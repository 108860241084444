/* eslint-disable */
import { FC } from 'react'
import { TrainQuestionModalProps } from './types'
import ModalTemplate from 'uiKit/ModalTemplate'
import * as Styles from '../styles'
import * as LocalStyles from '../createQuestionModal/styles'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import { useModalToggle } from '../hooks'
import styles from './styles.module.scss'
import { useQuestionTrainedStatus } from '../tableRow/hooks'
import { useUnknownQuestions } from '../context'

export const TrainQuestionModal: FC<TrainQuestionModalProps> = ({ question }) => {
  const { toggleModal, isOpen } = useModalToggle()

  const { trainQuestion } = useUnknownQuestions()

  const { canBeTrained } = useQuestionTrainedStatus(question)

  const doShowFollowUpButton = !!question.usersId

  const onTrainButtonPressed = (followUp = false) => {
    if (!canBeTrained) return
    trainQuestion(question, { followUp }).then(toggleModal)
  }

  return (
    <>
      <Styles.TrainButton disabled={!canBeTrained} onClick={toggleModal}>
        Train
      </Styles.TrainButton>
      <ModalTemplate
        containerClasses={styles.modal}
        open={isOpen}
        title="Train question"
        titleIcon={
          <Styles.TitleIconWrapper>
            <SparklesIcon />
          </Styles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <Styles.ModalContainer>
          <Styles.SubHeader>
            Do you want to train the question? Bot will reply to similar questions with provided answer
          </Styles.SubHeader>

          <Styles.ModalButtonContainer>
            <LocalStyles.ModalButton onClick={toggleModal}>Cancel</LocalStyles.ModalButton>
            <LocalStyles.ModalButton filled onClick={() => onTrainButtonPressed()}>
              Train
            </LocalStyles.ModalButton>
            {doShowFollowUpButton && (
              <LocalStyles.ModalButton filled onClick={() => onTrainButtonPressed(true)}>
                Train and Follow Up
              </LocalStyles.ModalButton>
            )}
          </Styles.ModalButtonContainer>
        </Styles.ModalContainer>
      </ModalTemplate>
    </>
  )
}
