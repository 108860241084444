import React, { FC } from 'react'
import * as Styles from './styles'
import uuid from 'uuid'
import ReactTooltip from 'react-tooltip'
import { HeaderItemProps } from './types'

export const TableHeaderItem: FC<HeaderItemProps> = ({ title, maxWidth, sorting, tooltipText, styles }) => {
  const id = React.useMemo(() => uuid(), [])

  const changeSort = () => {
    sorting?.directionState[1](prev => !prev)
    sorting?.onClickCallback && sorting.onClickCallback()
  }

  return (
    <Styles.TitleContainer style={{ maxWidth }}>
      <Styles.TitleWrapper
        style={styles}
        addHover={sorting?.hasOwnProperty('directionState') && sorting.directionState}
        onClick={changeSort}>
        <Styles.Title>{title}</Styles.Title>

        {sorting && sorting.directionState && <Styles.SortTriangle desc={sorting?.directionState[0]} />}

        {tooltipText && (
          <>
            <ReactTooltip
              className={Styles.TooltipStyle}
              place="bottom"
              type="light"
              effect="solid"
              id={`TooltipInfo-${id}`}>
              {title.tooltipText}
            </ReactTooltip>
            <img
              style={{ cursor: 'pointer', paddingLeft: '3px' }}
              data-tip
              data-for={`TooltipInfo-${id}`}
              src="/images/platform/information-button.svg"
              alt="info icon"
            />
          </>
        )}
      </Styles.TitleWrapper>
    </Styles.TitleContainer>
  )
}
