import styled from 'styled-components'
import { WHITE } from 'constants/colors'

const Container = styled.div`
  background-color: ${WHITE};
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
`

const TableWrap = styled.div`
  min-height: 800px;
`

export { Container, TableWrap }
