import React from 'react'

import KnowledgeBase from '../tabs/nlp/components/knowledgeBase/KnowledgeBase'
import UnansweredQuestions from '../tabs/nlp/components/unansweredQuestions'
import AbbreviationTab from '../tabs/nlp/components/Abbreviations'
import UserFeedbackTab from '../tabs/nlp/components/UserFeedback'
import { UnknownQuestionProvider } from 'tabs/nlp/components/unansweredQuestions/context'
import { AbbreviationsProvider } from 'tabs/nlp/components/Abbreviations/context'
import { UserFeedbackProvider } from 'tabs/nlp/components/UserFeedback/context'
import { TopQuestionsPage } from 'tabs/topQuestions/components/top_questions_page/TopQuestionsPage'
import PsychologyIcon from '@mui/icons-material/Psychology'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import SosIcon from '@mui/icons-material/Sos'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import AnimationIcon from '@mui/icons-material/Animation'

export type RouteConfiguration = {
  path: string
  name: string
  component: () => JSX.Element
  icon: () => JSX.Element
}

export const nlpRoutes: RouteConfiguration[] = [
  {
    path: 'ai-knowledge',
    name: 'AI Knowledge',
    component: () => <KnowledgeBase />,
    icon: () => <PsychologyIcon />,
  },
  {
    path: 'unanswered-questions',
    name: 'Unanswered Questions',
    component: () => (
      <UnknownQuestionProvider>
        <UnansweredQuestions />
      </UnknownQuestionProvider>
    ),
    icon: () => <ContactSupportIcon />,
  },
  {
    path: 'abbreviations',
    name: 'Abbreviations',
    component: () => (
      <AbbreviationsProvider>
        <AbbreviationTab />
      </AbbreviationsProvider>
    ),
    icon: () => <SosIcon />,
  },
  {
    path: 'feedback',
    name: 'User Feedback',
    component: () => (
      <UserFeedbackProvider>
        <UserFeedbackTab />
      </UserFeedbackProvider>
    ),
    icon: () => <ThumbUpAltIcon />,
  },
  {
    path: 'top-questions',
    name: 'Top Questions',
    component: () => <TopQuestionsPage />,
    icon: () => <AnimationIcon />,
  },
]
