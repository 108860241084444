import { UpdateRequestsMapping } from './type'
import { DELAY_BEFORE_SENDING_UPDATE_REQUEST } from './configs'
import { SimpleObject } from 'types/types'

type UpdateFunc<T> = (id: string, body: T, queryParams?: SimpleObject) => Promise<void>

export const useUpdateRequestsMapping = <T>(updateRecordFunc: UpdateFunc<T>) => {
  const updateRequestsMap: UpdateRequestsMapping = {}

  const updateRecord = async (id: string, newRecord: Partial<T>, queryParams = {}) => {
    updateRequestsMap[id] = {
      queryParams,
      timer: setTimeout(() => {
        updateRecordFunc(id, <T>newRecord, queryParams).then(() => removeRequestFromMap(id))
      }, DELAY_BEFORE_SENDING_UPDATE_REQUEST),
    }
  }

  const putOnHoldUpdateRequest = (id: string) => {
    if (updateRequestsMap[id]) {
      clearTimeout(updateRequestsMap[id].timer)
      updateRequestsMap[id].shouldUpdate = true
    }
  }

  const sendOnHoldRequestsIfNeed = (id: string, newRecord: T) => {
    if (updateRequestsMap[id].shouldUpdate) {
      updateRecord(id, newRecord, updateRequestsMap[id].queryParams)
    }
  }

  const removeRequestFromMap = (id: string) => {
    delete updateRequestsMap[id]
  }

  return { putOnHoldUpdateRequest, sendOnHoldRequestsIfNeed, updateRecord }
}
