import { PayloadAction } from 'tabs/topQuestions/types/GenericTypes'

export const BOT_SETTING_DATE_GET = 'bot/setting/get'
export const BOT_SETTING_DATE_SAVE = 'bot/setting/save'
export const BOT_SETTING_UPDATE = 'bot/setting/update'
export const BOT_USERS_SAVE_FEEDBACK_GROUP = 'bot/users/save/feedback/group'

export type BotSettingGet = PayloadAction<typeof BOT_SETTING_DATE_GET, Bot>
export type BotSettingSave = PayloadAction<typeof BOT_SETTING_DATE_SAVE, any>
export type BotSettingUpdate = PayloadAction<typeof BOT_SETTING_UPDATE, Bot>
export type BotUsersGroupSave = PayloadAction<
  typeof BOT_USERS_SAVE_FEEDBACK_GROUP,
  { users: BotUser[]; groupName: string }
>

export type BotSettingActions = BotSettingGet | BotSettingSave | BotSettingUpdate | BotUsersGroupSave

export type FeedbackGroupConfig = {
  shouldFeedbackBeAsked: boolean
  isMandatory: boolean
}

export type BotFeedback = {
  groups: Record<string, FeedbackGroupConfig>
} & FeedbackGroupConfig

export type Bot = {
  name: string
  feedback: BotFeedback
}

export type BotUser = { name: string; id: string; idString: string }
