import { HeadersSortMap, SortConfig } from './types'

export const convertSortParamsToAPIFormat = (sortParams: SortConfig, defaultParams = '') => {
  return (
    Object.entries(sortParams)
      .reduce((acc, [key, value]) => acc + `${key},${value},`, '')
      .slice(0, -1) || defaultParams
  )
}

export const mergeTitlesWithSortingMap = (sortHeadersMap: HeadersSortMap, tableTitles: any[]) => {
  const titles = Object.keys(sortHeadersMap)
  return tableTitles.map(titleData => {
    if (titles.includes(titleData.title)) {
      return { ...titleData, sort: sortHeadersMap[titleData.title] }
    }
    return titleData
  })
}

export const convertFilterToAPIFormat = <T>(filter: T, keys: Array<keyof T>) => {
  return keys.reduce((obj, key) => (filter.hasOwnProperty(key) ? { ...obj, [key]: filter[key] } : obj), {})
}
