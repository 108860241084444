/* eslint-disable */
import { FC } from 'react'
import * as LocalStyles from './../styles'
import * as ModalStyles from '../../../../ModalWindow/modal.styles'
import { QuestionAnswerModalSectionProps } from './types'

export const QuestionAnswerSection: FC<QuestionAnswerModalSectionProps> = ({
  answer,
  question,
  score,
  answerTitle,
  questionTitle,
  questionType,
}) => {
  const getQuestionComponent = (question: string) => {
    return question.length < 230 ? (
      <ModalStyles.Text>{question}</ModalStyles.Text>
    ) : (
      <LocalStyles.AnswerInput disabled={true} value={question} />
    )
  }

  return (
    <ModalStyles.ModalContainerSection>
      <ModalStyles.ModalContainerSectionItem width="40%">
        <ModalStyles.SubHeader>{questionTitle}</ModalStyles.SubHeader>
        {getQuestionComponent(question)}
        {questionType && (
          <ModalStyles.SubHeader fontSize="17px" color="rgb(33 37 38 / 75%)">
            {'Question Type: ' + questionType}
          </ModalStyles.SubHeader>
        )}
        {score && (
          <ModalStyles.SubHeader fontSize="17px" color="rgb(33 37 38 / 75%)">
            Matching score with the user's query:{' '}
            <LocalStyles.Score score={score}>{score.toFixed(0)}</LocalStyles.Score>
          </ModalStyles.SubHeader>
        )}
      </ModalStyles.ModalContainerSectionItem>

      <ModalStyles.ModalContainerSectionItem>
        <ModalStyles.SubHeader>{answerTitle}</ModalStyles.SubHeader>
        <LocalStyles.AnswerInput disabled={true} value={answer} />
      </ModalStyles.ModalContainerSectionItem>
    </ModalStyles.ModalContainerSection>
  )
}
