import React from 'react'
import MTPopover from '@material-ui/core/Popover'

type Direction = 'left' | 'right' | 'center'

const Popover = ({
  children,
  id,
  open,
  anchorEl,
  onClose,
  paperStyle = {},
  style = {},
  horizontal = 'right' as Direction,
}) => {
  return (
    <MTPopover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{ style: paperStyle }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal,
      }}
      style={style}>
      {children}
    </MTPopover>
  )
}

export default Popover
