import { BACKEND_URL } from 'configs'
import { lineChartDataReqPending, lineChartDataReqRejected, lineChartDataReqResolved } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartActions'
import { ClusteringExecutionData } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartTypes'


export const sendFetchTopQuestionsLineChartData = (minTimestampSeconds: number,
                                                   maxTimestampSeconds: number) => {
  return async dispatch => {
    dispatch(lineChartDataReqPending())
    try {
      const urlComponents = new URLSearchParams()
      urlComponents.append('minTimestamp', '' + minTimestampSeconds)
      urlComponents.append('maxTimestamp', '' + maxTimestampSeconds)

      const url = `${BACKEND_URL}/questions-clustering/historical-data?` + urlComponents.toString()
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      const data = await response.json() as ClusteringExecutionData[]
      dispatch(lineChartDataReqResolved(data))
    } catch (error) {
      dispatch(lineChartDataReqRejected())
    }
  }
}
