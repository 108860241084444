import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import base64 from 'base-64'
import { login } from '../api/admin'
import Input from './Input'
import PasswordInput from './PasswordInput'
import Button from './Button'
import { isStringEmpty } from '../../../helpers/isStringEmpty'
import { HOME_PATH } from '../../../configs'
import { MicrosoftSignIn } from '../../../uiKit/icons/MicrosoftSignIn'
import { microsoftAuthService } from '../api/microsoftAuth'

const defaultErrorMessage = 'Invalid email or password'

class LoginForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      error: false,
      errorMessage: '',
    }
  }

  handleChangeUsername = e => {
    this.setState({ username: e.target.value })
  }

  handleChangePassword = e => {
    this.setState({ password: e.target.value })
  }

  trowError = customErrorMessage =>
    this.setState({
      errorMessage: customErrorMessage ? customErrorMessage : this.state.errorMessage,
      error: true,
    })

  handleSubmitLogin = () => {
    const { username, password } = this.state
    if (!username || !password) {
      this.trowError(defaultErrorMessage)
      return
    }

    const user = {
      username,
      password: base64.encode(password),
    }
    login(user)
      .then(response => {
        if (response.ok) {
          const from = { pathname: HOME_PATH + '/bot/1/nlp/ai-knowledge' }
          this.props.authenticateWithReferrer(from)
        } else {
          if (response.status >= 400) {
            response.json().then(text => this.trowError(text.message))
          }
        }
      })
      .catch(this.trowError)
  }

  handleSsoLogin = () => {
    microsoftAuthService.getIdPRedirectUrl().then(url => {
      window.location.href = url
    })
  }

  render() {
    const { styles: classes, isSignUpEnable } = this.props
    const { error, errorMessage } = this.state

    return (
      <main className={classes.main}>
        <div className={`${classes.demoForm} animated zoomIn faster`}>
          <h4 className={classes.formTitle}>Sign in</h4>

          <div className={classes.demoDiv}>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Type your email"
              label="Email"
              errorLabel=""
              required={true}
              onChange={this.handleChangeUsername}
              pressEnterCallback={this.handleSubmitLogin}
            />

            <PasswordInput
              id="password"
              name="password"
              placeholder="Type password"
              label="Password"
              errorLabel=""
              onChange={this.handleChangePassword}
              pressEnterCallback={this.handleSubmitLogin}
            />

            {error ? (
              <div className="error-message">{!isStringEmpty(errorMessage) ? errorMessage : defaultErrorMessage}</div>
            ) : (
              <div />
            )}
          </div>
          <Button type="submit" title="Sign in" onClick={this.handleSubmitLogin} />
          <Link className={classes.forgotPasswordLink} to={'/forgot-password'}>
            Forgot your password?
          </Link>

          <MicrosoftSignIn style={{ borderRadius: 10 }} onClick={this.handleSsoLogin} />
        </div>
        <br />
        {isSignUpEnable && (
          <span className={classes.bottomText}>
            Don't have an account yet? <Link to={'/sign-up'}>Sign up</Link>
          </span>
        )}
      </main>
    )
  }
}

export default withRouter(LoginForm)
