import React, { FC } from 'react'
import Select from 'react-select'
import { UnkownQuestionTableRowSelectProps } from './types'
import SelectorStyles from '../../../constants/selectorStyles'
import { unansweredQuestionTypeAssignOptions } from '../configs'
import { useUnknownQuestionsOptions } from './hooks'

export const UnknownQuestionTableRowSelect: FC<UnkownQuestionTableRowSelectProps> = ({
  onValueChange,
  question
}) => {
  const { currentOption, updateValue } = useUnknownQuestionsOptions(question)

  const onSelectValueChange = ({ value }) => {
    updateValue(value)
    onValueChange(value)
  }

  return (
    <Select
      styles={SelectorStyles}
      options={unansweredQuestionTypeAssignOptions}
      value={currentOption as any}
      onChange={onSelectValueChange}
      placeholder="Select question type"
    />
  )
}
