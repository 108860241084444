import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'
import { generateClusterName } from 'tabs/topQuestions/lib/top_questions/topQuestionsApi'
import Typography from '@material-ui/core/Typography'
import { RequestStatus } from 'tabs/topQuestions/types/GenericTypes'

import { selectGenerateClusterNameRequest } from 'tabs/topQuestions/lib/top_questions/topQuestionsSelectors'

type QuestionClusterNameProps = {
  topQuestion: ClusterDetails
}
export const QuestionClusterName: React.FC<QuestionClusterNameProps> = ({ topQuestion }) => {
  const dispatch = useDispatch()
  const { clusterName, clusterId } = topQuestion
  const generateNameRequest = useSelector(selectGenerateClusterNameRequest(clusterId))
  const clusterNameNotExists = clusterName == undefined || clusterName == ''

  useEffect(() => {
    const requestStatus = generateNameRequest?.status
    if (requestStatus == null || requestStatus == RequestStatus.NONE) {
      if (clusterNameNotExists) {
        dispatch(generateClusterName(clusterId))
      }
    }
  }, [clusterName])

  if (!clusterNameNotExists) {
    return <Typography>
      {clusterName}
    </Typography>
  } else {
    return <Typography>Generating name</Typography>
  }
}
