import { BASE_URL } from '../../../configs'

import { saveAccountSettings } from '../actions/account'
import { alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson } from '../../../api'

export const getAccountSettings = adminId => {
  return fetch(`${BASE_URL}/admin/settings/${adminId}`, {
    method: 'GET',
    credentials: 'include',
    headers: createJsonHeaders(),
  })
    .then(response => {
      if (!response.ok) {
        alertError(
          'Sorry but something going wrong at getting intents please ping support!',
        )
        return
      }

      return response.json()
    })
    .then(response => saveAccountSettings(response))
    .catch(function() {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export const updateAccountSettings = (adminId, body) => {
  return fetch(`${BASE_URL}/admin/settings/${adminId}`, {
    method: 'PUT',
    credentials: 'include',
    headers: createJsonHeaders(),
    body: JSON.stringify(body),
  })
    .then(response => {
      if (!response.ok) {
        alertError(
          'Sorry but something going wrong at getting intents please ping support!',
        )
        return
      }
      alertSuccess("User's settings has been successfully updated.", 3000)

      return response.json()
    })
    .then(response => saveAccountSettings(response))
    .catch(function() {
      alertError(
        'Sorry but something going wrong at getting intents please ping support!',
      )
    })
}

export const dropImage = files => {
  const file = new FormData()
  file.append('file', files[0])
  return fetch(BASE_URL + '/file', {
    credentials: 'include',
    method: 'POST',
    body: file,
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(function() {
      alertError('Fail. Try it later', 10000)
    })
}
