import styled from 'styled-components'

const IconContainer = styled.div`
  border-radius: 5px;
  height: max-content;
  cursor: pointer;

  &:hover {
    background-color: #a7cef5a3;
  }
`

const TitleIconWrapper = styled.span`
  padding-left: 8px;
`

export { IconContainer, TitleIconWrapper }
