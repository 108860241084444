import { RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import { handleClusterHoveredChanged, handleDisplayedClusterChange, handleNewClusterInfoReceived, updateClusterName } from 'tabs/topQuestions/conversion/InternalConverters'
import {
  FETCH_TOP_QUESTIONS_PENDING,
  FETCH_TOP_QUESTIONS_REJECTED,
  FETCH_TOP_QUESTIONS_RESOLVED,
  GENERATE_CLUSTER_NAME_REJECTED,
  GENERATE_CLUSTER_NAME_REQ,
  GENERATE_CLUSTER_NAME_RESOLVED,
  TopQuestionPayloadAction,
  TopQuestionsSlice,
  UPDATE_CLUSTER_HOVERED,
  UPDATE_DISPLAYED_CLUSTER,
} from 'tabs/topQuestions/lib/top_questions/topQuestionsConstnats'
import { handleGenerateNameResponse } from 'tabs/topQuestions/lib/top_questions/topQuestionsHandlers'


const initialState: TopQuestionsSlice = {
  clusters: {},
  fetchTopQuestionsRequestStatus: RequestStatus.NONE,
  generateNameRequest: {},
}

export const topQuestions = (
  state: TopQuestionsSlice = initialState,
  action: TopQuestionPayloadAction): TopQuestionsSlice => {
  switch (action.type) {
    case UPDATE_DISPLAYED_CLUSTER:
      return {
        ...state,
        clusters: handleDisplayedClusterChange(action, state),
      }
    case FETCH_TOP_QUESTIONS_REJECTED:
      return {
        ...state,
        fetchTopQuestionsRequestStatus: RequestStatus.REJECTED,
      }
    case FETCH_TOP_QUESTIONS_RESOLVED:
      return {
        ...state,
        clusters: handleNewClusterInfoReceived(action),
        fetchTopQuestionsRequestStatus: RequestStatus.RESOLVED,
      }
    case FETCH_TOP_QUESTIONS_PENDING:
      return {
        ...state,
        fetchTopQuestionsRequestStatus: RequestStatus.PENDING,
      }
    case GENERATE_CLUSTER_NAME_REQ: {
      return {
        ...state,
        generateNameRequest: {
          ...state.generateNameRequest,
          [action.payload.clusterId]: {
            status: RequestStatus.PENDING,
          },
        },
      }
    }
    case GENERATE_CLUSTER_NAME_RESOLVED: {
      return {
        ...state,
        clusters: updateClusterName(state.clusters, action.payload),
        generateNameRequest: handleGenerateNameResponse(state, action),
      }
    }
    case GENERATE_CLUSTER_NAME_REJECTED: {
      return {
        ...state,
        generateNameRequest: {
          ...state.generateNameRequest,
          [action.payload.clusterId]: { status: RequestStatus.REJECTED },
        },
      }
    }
    case UPDATE_CLUSTER_HOVERED:
      return {
        ...state,
        clusters: handleClusterHoveredChanged(state.clusters, action),
      }
    default:
      return state
  }
}
