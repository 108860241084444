function componentToHex(c: number) {
  const hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}

export function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}


function scaleRgb(b: number) {
  const maxValue = 255.0
  return Math.round(b * maxValue)
}

export function toRgba(r: number, g: number, b: number, alpha: number) {
  return `rgba(${scaleRgb(r)}, ${scaleRgb(g)}, ${scaleRgb(b)}, ${alpha})`
}
