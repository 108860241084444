import React, { ChangeEventHandler, FC, useMemo, useState } from 'react'
import * as Styles from '../styles'
import { useKeyOnChange, useModalToggle } from '../hooks'
import { EditIcon } from 'uiKit/icons/EditIcon'
import ModalTemplate from 'uiKit/ModalTemplate'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import { UnkownQuestionEditElementProps, UnkownQuestionEditInnerElementProps } from './types'
import { useUnknownQuestions } from '../context'
import { UnknownQuestionUpdateAPI } from '../types'
import styles from './styles.module.scss'

export const Component: FC<UnkownQuestionEditInnerElementProps> = ({ question, isOpen, toggleModal }) => {
  const { updateQuestion } = useUnknownQuestions()

  const [currentAnswer, setCurrentAnswer] = useState<string>(question.answer)
  const [currentQuestion, setCurrentQuestion] = useState<string>(question.question)

  const isAnswerChangedAndValid = useMemo(() => {
    return (
      (!!currentAnswer && currentAnswer !== question.answer) ||
      (!!currentQuestion && currentQuestion !== question.question)
    )
  }, [currentAnswer, currentQuestion])

  const onUpdateButtonPressed = () => {
    if (!isAnswerChangedAndValid) return
    const newQuestion: UnknownQuestionUpdateAPI = { question: currentQuestion, answer: currentAnswer }
    updateQuestion(question, newQuestion).then(toggleModal)
  }

  const onCurrentAnswerChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setCurrentAnswer(event.target.value)
  }

  const onCurrentQuestionChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setCurrentQuestion(event.target.value)
  }

  return (
    <>
      <Styles.ModalIconContainer onClick={toggleModal}>
        <EditIcon />
      </Styles.ModalIconContainer>
      <ModalTemplate
        containerClasses={styles.modal}
        open={isOpen}
        title="Add answer to the question"
        titleIcon={
          <Styles.TitleIconWrapper>
            <SparklesIcon />
          </Styles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <Styles.ModalContainer>
          <Styles.SubHeader>Here you can update the question itself. You can adjust it as you need.</Styles.SubHeader>
          <Styles.AnswerInput value={currentQuestion} onChange={onCurrentQuestionChange} />

          <Styles.SubHeader>Update the answer to the question. Be imaginative and creative.</Styles.SubHeader>
          <Styles.AnswerInput value={currentAnswer} onChange={onCurrentAnswerChange} />

          <Styles.ModalButtonContainer>
            <Styles.TrainButton disabled={!isAnswerChangedAndValid} onClick={onUpdateButtonPressed}>
              Update
            </Styles.TrainButton>
          </Styles.ModalButtonContainer>
        </Styles.ModalContainer>
      </ModalTemplate>
    </>
  )
}

export const UnkownQuestionTableRowEditElement: FC<UnkownQuestionEditElementProps> = props => {
  const { isOpen, toggleModal } = useModalToggle()

  const key = useKeyOnChange(isOpen)

  return <Component key={key} {...{ isOpen, toggleModal, ...props }} />
}
