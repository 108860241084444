/* eslint-disable */
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
`

const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
`

const SubHeader = styled.div`
  font-size: 14px;
  color: #616581;
`

export type ButtonProps = {
  disabled?: boolean
}

const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 3px 9px rgba(19, 69, 186, 0.206267);
  cursor: pointer;
  color: white;
  border: none;
  max-width: fit-content;
  background: ${({ disabled }) => (disabled ? '#C7CAD6' : 'linear-gradient(285.2deg, #5a98f7 -35.9%, #1658f3 61.17%)')};
  cursor: ${({ disabled }) => disabled && 'auto'};
`

const RemoveButton = styled(Button)`
  background: linear-gradient(285.2deg, #ff7961 -35.9%, #f44336 61.17%);
`

const ModalIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export type TextProps = {
  fontSize?: string
}

const Text = styled.div<TextProps>`
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  font-size: ${({ fontSize }) => fontSize};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export { Container, Header, SubHeader, Button, RemoveButton, Text, ModalIconContainer, FiltersContainer }
