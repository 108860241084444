import { TopQuestionsFilterActions, TopQuestionsSearchSliceState, UPDATE_TOP_QUESTIONS_SEARCH } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchTypes'


const initialState: TopQuestionsSearchSliceState = {
  filter: {},
}

export const topQuestionsSearch = (
  state: TopQuestionsSearchSliceState = initialState,
  action: TopQuestionsFilterActions): TopQuestionsSearchSliceState => {
  switch (action.type) {
    case UPDATE_TOP_QUESTIONS_SEARCH:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      }
    default:
      return state
  }
}


