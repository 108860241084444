import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { withRouter } from 'react-router-dom'
import { TableHeaderItem } from './TableHeaderItem.tsx'

const styles = () => ({
  tableHeader: {
    borderBottom: '1px solid #EBEBEF',
    // background: '#F7FAFE',
    borderTop: 'none',
    padding: '5px 20px',
    display: 'flex',
    gap: '30px',
    justifyContent: 'space-between',
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: 'visible',
    width: '100%',
  },
  tableCellText: {
    margin: 0,
    maxWidth: 150,
  },
  view: {
    fontSize: '14px',
    textDecoration: 'underline',
    color: '#1658F3',
    cursor: 'pointer',
    margin: 0,
  },
  paper: {
    width: 800,
    padding: '30px 50px 40px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tooltip: {
    maxWidth: '80%',
    width: '200px',
    border: '1px solid #EBEBEF',
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
  },
})

class Table extends React.Component {
  state = {
    page: 0,
    open: false,
    rowsPerPage: 10,
  }

  render() {
    const { classes, children, style, titles } = this.props

    return (
      <div className={classes.tableWrapper} style={style}>
        <div className={classes.table}>
          <div>
            <div className={classes.tableHeader}>
              {titles.map(({ title, maxWidth, sort, tooltipText, styles }, index) => (
                <TableHeaderItem
                  key={title + index}
                  title={title}
                  tooltipText={tooltipText}
                  maxWidth={maxWidth}
                  sorting={sort}
                  styles={styles}
                />
              ))}
            </div>
            {children}
            <div className={classes.footer}>{this.props.paginationComponent}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Table))
