import { useRef } from 'react'

type BufferedContext<U> = {
  append: (arg: U) => void;
  releaseSignal: () => void;
}

export const useBuffer = <U extends any>(onBufferSignal: (argBuffer: U[]) => void): BufferedContext<U> => {
  const buffer = useRef<U[]>([])

  return {
    append: (arg: U) => buffer.current.push(arg),
    releaseSignal: () => {
      const oldBuffer = buffer.current
      buffer.current = []
      onBufferSignal(oldBuffer)
    },
  }
}
