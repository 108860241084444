import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Table from '../../../../uiKit/table/Table'
import * as Styles from './styles'
import Heading from 'uiKit/texts/Heading'
import { useUnknownQuestions } from './context'
import { unansweredQuestionsTableTitles } from './configs'
import { UnkownQuestionTableRow } from './tableRow'
import { UnknownQuestionCreateModal } from './createQuestionModal'
import Pagination from 'uiKit/table/pagination/Pagination'
import { paginationConfigs } from 'tabs/nlp/constants/paginationSize'
import { TrainSearchInput } from '../searchField'
import { mergeTitlesWithSortingMap } from 'uiKit/table/config'
import { FilterPopover } from './filterPopover/FilterPopover'

const UnansweredQuestions = () => {
  const {
    unkownQuestions,
    setPage,
    page,
    count,
    areRecordsInitialized,
    updateSearchValue,
    sortHeadersMap,
    isSortingActive,
    dropSortParams,
    loadQueryParamsInSorting,
  } = useUnknownQuestions()

  const onPaginationChange = (_, page) => {
    setPage(page)
  }

  const unQuestionTableTitlesWithSortingMap = () => {
    return mergeTitlesWithSortingMap(sortHeadersMap, unansweredQuestionsTableTitles)
  }

  useEffect(() => {
    loadQueryParamsInSorting()
  }, [])

  return (
    <Styles.Container>
      <Heading>Unanswered Questions</Heading>
      <Styles.SubHeader>Train questions unanswered by the bot</Styles.SubHeader>

      <Styles.FiltersContainer>
        <TrainSearchInput placeholder="Search unknown questions" updateSearchValue={updateSearchValue} />
        <Styles.TrainButton onClick={dropSortParams} disabled={!isSortingActive}>
          Reset Sort Parameters
        </Styles.TrainButton>
        <FilterPopover />
        <UnknownQuestionCreateModal />
      </Styles.FiltersContainer>

      <Table titles={unQuestionTableTitlesWithSortingMap()}>
        {unkownQuestions.map((question, index) => {
          return <UnkownQuestionTableRow key={question.id} {...{ question, index }} />
        })}
      </Table>

      {areRecordsInitialized && (
        <Pagination page={page} rowsPerPage={paginationConfigs.limit} count={count} onChangePage={onPaginationChange} />
      )}
    </Styles.Container>
  )
}

export default withRouter(UnansweredQuestions)
