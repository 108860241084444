import styled from 'styled-components'

const SubHeader = styled.div<{ fontSize?: string; color?: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
  color: ${({ color }) => (color ? color : 'rgb(80 102 255)')};
  display: flex;
  align-items: center;
  font-weight: bold;
`

const Container = styled.div`
  height: 100%;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 1rem 1rem;
`

const ModalContainerSection = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 1rem 0 1rem;
  flex-wrap: wrap;
`

const ModalContainerSectionItem = styled.div<{ width?: string }>`
  flex: ${({ width }) => (width ? `0 0 ${width}` : 1)};
`

const TitleIconWrapper = styled.span`
  padding-left: 8px;
`

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const Divider = styled.div`
  height: 1px;
  background-color: #e0e0e0;
`

const Text = styled.div`
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: block;
`

export {
  Container,
  TitleIconWrapper,
  ModalContainer,
  ModalButtonContainer,
  ModalContainerSection,
  ModalContainerSectionItem,
  Divider,
  Text,
  SubHeader,
}
