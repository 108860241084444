import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { microsoftAuthService } from '../api/microsoftAuth'
import { HOME_PATH } from 'configs'

export const MicrosoftAuthRedirectHandler = props => {
  useEffect(() => {
    const { location, security } = props
    const searchParams = new URLSearchParams(location.hash.substring(1))
    const idToken = searchParams.get('id_token')

    if (!security.authenticated && idToken) {
      microsoftAuthService.singInUserByToken(idToken).then(response => {
        if (response.ok) props.authenticateWithReferrer({ pathname: HOME_PATH + '/bot/1/nlp/ai-knowledge' })
      })
    }
  }, [])

  return null
}

export default withRouter(MicrosoftAuthRedirectHandler)
