import { QuestionClustersLineChartSlice } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartSlice'
import { PendingRequest } from 'tabs/topQuestions/types/GenericTypes'
import { ClusteringExecutionData } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartTypes'
import { DateObject } from 'tabs/topQuestions/utils/DateUtils'

export const selectQuestionsData = (root: any): PendingRequest<ClusteringExecutionData[]> => {
  const lineChartState: QuestionClustersLineChartSlice = root.questionsLineChartData
  return lineChartState.lineChartData
}

export const selectStartDateFilter = (root: any): DateObject | undefined => {
  const lineChartState: QuestionClustersLineChartSlice = root.questionsLineChartData
  return lineChartState.lienChartFilter.startDate
}

export const selectEndDateFilter = (root: any): DateObject | undefined => {
  const lineChartState: QuestionClustersLineChartSlice = root.questionsLineChartData
  return lineChartState.lienChartFilter.endDate
}
