import { DeepPartial } from 'react-hook-form'
import {
  BOT_SETTING_DATE_GET,
  BOT_SETTING_DATE_SAVE,
  BOT_SETTING_UPDATE,
  BOT_USERS_SAVE_FEEDBACK_GROUP,
  Bot,
  BotUser,
} from './BotSettingsTypes'

export const botSettingGet = () => ({
  type: BOT_SETTING_DATE_GET,
  payload: undefined,
})

export const botSettingSave = (bot: Bot) => ({
  type: BOT_SETTING_DATE_SAVE,
  payload: bot,
})

export const botSettingUpdate = (bot: DeepPartial<Bot>) => ({
  type: BOT_SETTING_UPDATE,
  payload: bot,
})

export const botUsersGroupSave = (users: BotUser[], groupName: string) => ({
  type: BOT_USERS_SAVE_FEEDBACK_GROUP,
  payload: { users, groupName },
})
