import styled from 'styled-components'
import { COMET_GREY, FIORD_BLUE, PERMISSION_RED, SELAGO_GRAY } from 'constants/colors'

const Container = styled.div<{ isFromEdit: boolean }>`
  width: 100%;
  background-color: white;
  padding: 24px;
  border-radius: ${({ isFromEdit }) => (isFromEdit ? '0' : '10px')};
  box-shadow: ${({ isFromEdit }) => (isFromEdit ? 'unset' : '4px 4px 29px rgba(19, 69, 186, 0.0855129)')};
  margin-top: ${({ isFromEdit }) => (isFromEdit ? '0' : '32px')};
  margin-bottom: ${({ isFromEdit }) => (isFromEdit ? '0' : '16px')};
  position: relative;
  padding-top: ${({ isFromEdit }) => (isFromEdit ? '8px' : '24px')};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Title = styled.p`
  margin: 0;
  font-size: 14px;

  font-weight: ${({ isFromEdit }) => (isFromEdit ? '400' : '700')};
  color: ${({ isFromEdit }) => (isFromEdit ? COMET_GREY : FIORD_BLUE)};
`

const Form = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`

const Button = styled.button`
  background: #1658f3;
  min-width: 80px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  &:disabled {
    background: #acb0c0;
  }
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.3;
  background-color: ${SELAGO_GRAY};
`

const Textarea = styled.textarea<{ isError: boolean; isFromEdit: boolean }>`
  position: relative;
  width: 100%;
  height: ${({ isFromEdit }) => (isFromEdit ? '250px' : '176px')};
  font-size: 14px;
  word-break: break-word;
  outline: none;
  border: none;
  resize: none;
`

const Error = styled.div`
  font-size: 12px;
  position: absolute;
  color: ${PERMISSION_RED};
  bottom: 44px;
  left: 0;
`

const InputContainer = styled.div<{ isError: boolean; isFromEdit: boolean }>`
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 10px;

  * {
    padding: 1rem;
    border-radius: inherit;
  }
`

const TitleInput = styled.input`
  font-weight: 600;
  font-size: ${({ isFromEdit }) => (isFromEdit ? '14px' : '16px')};
  color: rgb(68, 68, 68);
  border: none;
  outline: none;
  width: 100%;
`

export { Container, Title, Form, DisableScreen, Button, Textarea, Error, InputContainer, TitleInput }
