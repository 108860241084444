import styled from 'styled-components'

//Define the styled-components
const TitleContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  align-items: center;
  max-width: 150px;
  &:last-child {
    max-width: 50px;
  }
  &:nth-last-child(2) {
    // maxWidth: 150,
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px 10px;
  ${({ addHover }) =>
    addHover &&
    `cursor: pointer;
    &:hover{
      background-color: rgba(90, 152, 247, 0.2);
  }`}
`

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  color: #3a3f62;
  display: inline;
  white-space: nowrap;
`

const SortTriangle = styled.div<{ desc: boolean }>`
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  ${({ desc }) => (desc ? 'border-top: 5px solid rgb(97, 101, 129);' : 'border-bottom: 5px solid rgb(97, 101, 129);')}
`

const TooltipStyle = {
  maxWidth: '80%',
  width: '200px',
  border: '1px solid #EBEBEF',
  boxSizing: 'border-box',
  boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
  borderRadius: '10px',
  zIndex: 9999,
  opacity: 1,
}

export { TitleContainer, Title, TooltipStyle, SortTriangle, TitleWrapper }
