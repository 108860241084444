/* eslint-disable */
import { FC, useEffect, useMemo, useState } from 'react'
import * as ModalStyles from '../../../ModalWindow/modal.styles'
import { Autocomplete, TextField } from '@mui/material'
import { Done } from '@material-ui/icons'
import * as Styles from '../../styles'
import { FeedbackUsersTable } from './table'
import { BotUser } from '../state/BotSettingsTypes'
import { useDebounceCallback } from 'hooks/useDebounceCallback'
import { appBotService } from '../../api/bot'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersOfGroup } from '../state/BotSettingsReduser'
import { retrieveUsersGroup } from '../state/BotSettingApi'

export const AddUsersSection: FC<{ groupName: string }> = ({ groupName }) => {
  const dispatch = useDispatch()
  const groupUsers = useSelector(state => getUsersOfGroup(state, groupName))

  const [selectedUsers, setSelectedUsers] = useState<Array<BotUser>>([])
  const [users, setUsers] = useState<Array<BotUser>>([])
  const [search, setSearch] = useState('')

  const canAddUsers = useMemo(() => selectedUsers.length > 0, [selectedUsers])

  const setSearchDebounced = useDebounceCallback(setSearch, 500)

  const fetchUsers = () => {
    return appBotService.getBotUsers({ searchName: search, excludeGroup: groupName }).then(users => {
      setUsers(users)
    })
  }

  const handleInputChange = (event: React.SyntheticEvent, newValue: Array<BotUser>, reason: string) => {
    if (reason === 'selectOption' || reason === 'removeOption') setSelectedUsers(newValue)
    else if (reason === 'clear') setSelectedUsers([])
  }

  const addUsersToGroup = () => {
    appBotService.addUsersToGroup({ users: selectedUsers, groupName }).then(() => {
      setSelectedUsers([])
      dispatch(retrieveUsersGroup())
    })
  }

  const renderOptionFunc = (props, option: any) => {
    const isSelected = selectedUsers.some(user => user.id === option.id)
    return (
      <>
        <li {...props} key={props.key}>
          <Done style={{ visibility: isSelected ? 'visible' : 'hidden' }} />
          {option.name}
        </li>
      </>
    )
  }

  useEffect(() => {
    fetchUsers()
  }, [search])

  return (
    <>
      <ModalStyles.ModalContainerSection>
        <Autocomplete
          size="small"
          multiple
          value={selectedUsers}
          disableCloseOnSelect
          forcePopupIcon={false}
          getOptionLabel={option => option.name}
          ListboxProps={{ style: { maxHeight: 300 } }}
          options={users}
          onChange={handleInputChange}
          getOptionDisabled={option => selectedUsers.some(user => user.id === option.id)}
          onInputChange={(event, value) => setSearchDebounced(value)}
          onFocus={fetchUsers}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              placeholder="Search for users"
              size="small"
              value={selectedUsers.map(user => user.name).join(', ')}
            />
          )}
          renderOption={renderOptionFunc}
        />
        <Styles.Button disabled={!canAddUsers} onClick={addUsersToGroup}>
          Add to group
        </Styles.Button>
      </ModalStyles.ModalContainerSection>
      <FeedbackUsersTable users={groupUsers || []} groupName={groupName} />
    </>
  )
}
