/* eslint-disable */
import { FC, useMemo, useState } from 'react'
import { SolveFeedbackModalProps } from './types'
import ModalTemplate from 'uiKit/ModalTemplate'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import * as LocalStyles from './styles'
import styles from './styles.module.scss'
import { useModalToggle } from 'tabs/nlp/components/unansweredQuestions/hooks'
import { ModalButton } from 'tabs/nlp/components/unansweredQuestions/createQuestionModal/styles'
import { QuestionAnswerSection } from './QuestionAnswerSection'
import { useUserFeedback } from '../../context'
import Select from 'react-select'
import { unansweredQuestionTypeAssignOptions } from 'tabs/nlp/components/unansweredQuestions/configs'
import SelectorStyles from '../../../../constants/selectorStyles'
import { UnknownQuestionType } from 'tabs/nlp/components/unansweredQuestions/types'
import * as ModalStyles from '../../../ModalWindow/modal.styles'

export const SolveFeedbackModal: FC<SolveFeedbackModalProps> = ({ feedback }) => {
  const { retrainQuestion, creteNewQuestion } = useUserFeedback()
  const { toggleModal, isOpen } = useModalToggle()

  const [question, setQuestion] = useState(feedback.trainedQuestion)
  const [answer, setAnswer] = useState(feedback.trainedAnswer)
  const [type, setType] = useState<UnknownQuestionType>(feedback.trainedType)

  const isDataValid = useMemo(() => question && answer && type, [question, answer, type])

  const enableCreateButton = useMemo(
    () => isDataValid && question !== feedback.trainedQuestion,
    [isDataValid, question],
  )

  const enableRetrainButton = useMemo(
    () => isDataValid && (answer !== feedback.trainedAnswer || question !== feedback.trainedQuestion),
    [isDataValid, answer, question],
  )

  const onQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value)
  }

  const onAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAnswer(e.target.value)
  }

  const onSelectValueChange = event => {
    setType(event?.value)
  }

  const onRetrainPressed = () => {
    return retrainQuestion(feedback.id, { id: feedback.unknownQuestionId, question, answer, type }).then(toggleModal)
  }

  const onCreatedPressed = () => {
    return creteNewQuestion(feedback.id, { question, answer, type }).then(toggleModal)
  }

  return (
    <>
      <LocalStyles.SolveButton disabled={feedback.solved} onClick={toggleModal}>
        {feedback.solved ? 'Solved' : 'Solve'}
      </LocalStyles.SolveButton>
      <ModalTemplate
        containerClasses={styles.modal}
        open={isOpen}
        title="User Feedback Details"
        titleIcon={
          <ModalStyles.TitleIconWrapper>
            <SparklesIcon />
          </ModalStyles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <ModalStyles.ModalContainer>
          <QuestionAnswerSection
            question={feedback.userQuestion}
            answer={feedback.botAnswer}
            questionTitle="Asked User Question"
            answerTitle="Bot Answer"
          />

          <ModalStyles.Divider />

          <QuestionAnswerSection
            question={feedback.trainedQuestion}
            answer={feedback.trainedAnswer}
            score={feedback.matchScore * 100}
            questionType={feedback.trainedType.toUpperCase()}
            questionTitle="Closest Trained Question"
            answerTitle="Trained Answer"
          />

          {feedback.userFeedback && (
            <>
              <ModalStyles.Divider />

              <ModalStyles.ModalContainerSection>
                <ModalStyles.ModalContainerSectionItem>
                  <ModalStyles.SubHeader>User feedback about bot answer</ModalStyles.SubHeader>
                  <ModalStyles.Text>{feedback.userFeedback}</ModalStyles.Text>
                </ModalStyles.ModalContainerSectionItem>
              </ModalStyles.ModalContainerSection>
            </>
          )}

          <ModalStyles.Divider />

          <ModalStyles.SubHeader>Retrain trained question or create new</ModalStyles.SubHeader>

          <ModalStyles.ModalContainerSection>
            <ModalStyles.ModalContainerSectionItem width="40%">
              <ModalStyles.SubHeader>Question</ModalStyles.SubHeader>
              <LocalStyles.AnswerInput value={question} onChange={onQuestionChange} />
            </ModalStyles.ModalContainerSectionItem>

            <ModalStyles.ModalContainerSectionItem width="58%">
              <ModalStyles.SubHeader>Answer</ModalStyles.SubHeader>
              <LocalStyles.AnswerInput value={answer} onChange={onAnswerChange} />
            </ModalStyles.ModalContainerSectionItem>
            <Select
              styles={SelectorStyles}
              options={unansweredQuestionTypeAssignOptions}
              onChange={onSelectValueChange}
              placeholder="Select question type"
              defaultValue={unansweredQuestionTypeAssignOptions.find(option => option.value === type)}
            />
          </ModalStyles.ModalContainerSection>

          <ModalStyles.ModalButtonContainer>
            <ModalButton onClick={toggleModal}>Cancel</ModalButton>
            <ModalButton filled onClick={onCreatedPressed} disabled={!enableCreateButton}>
              Create new
            </ModalButton>
            <ModalButton filled onClick={onRetrainPressed} disabled={!enableRetrainButton}>
              Retrain
            </ModalButton>
          </ModalStyles.ModalButtonContainer>
        </ModalStyles.ModalContainer>
      </ModalTemplate>
    </>
  )
}
