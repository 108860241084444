import {
  UnknownQuestionModel,
  UnknownQuestionPaginateAPIRepsonse,
  UnknownQuestionTrainOptions,
} from '../components/unansweredQuestions/types'
import { UnknownQuestionCreateAPI, UnkownQuestionsSearchAPI } from './types'
import { BACKEND_URL } from '../../../configs'
import API from '../../../services/api'
import { alertSuccess } from '../../../api'
import { unknownQuestionsAPIMessages } from './configs'

const template = (path = '', params = {}) => {
  const searchParams = new URLSearchParams(params)
  return `${BACKEND_URL}/unknown-question/${path}?${searchParams}`
}

const deleteQuestion = (question: UnknownQuestionModel): Promise<UnknownQuestionModel> => {
  return API.delete<UnknownQuestionModel>(template(question.id)).then(responseQuestion => {
    alertSuccess(unknownQuestionsAPIMessages.deleted)
    return responseQuestion
  })
}

const trainQuestion = (
  question: UnknownQuestionModel,
  options: UnknownQuestionTrainOptions,
  withAlert = true,
): Promise<UnknownQuestionModel> => {
  return API.post(template(`train/${question.id}`, options)).then(responseQuestion => {
    if (withAlert) alertSuccess(unknownQuestionsAPIMessages.trained)
    return responseQuestion
  })
}

const updateQuestion = (id: string, question: Partial<UnknownQuestionModel>, withAlert = true): Promise<any> => {
  return API.put(template(id), question).then(responseQuestion => {
    if (withAlert) alertSuccess(unknownQuestionsAPIMessages.updated)
    return responseQuestion
  })
}

const searchQuestions = async (params: UnkownQuestionsSearchAPI): Promise<UnknownQuestionPaginateAPIRepsonse> => {
  const requestUrl = template('', params)
  return API.get(requestUrl)
}

const getAlertMessageForQuestionCreate = (train: boolean) => {
  return train ? unknownQuestionsAPIMessages.createdAndTrained : unknownQuestionsAPIMessages.created
}

const createQuestion = async (body: UnknownQuestionCreateAPI, train = false, withAlert = true) => {
  const requestUrl = template('', { train })
  return API.post<UnknownQuestionModel>(requestUrl, body).then(responseQuestion => {
    if (withAlert) alertSuccess(getAlertMessageForQuestionCreate(train))
    return responseQuestion
  })
}

export const unkownQuestionService = {
  deleteQuestion,
  trainQuestion,
  updateQuestion,
  searchQuestions,
  createQuestion,
}
