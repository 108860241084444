import { IMessage } from '@stomp/stompjs'
import {
  dataFields,
  detailsFields,
  detailsResponseFields,
  QuestionDetailsResponse,
  QuestionsDataResponse,
} from 'tabs/topQuestions/types/ApiResponses'

export function questionDetailsResponseConverter(message: IMessage): QuestionDetailsResponse {
  const body = JSON.parse(message.body)
  const { questionText } = detailsFields
  const { details, error, pineconeId } = detailsResponseFields

  return {
    details: {
      questionText: body[details][questionText],
    },
    error: body[error],
    pineconeId: body[pineconeId],
  }

}

export function questionDataResponseConverter(message: IMessage): QuestionsDataResponse {
  const body = JSON.parse(message.body)
  const { pineconeId, mongoLogId, mongoClusterId, x, y } = dataFields
  return {
    pineconeId: body[pineconeId],
    mongoLogId: body[mongoLogId],
    mongoClusterId: body[mongoClusterId],
    x: body[x],
    y: body[y],
  }
}
