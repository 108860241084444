export const styles = () => ({
  button: {
    cursor: 'pointer',
    fontSize: 14,
    padding: '8px 16px',
    border: '1px solid rgba(22, 88, 243, 1)',
    borderRadius: 4,
    color: 'rgba(22, 88, 243, 1)',
    width: 'fit-content',
    fontWeight: 500,
    backgroundColor: '#ffffff',
  },
})
