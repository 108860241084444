import { QuestionClustersTab } from 'tabs/topQuestions/components/question_cluster_list/QuestionClustersTab'
import React, { useContext } from 'react'
import { QuestionDetailsCallbackMap } from 'tabs/topQuestions/components/top_questions_content/TopQuestionsPageContent'
import { QuestionsDetailsWebsocketContext } from 'tabs/topQuestions/components/questions_websocket_connector/QuestionsDetailsWebsocketConnector'
import { TopQuestionsLineChart } from 'tabs/topQuestions/components/top_questions_line_chart/TopQuestionsLineChart'


type TopQuestionsDataContentProps = {
  questionDetailsCallback: QuestionDetailsCallbackMap
}
export const TopQuestionsDataContent: React.FC<TopQuestionsDataContentProps> = ({}) => {

  const { sendFetchClusterQuestionsMessage } = useContext(QuestionsDetailsWebsocketContext)


  return <div className={'topQuestionsPage'}>
    <TopQuestionsLineChart />
    <div className={'topQuestionsControlsWrapper'}>
      <QuestionClustersTab sendFetchClusterQuestionsMessage={sendFetchClusterQuestionsMessage} />
    </div>
  </div>

}
