/* eslint-disable */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  UnknownQuestionHeaderSortMap,
  UnknownQuestionModel,
  UnknownQuestionSortConfig,
  UnknownQuestionTrainOptions,
  UnknownQuestionType,
  UnknownQuestionUpdateAPI,
  UnkownQuestionsContextTypes,
  UnwQuestionFilterType,
} from './types'
import { unkownQuestionService } from 'tabs/nlp/api/unknownQuestions'
import { useDebounceCallback } from './hooks'
import { UnknownQuestionCreateAPI, UnkownQuestionsSearchAPI } from 'tabs/nlp/api/types'
import { paginationConfigs } from 'tabs/nlp/constants/paginationSize'
import { unansweredQuestionsQueryParamNamesMap } from './configs'
import { parseQueryParamValue } from 'helpers/parseQueryParamValue'
import { UserAutocomplete } from './assignUser/model/internal-types'
import { fetchAssignUserToUnkQuestion } from './assignUser/api/assign-user-api'
import { alertSuccess } from 'api'
import { convertFilterToAPIFormat, convertSortParamsToAPIFormat } from 'uiKit/table/config'
import { usePagination } from 'uiKit/table/pagination/hooks'

const DEFAULT_SORT_PARAMS = 'type,desc,updatedAt,desc'
const filterKeys: Array<keyof UnwQuestionFilterType> = ['isTrained', 'isAnswerExists', 'type', 'assignedUserId']

export const UnknownQuestionContext = createContext<UnkownQuestionsContextTypes>({
  unkownQuestions: [],
  search: '',
  updateType: async () => {},
  deleteQuestion: async () => {},
  trainQuestion: async () => {},
  searchQuestions: async () => [],
  updateSearchValue: () => {},
  addFilterParam: () => {},
  createQuestion: async () => {},
  updateQuestion: async () => {},
  page: 0,
  setPage: () => {},
  count: 0,
  areRecordsInitialized: false,
  sortHeadersMap: {},
  isSortingActive: false,
  dropSortParams: () => {},
  removeFilter: () => {},
  loadQueryParamsInSorting: () => {},
  filter: {} as UnwQuestionFilterType,
})

export const useUnknownQuestions = () => useContext(UnknownQuestionContext)

export const UnknownQuestionProvider = ({ children }) => {
  const {
    page,
    setPage,
    setRecordsInitialization,
    areRecordsInitialized,
    setCount,
    count: recordsCount,
  } = usePagination()

  const [unkownQuestions, setUnkownQuestions] = useState<UnknownQuestionModel[]>([])

  const [search, setSearchValue] = useState<string>('')
  const [filter, setFilter] = useState<UnwQuestionFilterType>({} as UnwQuestionFilterType)
  const [sortParams, setSortParams] = useState<UnknownQuestionSortConfig>({})

  const getSearchRequestParams = (): UnkownQuestionsSearchAPI => {
    const sort = convertSortParamsToAPIFormat(sortParams, DEFAULT_SORT_PARAMS)
    const filterParams = convertFilterToAPIFormat<UnwQuestionFilterType>(filter, filterKeys)

    return { search, page, limit: paginationConfigs.limit, sort, ...filterParams }
  }

  const searchQuestions = async () => {
    const params = getSearchRequestParams()
    unkownQuestionService.searchQuestions(params).then(({ records, count }) => {
      setUnkownQuestions(records)
      if (recordsCount !== count) setCount(count)
      if (!areRecordsInitialized) setRecordsInitialization(true)
    })
  }

  useEffect(() => {
    searchQuestions()
  }, [search, filter, page, sortParams])

  const updateQuestion = async (question: Partial<UnknownQuestionModel>, newQuestion: UnknownQuestionUpdateAPI) => {
    return unkownQuestionService.updateQuestion(question.id, newQuestion).then(searchQuestions)
  }

  const updateType = async (question: UnknownQuestionModel, type: UnknownQuestionType) => {
    const newQuestion: Partial<UnknownQuestionModel> = { type }
    return unkownQuestionService.updateQuestion(question.id, newQuestion).then(searchQuestions)
  }

  const deleteQuestion = async (question: UnknownQuestionModel) => {
    return unkownQuestionService.deleteQuestion(question).then(searchQuestions)
  }

  const trainQuestion = async (question: UnknownQuestionModel, options: UnknownQuestionTrainOptions) => {
    return unkownQuestionService.trainQuestion(question, options).then(searchQuestions)
  }

  const createQuestion = async (
    data: UnknownQuestionCreateAPI,
    train = false,
    user?: UserAutocomplete,
    notify?: boolean,
    withAlert = true,
  ) => {
    const question = await unkownQuestionService.createQuestion(data, train, false)

    if (user) await fetchAssignUserToUnkQuestion(user.id, question.id, notify, false)
    if (withAlert) alertSuccess('Question created successfully!')

    await searchQuestions()
    return question
  }

  const addFilterParam = (filedName: keyof UnwQuestionFilterType, value: string | number | boolean) => {
    setFilter(prev => ({ ...prev, [filedName]: value }))
  }

  const updateSearchValue = useDebounceCallback(setSearchValue)

  const dropSortParams = () => {
    setSortParams({})
    setFilter({} as UnwQuestionFilterType)
    Object.values(sortHeadersMap).forEach(({ directionState }) => directionState[1](true))
  }

  const removeFilter = (fieldName: string) => {
    setFilter(prev => {
      delete prev[fieldName]
      return { ...prev }
    })
  }

  const isSortingActive = Object.keys(sortParams).length > 0 || Object.keys(filter).length > 0

  const sortHeadersMap: UnknownQuestionHeaderSortMap = {
    Train: {
      directionState: React.useState<boolean>(true),
      onClickCallback: () => {
        addFilterParam('isTrained', !filter?.isTrained)
      },
      handleQueryParam: value => {
        addFilterParam('isTrained', value)
        return value
      },
    },
    Answer: {
      directionState: React.useState<boolean>(true),
      onClickCallback: () => {
        addFilterParam('isAnswerExists', !filter?.isAnswerExists)
      },
      handleQueryParam: value => {
        addFilterParam('isAnswerExists', value)
        return value
      },
    },
    'Created At': {
      directionState: React.useState<boolean>(true),
      onClickCallback: () => {
        const createdAt = sortParams?.createdAt === 'asc' ? 'desc' : 'asc'
        setSortParams(prev => ({ ...prev, createdAt }))
      },
      handleQueryParam: value => {
        const createdAt = value ? 'desc' : 'asc'
        setSortParams(prev => ({ ...prev, createdAt }))
        return value
      },
    },
    User: {
      directionState: null,
      onClickCallback: null,
      handleQueryParam: value => {
        addFilterParam('assignedUserId', value)
        filter.assignedUserId = value //This line is required to prevent re-rendering without applyed filter
        return value
      },
    },
  }

  const loadQueryParamsInSorting = () => {
    const params = new URLSearchParams(window.location.search)
    for (const [key, value] of params.entries()) {
      const filterKey = unansweredQuestionsQueryParamNamesMap[key.toLowerCase()]
      if (!filterKey) continue

      const sortingOrder = sortHeadersMap[filterKey].handleQueryParam(parseQueryParamValue(value))
      if (sortHeadersMap[filterKey].directionState) sortHeadersMap[filterKey].directionState[1](!sortingOrder)
    }
  }

  return (
    <UnknownQuestionContext.Provider
      value={{
        unkownQuestions,
        updateType,
        deleteQuestion,
        trainQuestion,
        searchQuestions,
        updateSearchValue,
        addFilterParam,
        search,
        createQuestion,
        updateQuestion,
        page,
        setPage,
        count: recordsCount,
        areRecordsInitialized,
        sortHeadersMap,
        isSortingActive,
        dropSortParams,
        removeFilter,
        loadQueryParamsInSorting,
        filter,
      }}>
      {children}
    </UnknownQuestionContext.Provider>
  )
}
