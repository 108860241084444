import { ClusterQuestionContent } from 'tabs/topQuestions/types/InternalTypes'
import { comparingFields } from 'tabs/topQuestions/utils/ArrayUtils'
import { TopQuestionsContentSlice } from 'tabs/topQuestions/lib/questions_content/questionsContentTypes'

export const selectClusterQuestionsWithContent = (clusterId: string) => {
  return (store: any): Partial<ClusterQuestionContent>[] => {
    const topQuestionsState: TopQuestionsContentSlice = store.questionsContentSlice
    const clusterQuestions = topQuestionsState.questionDetails
      .filter(q => q.mongoClusterId === clusterId)
      .filter(q => q.questionContent != null)
    return clusterQuestions.sort(comparingFields('mongoLogId'))
  }
}
