import { QuestionsDataResponse } from 'tabs/topQuestions/types/ApiResponses'
import {
  CLEAR_TOP_QUESTIONS_DATA,
  NEW_QUESTION_DATA_RECEIVED,
  NewQuestionDataReceived,
  UPDATE_LOADING_QUESTIONS_DATA,
  UpdateLoadingQuestionsData,
} from 'tabs/topQuestions/lib/questions_scatter_chart/questionScatterCoordinatesSlice'

export const newQuestionDataReceived = (data: QuestionsDataResponse[]): NewQuestionDataReceived => ({
  type: NEW_QUESTION_DATA_RECEIVED,
  payload: data,
})

export const clearTopQuestionsData = () => ({
  type: CLEAR_TOP_QUESTIONS_DATA,
})

export const updateQuestionDataIsLoading = (value): UpdateLoadingQuestionsData => ({
  type: UPDATE_LOADING_QUESTIONS_DATA,
  payload: value,
})
