import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { maxTextLength, maxTextTitleLength } from 'tabs/nlp/constants'
import { TrainingStatus } from 'tabs/settings/constants/trainingStatus'

import * as S from './TextForm.style'
import { Prompt } from 'react-router-dom'

interface TextFormProps {
  trainingStatus?: string
  onClick: (text: string, textTitle: string) => Promise<void>
  isFromEdit?: boolean
  inputText?: string
  inputDescription?: string
  textAreaContent?: string
  submitButtonLabel?: string
  areInitialValues?: boolean
  setAreInitialValues?: Dispatch<SetStateAction<boolean>>
}

export const TextForm: FC<TextFormProps> = ({
  onClick,
  trainingStatus,
  submitButtonLabel,
  areInitialValues,
  setAreInitialValues,
  inputDescription,
  isFromEdit = false,
  inputText = '',
  textAreaContent = '',
}) => {
  const [textTitle, setTextTitle] = useState(inputText)
  const [text, setText] = useState(textAreaContent)
  const [errorText, setErrorText] = useState('')
  const [errorTitle, setErrorTitle] = useState('')
  const [errorAPI, setErrorAPI] = useState('')

  const callUnsavedChangesAlert = Boolean(text.trim()) || Boolean(textTitle.trim())

  useEffect(() => {
    setAreInitialValues && setAreInitialValues(inputText === textTitle && textAreaContent === text)
    if (callUnsavedChangesAlert && !isFromEdit) window.onbeforeunload = () => true
    if (isFromEdit && !areInitialValues) window.onbeforeunload = () => true

    return () => {
      window.onbeforeunload = null
    }
  }, [text, textTitle, areInitialValues])

  const handleTitleChange = e => {
    if (errorAPI) setErrorAPI('')

    const isValidTitleLength = e.target.value.length <= maxTextTitleLength

    if (!isValidTitleLength) {
      setErrorTitle(`You've reached the max length for the text title value — ${maxTextTitleLength} characters`)
    } else {
      setErrorTitle('')
    }

    setTextTitle(e.target.value)
  }

  const handleTextChange = e => {
    if (errorAPI) setErrorAPI('')

    const isValidTextLength = e.target.value.length <= maxTextLength

    if (!isValidTextLength) {
      setErrorText(`You've reached the max length for the text value — ${maxTextLength} characters`)
    } else {
      setErrorText('')
    }

    setText(e.target.value)
  }

  const handleClick = () => {
    const isValidForm = !errorText && !errorTitle && text
    if (isValidForm) {
      onClick(text.trim(), textTitle.trim())
        .then(() => {
          setText('')
          setTextTitle('')
        })
        .catch(error => {
          setErrorAPI(error.message || 'Something went wrong')
        })
    }
  }

  const formError = errorTitle || errorText || errorAPI

  const isSubmitButtonDisabled = !text.trim() || formError || areInitialValues

  return (
    <S.Container isFromEdit={isFromEdit}>
      {!isFromEdit && (
        <Prompt
          when={callUnsavedChangesAlert}
          message="Are you sure you want to leave this page without saving your current changes?"
        />
      )}
      <S.Title isFromEdit={isFromEdit}>{inputDescription || 'Text'}</S.Title>
      <S.Form>
        <S.InputContainer isError={Boolean(formError)} isFromEdit={isFromEdit}>
          <S.TitleInput
            onChange={handleTitleChange}
            value={textTitle}
            placeholder={`${text && !textTitle ? 'Untitled' : 'Enter title'}`}
          />

          <S.Textarea
            isFromEdit={isFromEdit}
            placeholder="+ Insert text here"
            value={text}
            onChange={handleTextChange}
            isError={formError}
          />
        </S.InputContainer>
        <S.Error>{formError}</S.Error>
        <S.Button onClick={handleClick} disabled={isSubmitButtonDisabled}>
          {submitButtonLabel || 'Add text'}
        </S.Button>
      </S.Form>
      {trainingStatus === TrainingStatus.PENDING && <S.DisableScreen />}
    </S.Container>
  )
}
