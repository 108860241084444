import { BACKEND_URL } from 'configs'
import { FetchClusteringStatusResponse, StartClusteringResponse } from 'tabs/topQuestions/types/ApiResponses'
import {
  FETCH_CLUSTERING_STATUS_FAILED,
  FETCH_CLUSTERING_STATUS_REQUEST,
  FETCH_CLUSTERING_STATUS_SUCCESS,
  FetchClusteringStatusFailed,
  FetchClusteringStatusRequest,
  FetchClusteringStatusSuccess,
  START_CLUSTERING_FAILED,
  START_CLUSTERING_PENDING,
  START_CLUSTERING_SUCCESS,
  StartClusteringFailed,
  StartClusteringPending,
  StartClusteringSuccess,
} from 'tabs/topQuestions/lib/top_questions_config/clusteringConfigurationSlice'


export const startClustering = (botId: number, clusteringSensitivity: number) => {
  return async (dispatch) => {
    dispatch(startClusteringRequest())
    const requestBody = { botId, configuration: { clusteringSensitivity } }
    return fetch(`${BACKEND_URL}/top-questions/begin-clustering`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then(async response => {
      if (response.status == 200) {
        console.log('Start clustering success')
        const data = await response.json() as StartClusteringResponse
        dispatch(startClusteringSuccess(data))
      } else {
        console.log('Start clustering failed')
        dispatch(startClusteringFailed())
      }
    })
  }
}


export const fetchCurrentClusteringStatus = (botId: number) => {
  return async (dispatch) => {
    try {
      dispatch(fetchClusteringStatusRequest())
      const response = await fetch(`${BACKEND_URL}/bot/${botId}/top-questions/clustering-status`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      const data = await response.json() as FetchClusteringStatusResponse
      dispatch(fetchClusteringStatusSuccess(data))
    } catch (error) {
      dispatch(fetchClusteringStatusFailed(error))
    }
  }
}


const fetchClusteringStatusFailed = (error: string): FetchClusteringStatusFailed => ({
  type: FETCH_CLUSTERING_STATUS_FAILED,
  payload: error,
})

const fetchClusteringStatusRequest = (): FetchClusteringStatusRequest => ({
  type: FETCH_CLUSTERING_STATUS_REQUEST,
  payload: undefined,
})
const fetchClusteringStatusSuccess = (data: FetchClusteringStatusResponse): FetchClusteringStatusSuccess => ({
  type: FETCH_CLUSTERING_STATUS_SUCCESS,
  payload: data,
})

const startClusteringRequest = (): StartClusteringPending => ({
  type: START_CLUSTERING_PENDING,
  payload: undefined,
})
const startClusteringSuccess = (data: StartClusteringResponse): StartClusteringSuccess => ({
  type: START_CLUSTERING_SUCCESS,
  payload: data,
})

const startClusteringFailed = (): StartClusteringFailed => ({
  type: START_CLUSTERING_FAILED,
  payload: undefined,
})

