import { TopQuestionsSearchSliceState } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchTypes'

export const selectQuestionTypeFilter = (state: any) => {
  const topQuestionsSearch: TopQuestionsSearchSliceState = state.topQuestionsSearch
  const selectedQuestionType = topQuestionsSearch.filter.type
  return selectedQuestionType == null ? '' : selectedQuestionType
}

export const selectQuestionClustersFilter = (state: any) => {
  const topQuestionsSearch: TopQuestionsSearchSliceState = state.topQuestionsSearch
  return topQuestionsSearch.filter
}
