import { useDispatch, useSelector } from 'react-redux'
import { selectActiveBot } from 'tabs/home/reducers/selectors'
import React, { useEffect } from 'react'
import { fetchTopQuestions } from 'tabs/topQuestions/lib/top_questions/topQuestionsApi'
import { RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import { TopQuestionsPageContent } from 'tabs/topQuestions/components/top_questions_content/TopQuestionsPageContent'
import { selectTopQuestionsRequest } from 'tabs/topQuestions/lib/top_questions/topQuestionsSelectors'
import { selectQuestionTypeFilter } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchSelectors'

export const TopQuestionsLoadingPage = () => {
  const dispatch = useDispatch()
  const activeBot = useSelector(selectActiveBot)
  const activeBotId = activeBot.id
  const selectedQuestionType = useSelector(selectQuestionTypeFilter)

  const topQuestionsRequestStatus = useSelector(selectTopQuestionsRequest)

  useEffect(() => {
    if (activeBotId != null && topQuestionsRequestStatus !== RequestStatus.PENDING) {
      dispatch(fetchTopQuestions(activeBotId, selectedQuestionType))
    }
  }, [activeBotId, selectedQuestionType])

  switch (topQuestionsRequestStatus) {
    case RequestStatus.NONE:
    case RequestStatus.PENDING:
      return <LoaderScreen />
    case RequestStatus.RESOLVED:
      return <TopQuestionsPageContent />
    case RequestStatus.REJECTED:
      return <h4>Failed to retrieve top questions</h4>
  }
}
