/* eslint-disable */
import { CSSProperties } from 'react'
import { userFeedbackTableConfig } from '../configs'

export const userFeedbackTableRowConfigs: Record<string, CSSProperties> = {
  question: {
    maxWidth: userFeedbackTableConfig.userQuestion.maxWidth,
  },
  botAnswer: {
    maxWidth: userFeedbackTableConfig.botAnswer.maxWidth,
  },
  userFeedback: {
    maxWidth: userFeedbackTableConfig.userFeedback.maxWidth,
  },
  score: {
    maxWidth: userFeedbackTableConfig.score.maxWidth,
    textAlign: 'center',
  },
  solveButton: {
    maxWidth: userFeedbackTableConfig.solveButton.maxWidth,
  },
  createdAt: { maxWidth: userFeedbackTableConfig.createdAt.maxWidth },
  removeButton: {
    maxWidth: userFeedbackTableConfig.removeButton.maxWidth,
  },
}

export const userFeedbackTableRemoveConfig = {
  title: 'Remove User Feedback?',
  content: `Are you sure you want to remove this user feedback? Once you've done it, you won't be able to revert the changes.`,
}
