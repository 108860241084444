/* eslint-disable */
import React, { FC } from 'react'
import { connect } from 'react-redux'

import PaperHeader from '../PaperHeader'

import { FunnelType } from 'models/FunnelTypes'

import * as S from './style'

const LookerStudioEmbeddingWrap: FC = ({}) => {
  // const { startDate, endDate } = useContext(DateControlContext)

  return (
    <S.Container>
      <PaperHeader title="Looker Studio Report" />
      <S.TableWrap>
        {
          <iframe
            height="800"
            width="100%"
            src="https://lookerstudio.google.com/embed/reporting/bb8341a9-c113-41d0-810c-ad4856e16647/page/9CrqD"
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        }
      </S.TableWrap>
    </S.Container>
  )
}

const mapStateToProps = (state: { funnels: FunnelType[]; activeBot: any }) => ({
  funnels: state.funnels,
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(LookerStudioEmbeddingWrap)
