import React, { FC } from 'react'

import Tooltip from '../../../../uiKit/TooltipInfo/Tooltip'
import SecondaryButton from '../../../../uiKit/buttons/SecondaryButton/SecondaryButton'

import classes from './styles.module.scss'

interface Props {
  onOpen?: () => void
  title: string
  tooltipText?: string
}

const PaperHeader: FC<Props> = ({ onOpen, title, tooltipText }) => {
  return (
    <div className={classes.header}>
      <div className={classes.headerWrap}>
        <p className={classes.title}>{title}</p>
        {tooltipText && <Tooltip tooltipId={'TooltipInfo-attribute'} tooltipText={tooltipText} />}
      </div>
      {onOpen && <SecondaryButton title="Create funnel" onClick={onOpen} />}
    </div>
  )
}

export default PaperHeader
