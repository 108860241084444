/* eslint-disable */
import styled from 'styled-components'
import * as Styles from '../styles'
import { TrainButtonProps } from '../styles'

export type CreateUnknowQuestionButtonProps = TrainButtonProps & {
  filled?: boolean
}

const getButtonBackgroundColor = ({ disabled, filled }: CreateUnknowQuestionButtonProps) => {
  if (disabled) return '#C7CAD6'
  if (filled) return 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)'
  return 'white'
}

const getButtonFontColor = ({ disabled, filled }: CreateUnknowQuestionButtonProps) => {
  if (disabled || filled) return 'white'
  return 'black'
}

const CreateUnknowQuestionButton = styled(Styles.TrainButton)<CreateUnknowQuestionButtonProps>`
  white-space: nowrap;
  background: ${({ disabled, filled }) => getButtonBackgroundColor({ disabled, filled })};
  color: ${({ disabled, filled }) => getButtonFontColor({ disabled, filled })};
  border: none;
  outline: none;
  margin-left: auto;
`

const ModalButton = styled(CreateUnknowQuestionButton)`
  margin: 0;
`

const AssignAdminBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export { CreateUnknowQuestionButton, ModalButton, AssignAdminBlock }
