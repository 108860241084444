/* eslint-disable */
import styled from 'styled-components'

const DeleteButton = styled.div`
  cursor: pointer;

  &:hover {
    background-color: rgba(90, 152, 247, 0.2);
  }
`

const AbbreviationItemContainer = styled.div`
  border: 1px solid #ebebef;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  background-color: white;
  height: 30px;
  max-width: 120px;
  font-size: 13px;
`

const AbbreviationDeleteContainer = styled(DeleteButton)`
  position: relative;
  padding: 6px;
  min-width: 25px;
  border-radius: 5px;

  &:hover {
    background-color: rgba(90, 152, 247, 0.2);
  }
`

const AbbreviationDeleteButton = styled.div`
  &:before,
  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 45%;
    width: 1px;
    content: ' ';
    background-color: #333;
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
`

const AbbreviationConfirmDeleteButton = styled(DeleteButton)`
  position: absolute;
  padding: 2px;
  top: -25px;
  left: -15px;
  max-width: 60px;
  width: max-content;
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 239);
  font-size: 12px;
  color: red;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 22px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: rgb(235, 235, 239) transparent transparent transparent;
  }
`

export {
  AbbreviationItemContainer,
  AbbreviationDeleteButton,
  AbbreviationDeleteContainer,
  AbbreviationConfirmDeleteButton,
}
