/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from 'react'

import { AbbreviationModel, AbbreviationsContextTypes } from './type'
import { abbreviationService } from 'tabs/nlp/api/abbreviations'
import { useDebounceCallback } from '../unansweredQuestions/hooks'
import { AbbreviationCreateAPI } from 'tabs/nlp/api/types'
import { useUpdateRequestsMapping } from './hooks'

export const AbbreviationsContext = createContext<AbbreviationsContextTypes>({
  abbreviationsRecords: [],
  updateSearchValue: async () => {},
  createNewAbbreviation: async () => {},
  deleteAbbreviation: async () => {},
  updateAbbreviation: async () => {},
  putOnHoldUpdateRequest: () => {},
  sendOnHoldRequestsIfNeed: () => {},
  uploadAbbreviationFromFile: async () => {},
})

export const useAbbreviations = () => useContext(AbbreviationsContext)

export const AbbreviationsProvider = ({ children }) => {
  const { putOnHoldUpdateRequest, sendOnHoldRequestsIfNeed, updateRecord } =
    useUpdateRequestsMapping<AbbreviationModel>(abbreviationService.updateAbbreviation)

  const [abbreviationsRecords, setAbbreviationsRecords] = useState<AbbreviationModel[]>([])

  const [search, setSearchValue] = useState<string>('')

  const searchAbbreviations = async () => {
    abbreviationService.searchAbbreviations({ search }).then(abbreviationList => {
      setAbbreviationsRecords(abbreviationList || [])
    })
  }

  const createNewAbbreviation = async (abbreviation: AbbreviationCreateAPI) => {
    return abbreviationService.createNewAbbreviation(abbreviation).then(searchAbbreviations)
  }

  const uploadAbbreviationFromFile = async (body: FormData) => {
    return abbreviationService.uploadAbbreviationFromFile(body).then(searchAbbreviations)
  }

  const deleteAbbreviation = async (id: string) => {
    return abbreviationService.deleteAbbreviation(id).then(searchAbbreviations)
  }

  const updateAbbreviation = async (id: string, abbreviation: AbbreviationModel, train = false) => {
    return updateRecord(id, abbreviation, { train })
  }

  useEffect(() => {
    searchAbbreviations()
  }, [search])

  const updateSearchValue = useDebounceCallback(setSearchValue)

  return (
    <AbbreviationsContext.Provider
      value={{
        abbreviationsRecords,
        updateSearchValue,
        createNewAbbreviation,
        deleteAbbreviation,
        updateAbbreviation,
        putOnHoldUpdateRequest,
        sendOnHoldRequestsIfNeed,
        uploadAbbreviationFromFile,
      }}>
      {children}
    </AbbreviationsContext.Provider>
  )
}
