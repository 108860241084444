/* eslint-disable */
import { CSSProperties } from 'react'
import { UnkownQuestionsTableFields } from '../types'
import { unansweredQuestionsTableConfig } from '../configs'

export const unkownQuestionTableRowConfigs: Record<UnkownQuestionsTableFields, CSSProperties> = {
  question: {
    maxWidth: unansweredQuestionsTableConfig.question.maxWidth,
  },
  answer: {
    maxWidth: unansweredQuestionsTableConfig.answer.maxWidth,
  },
  train: {
    maxWidth: unansweredQuestionsTableConfig.trainButton.maxWidth,
  },
  remove: {
    maxWidth: unansweredQuestionsTableConfig.removeButton.maxWidth,
  },
  type: {
    maxWidth: unansweredQuestionsTableConfig.type.maxWidth,
    // width: unansweredQuestionsTableConfig.type.maxWidth,
  },
  assignUser: {
    maxWidth: unansweredQuestionsTableConfig.assignUser.maxWidth,
    // width: unansweredQuestionsTableConfig.assignUser.maxWidth,
  },
  edit: {
    maxWidth: unansweredQuestionsTableConfig.editButton.maxWidth,
  },
  createdAt: {
    maxWidth: unansweredQuestionsTableConfig.createAt.maxWidth,
    flexWrap: 'wrap',
  },
}

export const unknownQuestionTableRemoveConfig = {
  title: 'Remove unknown question?',
  content: `Are you sure you want to remove this unknown question? Once you've done it, you won't be able to revert the changes.`,
}
