import { useMemo, useState } from 'react'
import { UnknownQuestionModel, UnknownQuestionType } from '../types'
import { unansweredQuestionTypeLabels } from '../configs'

export const useUnknownQuestionsOptions = (question: UnknownQuestionModel) => {
    const [currentValue, setCurrentValue] = useState(question.type)

    const updateValue = (value: UnknownQuestionType) => {
        setCurrentValue(value)
    }

    const currentOption = useMemo(() => {
        return { value: currentValue, label: unansweredQuestionTypeLabels[currentValue] }
    }, [currentValue])

    return { currentValue, updateValue, currentOption }
}