import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { withStyles } from '@material-ui/core'
import { StyledComponentProps } from '@material-ui/core/styles/withStyles'
import { useDispatch } from 'react-redux'

import { updateClusterHovered } from 'tabs/topQuestions/lib/top_questions/topQuestionsActions'

const styles = theme => ({
  titleColorWrapper: {
    gap: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
  },
  colorBlock: {
    width: theme.typography.pxToRem(25),
    height: theme.typography.pxToRem(25),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  questionsCount: {
    color: 'white',
  },
})

type QuestionClusterCountProps = {
  totalQuestionCount: number
  clusterColor: string
  clusterId: string
} & StyledComponentProps

export const QuestionClusterCount = withStyles(styles)(
  ({ classes, totalQuestionCount, clusterColor, clusterId }: QuestionClusterCountProps) => {
    const dispatch = useDispatch()

    const onMouseEnter = () => {
      dispatch(updateClusterHovered({ clusterId, hovered: true }))
    }

    const onMouseLeave = () => {
      dispatch(updateClusterHovered({ clusterId, hovered: false }))
    }

    return (
      <div className={classes.titleColorWrapper} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Tooltip title="Total questions in cluster">
          <div className={classes.colorBlock} style={{ backgroundColor: clusterColor }}>
            <Typography className={classes.questionsCount}>{totalQuestionCount}</Typography>
          </div>
        </Tooltip>
      </div>
    )
  },
)
