/* eslint-disable */
import styled from 'styled-components'

const AbbreviationsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`

const AbbreviationText = styled.div`
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  cursor: pointer;
`

const AbbreviationAddButton = styled.div`
  border: 1px solid #ebebef;
  border-radius: 5px;
  background-color: white;
  height: 30px;
  position: relative;
  min-width: 30px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 45%;
    width: 1px;
    content: ' ';
    background-color: #333;
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
  }

  &:hover {
    background-color: rgba(90, 152, 247, 0.2);
  }
`

export { AbbreviationsContainer, AbbreviationText, AbbreviationAddButton }
