import { GenerateClusterNameResolved, TopQuestionsSlice } from 'tabs/topQuestions/lib/top_questions/topQuestionsConstnats'
import { PendingRequest, RequestStatus } from 'tabs/topQuestions/types/GenericTypes'

import { GenerateClusterNameResponsePayload } from 'tabs/topQuestions/types/InternalTypes'

export function handleGenerateNameResponse(state: TopQuestionsSlice,
                                           action: GenerateClusterNameResolved): Record<string, PendingRequest<GenerateClusterNameResponsePayload>> {
  return {
    ...state.generateNameRequest,
    [action.payload.clusterId]: {
      status: RequestStatus.RESOLVED,
      data: action.payload,
    },
  }
}
