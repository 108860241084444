import React, { FC } from 'react'
import * as LocalStyles from '../../unansweredQuestions/createQuestionModal/styles'
import * as Styles from '../styles'
import { AbbreviationCreateModalProps } from './types'
import ModalTemplate from 'uiKit/ModalTemplate'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import styles from './styles.module.scss'
import { useKeyOnChange, useModalToggle } from '../../unansweredQuestions/hooks'
import { ExampleTemplateContainer } from './exampleTemplateContainer'
import { AbbreviationUploadForm } from './uploadForm'

const Component: FC<AbbreviationCreateModalProps> = ({ isOpen, toggleModal }) => {
  return (
    <>
      <LocalStyles.CreateUnknowQuestionButton filled onClick={toggleModal}>
        Upload Abbreviation from file
      </LocalStyles.CreateUnknowQuestionButton>
      <ModalTemplate
        containerClasses={styles.modal}
        open={isOpen}
        title="Upload abbreviations from file"
        titleIcon={
          <Styles.TitleIconWrapper>
            <SparklesIcon />
          </Styles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <Styles.ModalContainer>
          <Styles.SubHeader>Please download example template and fill it with desired abbreviations.</Styles.SubHeader>
          <Styles.SubHeader>
            Note: Provided example abbreviation from a document will be added as well, erase it if it's not necessary
            for inclusion.
          </Styles.SubHeader>
          <ExampleTemplateContainer />

          <AbbreviationUploadForm toggleModal={toggleModal} />
          <Styles.ModalButtonContainer>
            <LocalStyles.ModalButton onClick={toggleModal}>Cancel</LocalStyles.ModalButton>
          </Styles.ModalButtonContainer>
        </Styles.ModalContainer>
      </ModalTemplate>
    </>
  )
}

export const AbbreviationBulkFileUploadModal = () => {
  const { isOpen, toggleModal } = useModalToggle()

  const key = useKeyOnChange(isOpen)

  return <Component {...{ isOpen, toggleModal, key }} />
}
