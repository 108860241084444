import React, { FC, useEffect, useRef, useState } from 'react'
import * as Styles from './styles'
import { AbbreviationTableRowProps } from '../../types'
import { useAbbreviations } from '../../../context'

export const DefinitionTableRowCell: FC<AbbreviationTableRowProps> = ({ abbreviationsRecord }) => {
  const { updateAbbreviation } = useAbbreviations()

  const [definition, setDefinition] = useState<string>(abbreviationsRecord.definition)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isEditMode && inputRef.current) inputRef.current.focus()
  }, [isEditMode])

  const openEditWindow = () => {
    setIsEditMode(true)
  }

  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDefinition(event.target.value)
  }

  const onBlurEvent = () => {
    setIsEditMode(false)
    if ((abbreviationsRecord.definition = definition)) {
      abbreviationsRecord.definition = definition
      updateAbbreviation(abbreviationsRecord.id, abbreviationsRecord, true)
    }
  }

  return (
    <Styles.DefinitionContainer>
      {isEditMode && (
        <Styles.DefinitionEditBlock>
          <Styles.DefinitionTextArea value={definition} onChange={textAreaChange} onBlur={onBlurEvent} ref={inputRef} />
        </Styles.DefinitionEditBlock>
      )}
      {abbreviationsRecord.definition ? (
        <Styles.AnswerText onClick={openEditWindow}>{abbreviationsRecord.definition}</Styles.AnswerText>
      ) : (
        <Styles.DefinitionAddButton onClick={openEditWindow}>Add definition</Styles.DefinitionAddButton>
      )}
    </Styles.DefinitionContainer>
  )
}
