import {
  usersAutocompletePending,
  usersAutocompleteRejected,
  usersAutocompleteResolved,
} from 'tabs/nlp/components/unansweredQuestions/assignUser/slice/assign-user-slice'
import { UserAutocomplete } from 'tabs/nlp/components/unansweredQuestions/assignUser/model/internal-types'
import API from 'services/api'
import { alertSuccess } from 'api'
import { getApiUrlTemplateFunc } from 'helpers/getApiUrlTemplateFunc'

const adminUserTemplate = getApiUrlTemplateFunc('users')
const questionsTemplate = getApiUrlTemplateFunc('questions')

export const fetchAutocompleteUsers = (query: string) => {
  return async dispatch => {
    dispatch(usersAutocompletePending())
    return fetch(adminUserTemplate('autocomplete', { query }), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then(async response => {
      if (response.status == 200) {
        const data = await response.json()
        dispatch(usersAutocompleteResolved(data as UserAutocomplete[]))
      } else {
        dispatch(usersAutocompleteRejected())
      }
    })
  }
}

export const fetchAssignUserToUnkQuestion = (
  userId: number,
  questionId: string,
  sendNotification?: boolean,
  withAlert = true,
) => {
  return API.put(questionsTemplate('update-assigned-user'), { userId, questionId, sendNotification }).then(response => {
    if (withAlert) alertSuccess('User assigned to question!')
    return response
  })
}
