class ValueMissingError extends Error {
}

export const selectExceptionally = <T>(value: T | undefined): T => {
  if (value != null) {
    return value
  } else {
    throw new ValueMissingError('Unexpected empty value')
  }
}

