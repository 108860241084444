import React, { ChangeEventHandler, FC } from 'react'
import { SearchIcon } from 'uiKit/icons/Icons'
import * as Styles from './styles'
import { SearchFieldProps } from './type'

export const TrainSearchInput: FC<SearchFieldProps> = ({ placeholder, updateSearchValue }) => {
  const onSearchChanged: ChangeEventHandler<HTMLInputElement> = event => {
    updateSearchValue(event.target.value)
  }

  return (
    <Styles.SearchContainer>
      <Styles.SearchWrapper>
        <Styles.Input placeholder={placeholder} onChange={onSearchChanged} />
        <SearchIcon width="20" height="20" color="#D8D8D8" />
      </Styles.SearchWrapper>
    </Styles.SearchContainer>
  )
}
