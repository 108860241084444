/* eslint-disable */
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
`

const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
`

const FiltersButtonsContainer = styled(FiltersContainer)`
  width: auto;
  margin-left: auto;
`

const SubHeader = styled.div`
  font-size: 14px;
  color: #616581;
`

const getTrainButtonColor = (disabled: boolean) => {
  if (disabled) return '#C7CAD6'
  return 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)'
}

export type TrainButtonProps = {
  disabled?: boolean
}

const TrainButton = styled.button<TrainButtonProps>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  outline: none;
  box-shadow: 0px 3px 9px rgba(19, 69, 186, 0.206267);
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  background: ${({ disabled }) => getTrainButtonColor(disabled)};
  color: white;
  border: none;
  max-width: fit-content;
`

const RemoveButton = styled(TrainButton)`
  background: linear-gradient(285.2deg, #ff7961 -35.9%, #f44336 61.17%);
`

const DownloadButton = styled(TrainButton)`
  max-width: 100%;
  justify-content: center;
`

const ModalIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 1rem 1rem;
`

const AnswerInput = styled.input`
  padding: 10px;
  outline: none;
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  line-height: 19px;
  background-color: white;
  color: black;
  border: 1px solid #c7cad6;
  min-height: 40px;
`

const AnswerTextArea = styled(AnswerInput).attrs({ as: 'textarea' })`
  min-height: 100px;
`

const AnswerText = styled.div`
  width: 100%;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
`

const TitleIconWrapper = styled.span`
  padding-left: 8px;
`

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const FileDownloadSuccessfully = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

export {
  Container,
  SubHeader,
  TrainButton,
  RemoveButton,
  AnswerInput,
  AnswerText,
  ModalIconContainer,
  TitleIconWrapper,
  ModalContainer,
  ModalButtonContainer,
  FiltersContainer,
  AnswerTextArea,
  FiltersButtonsContainer,
  DownloadButton,
  FileDownloadSuccessfully,
}
