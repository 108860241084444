/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from 'react'

import { userFeedbackService } from 'tabs/nlp/api/userFeedback'
import { unkownQuestionService } from 'tabs/nlp/api/unknownQuestions'
import { useDebounceCallback } from '../unansweredQuestions/hooks'
import {
  FeedbackAboutQuestionModel,
  FeedbackFilterType,
  FeedbackHeaderSortMap,
  FeedbackSearchAPI,
  FeedbackSortConfig,
  UserFeedbackContextType,
} from './types'
import { UnknownQuestionModel } from '../unansweredQuestions/types'
import { feedbackAPIMessages } from 'tabs/nlp/api/configs'
import { alertSuccess } from '../../../../api'
import { DefaultSearchAPI, UnknownQuestionCreateAPI } from 'tabs/nlp/api/types'
import { convertFilterToAPIFormat, convertSortParamsToAPIFormat } from 'uiKit/table/config'
import { usePagination } from 'uiKit/table/pagination/hooks'
import { paginationConfigs } from 'tabs/nlp/constants/paginationSize'

const filterKeys: Array<keyof FeedbackFilterType> = ['solved']

export const UserFeedbackContext = createContext<UserFeedbackContextType>({
  userFeedbackRecords: [],
  updateSearchValue: async () => {},
  deleteUserFeedback: async () => {},
  retrainQuestion: async () => {},
  creteNewQuestion: async () => {},
  sortHeadersMap: {},
  isSortingActive: false,
  dropSortParams: () => {},
  page: 0,
  setPage: () => {},
  count: 0,
  areRecordsInitialized: false,
})

export const useUserFeedback = () => useContext(UserFeedbackContext)

export const UserFeedbackProvider = ({ children }) => {
  const {
    page,
    setPage,
    setRecordsInitialization,
    areRecordsInitialized,
    setCount,
    count: recordsCount,
  } = usePagination()

  const [userFeedbackRecords, setUserFeedbackRecords] = useState<FeedbackAboutQuestionModel[]>([])

  const [search, setSearchValue] = useState<string>('')
  const [sortParams, setSortParams] = useState<FeedbackSortConfig>({})
  const [filter, setFilter] = useState<FeedbackFilterType>({})

  const getSearchRequestParams = (): FeedbackSearchAPI => {
    const sort = convertSortParamsToAPIFormat(sortParams)
    const filterParams = convertFilterToAPIFormat<FeedbackFilterType>(filter, filterKeys)

    return { search, page, limit: paginationConfigs.limit, sort, ...filterParams }
  }

  const searchUserFeedback = async () => {
    const params = getSearchRequestParams()
    return userFeedbackService.searchUserFeedback(params).then(({ records, count }) => {
      setUserFeedbackRecords(records || [])
      if (recordsCount !== count) setCount(count)
      if (!areRecordsInitialized) setRecordsInitialization(true)
    })
  }

  const deleteUserFeedback = async (id: string, withAlert = true) => {
    return userFeedbackService.deleteUserFeedback(id, withAlert).then(searchUserFeedback)
  }

  const retrainQuestion = async (feedbackId: string, question: Partial<UnknownQuestionModel>) => {
    await unkownQuestionService.updateQuestion(question.id, question, false)
    await unkownQuestionService.trainQuestion({ id: question.id } as UnknownQuestionModel, { followUp: false }, false)

    await userFeedbackService.updateFeedback(feedbackId, { solved: true }).then(searchUserFeedback)
    alertSuccess(feedbackAPIMessages.trained)
  }

  const creteNewQuestion = async (feedbackId: string, question: UnknownQuestionCreateAPI) => {
    await unkownQuestionService.createQuestion(question, true, false)

    await userFeedbackService.updateFeedback(feedbackId, { solved: true }).then(searchUserFeedback)
    alertSuccess(feedbackAPIMessages.created)
  }

  useEffect(() => {
    searchUserFeedback()
  }, [search, sortParams, filter, page])

  const updateSearchValue = useDebounceCallback(setSearchValue)

  const dropSortParams = () => {
    setSortParams({})
    setFilter({})
    Object.values(sortHeadersMap).forEach(({ directionState }) => directionState[1](true))
  }

  const isSortingActive = Object.keys(sortParams).length > 0 || Object.keys(filter).length > 0

  const sortHeadersMap: FeedbackHeaderSortMap = {
    'Created At': {
      directionState: React.useState<boolean>(true),
      onClickCallback: () => {
        const createdAt = sortParams?.createdAt === 'asc' ? 'desc' : 'asc'
        setSortParams(prev => ({ ...prev, createdAt }))
        setPage(0)
      },
    },
    'Match Score': {
      directionState: React.useState<boolean>(true),
      onClickCallback: () => {
        const matchScore = sortParams?.matchScore === 'asc' ? 'desc' : 'asc'
        setSortParams(prev => ({ ...prev, matchScore }))
        setPage(0)
      },
    },
    Solve: {
      directionState: React.useState<boolean>(true),
      onClickCallback: () => {
        setFilter(prev => ({ ...prev, solved: !prev.solved }))
        setPage(0)
      },
    },
  }

  return (
    <UserFeedbackContext.Provider
      value={{
        updateSearchValue,
        deleteUserFeedback,
        userFeedbackRecords,
        retrainQuestion,
        creteNewQuestion,
        sortHeadersMap,
        dropSortParams,
        isSortingActive,
        page,
        setPage,
        count: recordsCount,
        areRecordsInitialized,
      }}>
      {children}
    </UserFeedbackContext.Provider>
  )
}
