import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendFetchTopQuestionsLineChartData } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartApi'
import { selectEndDateFilter, selectQuestionsData, selectStartDateFilter } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartSelectors'
import { RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import { ChartDataSets, toChartDatasets } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartConverter'
import Chart from 'chart.js/auto'
import { selectClusterColorsMap, selectClusters } from 'tabs/topQuestions/lib/top_questions/topQuestionsSelectors'
import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'
import { fromDateObject } from 'tabs/topQuestions/components/top_questions_data_range_select/TopQuestionsDataRangeSelect'


function findHoveredClusterIdx(clustersObj: Record<string, ClusterDetails>): string | undefined {
  const clusters = Object.values(clustersObj)
  const hoveredCluster = clusters.find(c => c.hovered)
  return hoveredCluster?.clusterUniqueId
}

export const TopQuestionsLineChart = () => {
  const dispatch = useDispatch()
  const clusterColors = useSelector(selectClusterColorsMap)
  const lineChartData = useSelector(selectQuestionsData)
  const htmlCanvasElement = useRef<HTMLCanvasElement | undefined>()
  const chartRef = useRef<Chart>(null)
  const clusters = useSelector(selectClusters)
  const hoveredClusterUniqueId = findHoveredClusterIdx(clusters)
  const selectedStartDate = useSelector(selectStartDateFilter)
  const selectedEndDate = useSelector(selectEndDateFilter)
  const startTimestamp = fromDateObject(selectedStartDate).unix()
  const endTimestamp = fromDateObject(selectedEndDate).unix()

  useEffect(() => {
    if (selectedStartDate != undefined && selectedEndDate != undefined) {
      dispatch(sendFetchTopQuestionsLineChartData(startTimestamp, endTimestamp))
    }
  }, [])

  useEffect(() => {
    if (lineChartData.status === RequestStatus.RESOLVED && startTimestamp != undefined && endTimestamp != undefined) {
      const [uniqueClusterIds, datasets] = toChartDatasets(
        startTimestamp,
        endTimestamp,
        lineChartData.data,
        clusterColors,
        hoveredClusterUniqueId,
      )
      if (chartRef.current == undefined) {
        // @ts-ignore
        chartRef.current = new Chart(htmlCanvasElement.current, {
          type: 'line',
          data: buildChartData(uniqueClusterIds, datasets),
          options: {
            responsive: true,
            animation: { duration: 0 },
            plugins: {

              legend: {
                display: false,
              },
              title: {
                display: false,
              },
              tooltip: {
                mode: 'y', // Show tooltip for each dataset
              },

            },
            interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false,
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Day',
                },
              },
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: 'Number of questions',
                },
              },
            },
          },
        })
        chartRef.current.render()
      } else {
        chartRef.current.data = buildChartData(uniqueClusterIds, datasets)
        chartRef.current.update()
      }
    }
  }, [lineChartData.status, hoveredClusterUniqueId, startTimestamp, endTimestamp])


  function buildChartData(uniqueClusterIds: string[], datasets: ChartDataSets[]) {
    return {
      labels: uniqueClusterIds,
      datasets,
    }
  }

  return <div className={'questionClusterChartWrapper'}>
    <canvas height={280} ref={htmlCanvasElement} />
  </div>

}
