import { ClusterDetailsResponse } from 'tabs/topQuestions/types/ApiResponses'
import { CLEAR_CLUSTER_QUESTION, ClearClusterQuestion, NEW_CLUSTER_QUESTIONS_RECEIVED, NewClusterQuestionsReceived } from 'tabs/topQuestions/lib/questions_content/questionsContentTypes'
import {
  ClusteringExecutionData,
  LINE_CHART_DATA_REQ_PENDING,
  LINE_CHART_DATA_REQ_REJECTED,
  LINE_CHART_DATA_REQ_RESOLVED,
  LINE_CHART_FILTER_END_DATE_CHANGE,
  LINE_CHART_FILTER_START_DATE_CHANGE,
  LineChartDataReqPending,
  LineChartDataReqRejected,
  LineChartDataReqResolved,
  LineChartFilterEndDateChange,
  LineChartFilterStartDateChange,
} from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartTypes'
import { DateObject } from 'tabs/topQuestions/utils/DateUtils'

export const newClusterQuestionsReceived = (payload: ClusterDetailsResponse): NewClusterQuestionsReceived => ({
  type: NEW_CLUSTER_QUESTIONS_RECEIVED,
  payload: payload,
})

export const clearClusterQuestion = (): ClearClusterQuestion => ({
  type: CLEAR_CLUSTER_QUESTION,
  payload: undefined,
})

export const lineChartDataReqPending = (): LineChartDataReqPending => ({
  type: LINE_CHART_DATA_REQ_PENDING,
  payload: undefined,
})
export const lineChartDataReqResolved = (payload: ClusteringExecutionData[]): LineChartDataReqResolved => ({
  type: LINE_CHART_DATA_REQ_RESOLVED,
  payload,
})

export const lineChartDataReqRejected = (): LineChartDataReqRejected => ({
  type: LINE_CHART_DATA_REQ_REJECTED,
  payload: undefined,
})

export const lineChartFilterStartDateChange = (startDate: DateObject): LineChartFilterStartDateChange => ({
  type: LINE_CHART_FILTER_START_DATE_CHANGE,
  payload: startDate,
})
export const lineChartFilterEndDateChange = (endDate: DateObject): LineChartFilterEndDateChange => ({
  type: LINE_CHART_FILTER_END_DATE_CHANGE,
  payload: endDate,
})
