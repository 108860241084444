import styled from 'styled-components'

const FilterPopoverWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px 20px 20px;
  width: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  gap: 25px;
`

const CloseButton = styled.div`
  height: 12px;
  position: absolute;
  top: 0px;
  right: 7px;
  cursor: pointer;
`

export { FilterPopoverWrapper, CloseButton }
