import React, { ChangeEventHandler, FC, useMemo, useState } from 'react'
import * as LocalStyles from '../../unansweredQuestions/createQuestionModal/styles'
import * as Styles from '../styles'
import { AbbreviationCreateModalProps } from './types'
import ModalTemplate from 'uiKit/ModalTemplate'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import styles from '../../unansweredQuestions/createQuestionModal/styles.module.scss'
import { useKeyOnChange, useModalToggle } from '../../unansweredQuestions/hooks'
import { AbbreviationsTableRowCell } from '../tableRow/cells/abbreviation'
import { useAbbreviations } from '../context'

const Component: FC<AbbreviationCreateModalProps> = ({ isOpen, toggleModal }) => {
  const { createNewAbbreviation } = useAbbreviations()

  const [fullName, setFullName] = useState<string>()
  const [abbreviations, setAbbreviations] = useState<string[]>([])
  const [definition, setDefinition] = useState<string>()

  const isDataValid = useMemo(
    () => !!fullName && !!abbreviations.length && !!abbreviations.some(Boolean),
    [fullName, abbreviations],
  )

  const onNameChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setFullName(event.target.value)
  }

  const onDefinitionChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setDefinition(event.target.value)
  }

  const onCreateButtonPressed = () => {
    if (!isDataValid) return
    createNewAbbreviation({ fullName, abbreviations, definition }).then(toggleModal)
  }

  return (
    <>
      <LocalStyles.CreateUnknowQuestionButton filled onClick={toggleModal}>
        Add New Abbreviation
      </LocalStyles.CreateUnknowQuestionButton>
      <ModalTemplate
        containerClasses={styles.modal}
        open={isOpen}
        title="Create custom abbreviation"
        titleIcon={
          <Styles.TitleIconWrapper>
            <SparklesIcon />
          </Styles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <Styles.ModalContainer>
          <Styles.SubHeader>Add phrase for abbreviations.</Styles.SubHeader>

          <Styles.AnswerInput placeholder="Enter phrase..." value={fullName} onChange={onNameChange} />

          <Styles.SubHeader>Add acronyms that will be shortcut for full name.</Styles.SubHeader>
          <AbbreviationsTableRowCell
            abbreviationsRecord={{ abbreviations, fullName, id: '' }}
            setExternalAbbreviations={setAbbreviations}
          />

          <Styles.SubHeader>Add definition</Styles.SubHeader>
          <Styles.AnswerTextArea value={definition} onChange={onDefinitionChange} />

          <Styles.ModalButtonContainer>
            <LocalStyles.ModalButton onClick={toggleModal}>Cancel</LocalStyles.ModalButton>
            <LocalStyles.ModalButton filled disabled={!isDataValid} onClick={onCreateButtonPressed}>
              Add
            </LocalStyles.ModalButton>
          </Styles.ModalButtonContainer>
        </Styles.ModalContainer>
      </ModalTemplate>
    </>
  )
}

export const AbbreviationCreateModal = () => {
  const { isOpen, toggleModal } = useModalToggle()

  const key = useKeyOnChange(isOpen)

  return <Component {...{ isOpen, toggleModal, key }} />
}
