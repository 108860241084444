import { ClusterQuestion, ClusterQuestionContent } from 'tabs/topQuestions/types/InternalTypes'
import { ClusterDetailsResponse } from 'tabs/topQuestions/types/ApiResponses'
import { distinctByKey } from 'tabs/topQuestions/utils/ArrayUtils'


export function mergeNewClusterQuestionAndExisting(existingQuestions: Partial<ClusterQuestionContent>[],
                                                   newQuestionsContent: ClusterDetailsResponse): Partial<ClusterQuestionContent>[] {

  const mongoClusterId = newQuestionsContent.clusterId
  const mergedData: Partial<ClusterQuestion>[] = [...existingQuestions]
  const mongoLogIdsMapIdxMap = new Map(existingQuestions
                                         .filter(q => q.mongoClusterId === mongoClusterId)
                                         .map((q, idx) => [q.mongoLogId, idx]))

  const newData: Partial<ClusterQuestion>[] = newQuestionsContent.questions
    .map(({ questionContent, logId }) => ({
      questionContent, mongoLogId: logId, mongoClusterId,
    }))

  for (let i = 0; i < newData.length; i++) {
    const dataItem = newData[i]
    const mongoLogId = dataItem.mongoLogId
    if (mongoLogIdsMapIdxMap.has(mongoLogId)) {
      const existingDataItemIdx = mongoLogIdsMapIdxMap.get(mongoLogId)
      mergedData.splice(existingDataItemIdx, 1, dataItem)
    } else {
      mergedData.push(dataItem)
    }
  }

  return mergedData.filter(distinctByKey('mongoLogId'))
}
