// ACTIONS

import { UpdateClusterHoveredPayload, UpdateClusterSelectedPayloadAction } from 'tabs/topQuestions/types/InternalTypes'
import { UPDATE_CLUSTER_HOVERED, UPDATE_DISPLAYED_CLUSTER, UpdateClusterDisplayedStatus, UpdateClusterHovered } from 'tabs/topQuestions/lib/top_questions/topQuestionsConstnats'


export const updateClusterDisplayed = (payload: UpdateClusterSelectedPayloadAction): UpdateClusterDisplayedStatus => ({
  type: UPDATE_DISPLAYED_CLUSTER,
  payload,
})

export const updateClusterHovered = (payload: UpdateClusterHoveredPayload): UpdateClusterHovered => ({
  type: UPDATE_CLUSTER_HOVERED,
  payload,
})
