import { COMET_GREY, WHITE, OXFORD_BLUE, SOLITUDE, BLUE } from 'constants/colors'

export default {
  container: (provided) => ({
    ...provided,
    width: '100%',
    maxWidth: '300px',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid rgba(53, 64, 82, 0.1)',
    borderRadius: 10,
    outline: 'none',
    boxShadow: 'none',
    width: '100%',
    height: 45,
    '&:hover': {
      border: '1px solid rgba(53, 64, 82, 0.1)',
      boxShadow: 'none',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: COMET_GREY,
    fontSize: 14,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    cursor: 'pointer',
    backgroundColor: state.isFocused ? SOLITUDE : WHITE,
    color: state.children === '+ Create new' ? BLUE : OXFORD_BLUE,
    '&:hover': {
      fontSize: 14,
      backgroundColor: state.isFocused ? SOLITUDE : WHITE,
      color: state.children === '+ Create new' ? BLUE : OXFORD_BLUE,
    },
  }),
  menu: provided => ({
    ...provided,
    wordBreak: 'break-all',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: 14,
  }),
}
