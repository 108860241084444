import { useEffect, useMemo, useRef } from 'react'
import { UnknownQuestionModel, UnknownQuestionType } from '../types'

export const useOutsideAlerter = <T extends HTMLElement>(callback: VoidFunction) => {
    const reference = useRef<T>()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (reference.current && !reference.current.contains(event.target)) {
                callback()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [reference])

    return reference
}

export const useQuestionTrainedStatus = (question: UnknownQuestionModel) => {
    const canBeTrained = useMemo(() => {
        return !!question.answer
            && !!question.type
            && question.type !== UnknownQuestionType.unknown
            && !question.isTrained
    }, [question])
    return { canBeTrained }
}