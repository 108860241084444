import { useRef } from 'react'

export const useThrottle = <U extends any[]>(fn: (...args: U) => void,
  throttleValue = 100): (...args: U) => void => {

  const throttleTimeoutRef = useRef<NodeJS.Timeout | undefined>()
  const lastScheduledExecutionAt = useRef<number>()

  function scheduleNextExecution(args: U, time: number) {
    throttleTimeoutRef.current = setTimeout(() => {
      fn(...args)
      throttleTimeoutRef.current = undefined
      lastScheduledExecutionAt.current = undefined
    }, time)
  }

  return (...args) => {
    const throttleTimeout = throttleTimeoutRef.current
    const nextExecutionScheduledAt = lastScheduledExecutionAt.current
    if (nextExecutionScheduledAt == null && throttleTimeout == null) {
      lastScheduledExecutionAt.current = Date.now() + throttleValue
      scheduleNextExecution(args, throttleValue)
    } else if (nextExecutionScheduledAt != null && throttleTimeout != null) {
      const timeBeforeNextExecution = nextExecutionScheduledAt - Date.now()
      if (timeBeforeNextExecution > 0) {
        clearTimeout(throttleTimeout)
        scheduleNextExecution(args, timeBeforeNextExecution)
      }
    } else {
      throw Error('Unexpected state')
    }
  }

}

