import { ClusterQuestion } from 'tabs/topQuestions/types/InternalTypes'
import { QuestionsDataResponse } from 'tabs/topQuestions/types/ApiResponses'
import { distinctByKey } from 'tabs/topQuestions/utils/ArrayUtils'


export function updateClustersMergeQuestions(oldClusterQuestions: Partial<ClusterQuestion>[],
                                             response: QuestionsDataResponse[]): Partial<ClusterQuestion>[] {
  const newData = response.map(convertQuestionsDataResponse)
  oldClusterQuestions = oldClusterQuestions != null ? oldClusterQuestions : []
  const mergedData: Partial<ClusterQuestion>[] = [...oldClusterQuestions]

  const existingDataMongoIdsMap = new Map(oldClusterQuestions
                                            .map((q, idx) => [q.mongoLogId, idx]))

  for (let i = 0; i < newData.length; i++) {
    const dataItem = newData[i]
    const mongoLogId = dataItem.mongoLogId
    if (existingDataMongoIdsMap.has(mongoLogId)) {
      const existingDataItemIdx = existingDataMongoIdsMap.get(mongoLogId)
      const existingDataItem = oldClusterQuestions[existingDataItemIdx]
      const mergedQuestion = Object.assign(existingDataItem, dataItem)
      mergedData.splice(existingDataItemIdx, 1, mergedQuestion)
    } else {
      mergedData.push(dataItem)
    }
  }
  return mergedData.filter(distinctByKey('mongoLogId'))
}


export function convertQuestionsDataResponse(response: QuestionsDataResponse): ClusterQuestion {
  return {
    pineconeId: response.pineconeId,
    mongoClusterId: response.mongoClusterId,
    y: response.y,
    x: response.x,
    mongoLogId: response.mongoLogId,
  }
}
