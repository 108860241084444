import React from 'react'

import {
  // BroadcastIcon,
  // DashboardIcon,
  // FlowsIcon,
  NlpIcon,
  SettingsIcon,
  // SupportIcon,
  // WidgetIcon,
  AnalyticsIcon,
} from '../uiKit/icons/Icons'

// import Dashboard from '../tabs/dashboard/Dashboard'
// import Builder from '../tabs/flowBuilder/Builder'
import NLP from '../tabs/nlp/NlpContainer'
// import ChatContainer from '../tabs/support/SupportContainer'
// import Broadcasts from '../tabs/broadcast/Broadcasts'
import Settings from '../tabs/settings/Settings'
// import WidgetContainer from '../tabs/widget/WidgetContainer'
import AnalyticsContainer from '../tabs/analytics/AnalyticsContainer'

import { ComponentNames } from '../constants/componentNames'
import { theme } from 'constants/theme'

export const mainRoutes = [
  // {
  //   path: 'dashboard',
  //   icon: <DashboardIcon width={40} height={20} color={theme.menu.tabListIcon} />,
  //   name: ComponentNames.Dashboard,
  //   component: Dashboard,
  // },
  {
    path: 'analytics',
    icon: <AnalyticsIcon width={40} height={20} color={theme.menu.tabListIcon} />,
    name: 'Analytics',
    component: AnalyticsContainer,
  },
  // {
  //   path: 'flows',
  //   icon: <FlowsIcon width={40} height={20} color={theme.menu.tabListIcon} />,
  //   name: ComponentNames.Flows,
  //   component: Builder,
  // },
  {
    path: 'nlp',
    icon: <NlpIcon width={40} height={20} color={theme.menu.tabListIcon} />,
    name: ComponentNames.NLP,
    component: NLP,
  },
  // {
  //   path: 'support',
  //   icon: <SupportIcon width={40} height={20} color={theme.menu.tabListIcon} />,
  //   name: ComponentNames.Support,
  //   component: ChatContainer,
  // },
  // {
  //   path: 'broadcast',
  //   icon: <BroadcastIcon width={40} height={20} color={theme.menu.tabListIcon} />,
  //   name: ComponentNames.Broadcasts,
  //   component: Broadcasts,
  // },
  // {
  //   path: 'widget',
  //   icon: <WidgetIcon width={40} height={20} color={theme.menu.tabListIcon} />,
  //   name: ComponentNames.Widget,
  //   component: WidgetContainer,
  // },
  {
    path: 'settings',
    icon: <SettingsIcon width={40} height={20} color={theme.menu.tabListIcon} />,
    name: ComponentNames.BotSettings,
    component: Settings,
  },
]
