import { CSSProperties } from 'react'

export const feedbackSettingTableConfig: Record<string, { maxWidth: string; title: string; styles?: CSSProperties }> = {
  user: { maxWidth: '90%', title: 'User Name' },
  removeButton: { maxWidth: '10%', title: '' },
}

export const feedbackSettingTableTitles = Object.values(feedbackSettingTableConfig).map(
  ({ title, maxWidth, styles }) => ({
    title,
    maxWidth,
    styles,
  }),
)
