import { NEW_CLUSTER_QUESTIONS_RECEIVED, TopQuestionsDetailsPayloadAction, TopQuestionsContentSlice, CLEAR_CLUSTER_QUESTION } from 'tabs/topQuestions/lib/questions_content/questionsContentTypes'
import { mergeNewClusterQuestionAndExisting } from 'tabs/topQuestions/lib/questions_content/questionsContentHandlers'

const initialState: TopQuestionsContentSlice = {
  questionDetails: [],
}

export const questionsContentSlice = (
  state: TopQuestionsContentSlice = initialState,
  action: TopQuestionsDetailsPayloadAction): TopQuestionsContentSlice => {
  switch (action.type) {
    case NEW_CLUSTER_QUESTIONS_RECEIVED:
      return {
        ...state,
        questionDetails: mergeNewClusterQuestionAndExisting(state.questionDetails, action.payload),
      }
    case CLEAR_CLUSTER_QUESTION:
      return {
        ...state,
        questionDetails: [],
      }
    default:
      return state
  }

}
