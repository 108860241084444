export const abbreviationsTableConfig = {
  fullName: { maxWidth: '20%', title: 'Phrase', paddingRight: 30 },
  abbreviations: { maxWidth: '45%', title: 'Acronyms' },
  definition: { maxWidth: '30%', title: 'Definition' },
  removeButton: { maxWidth: '5%', title: '' },
}

export const abbreviationsTableTitles = Object.values(abbreviationsTableConfig).map(({ title, maxWidth }) => ({
  title,
  maxWidth,
}))

export const DELAY_BEFORE_SENDING_UPDATE_REQUEST = 2000
