import { createJsonHeaders, logoutOnRequestOrResponseJson } from '../api'

async function _apiMethod(method: string, url: string, body?: any) {
  const options: RequestInit = {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: method,
  }

  if (body) {
    options.body = JSON.stringify(body)
  }

  return fetch(url, options)
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => {
      if (response?.data) {
        return response.data
      }

      if (response?.error) {
        throw response?.error
      }
    })
}

class Api {
  public get = <T = any>(url: string): Promise<T> => {
    return _apiMethod('GET', url)
  }

  public post = <T = any>(url: string, body?: any): Promise<T> => {
    return _apiMethod('POST', url, body)
  }

  public put = <T = any>(url: string, body?: any): Promise<T> => {
    return _apiMethod('PUT', url, body)
  }

  public patch = <T = any>(url: string, body?: any): Promise<T> => {
    return _apiMethod('PATCH', url, body)
  }

  public delete = <T = any>(url: string, body?: any): Promise<T> => {
    return _apiMethod('DELETE', url, body)
  }
}

export default new Api()
