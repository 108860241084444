import { v4 as uuid } from 'uuid'
import { useCallback, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

export const useModalToggle = () => {
  const [isOpen, setOpenStatus] = useState<boolean>(false)

  const toggleModal = () => {
    setOpenStatus(status => !status)
  }

  return { isOpen, toggleModal }
}

export const useDebounceCallback = <U extends any[]>(
  callback: (...args: U) => void,
  timeout = 500,
): ((...args: U) => void) => {
  return useCallback(debounce(callback, timeout), [])
}

export const useKeyOnChange = (...args: any[]) => {
  return useMemo(() => uuid(), args)
}
