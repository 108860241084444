import { BACKEND_URL } from 'configs'
import { GenerateTopQuestionNameResponse, TopQuestionsResponse } from 'tabs/topQuestions/types/ApiResponses'
import {
  FETCH_TOP_QUESTIONS_PENDING,
  FETCH_TOP_QUESTIONS_REJECTED,
  FETCH_TOP_QUESTIONS_RESOLVED,
  GENERATE_CLUSTER_NAME_REJECTED,
  GENERATE_CLUSTER_NAME_REQ,
  GENERATE_CLUSTER_NAME_RESOLVED,
  GenerateClusterNameRejected,
  GenerateClusterNameReq,
  GenerateClusterNameResolved,
} from 'tabs/topQuestions/lib/top_questions/topQuestionsConstnats'

export const fetchTopQuestions = (botId: number, questionType: string) => {
  return async dispatch => {
    dispatch(fetchClustersRequest())
    try {
      let search = `?botId=${botId}`
      if (questionType != null) search = search + `&questionType=${questionType}`
      const url = `${BACKEND_URL}/top-questions${search}`
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      const data = await response.json() as TopQuestionsResponse
      dispatch(fetchClustersSuccess(data))
    } catch (error) {
      dispatch(fetchClustersFailure(error))
    }
  }
}


export const generateClusterName = (clusterId: string) => {
  return async dispatch => {
    dispatch(generateClusterNameRequest(clusterId))
    try {
      const response = await fetch(`${BACKEND_URL}/top-questions/generate-name`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({ topQuestionId: clusterId }),
      })
      if (response.status === 200) {
        const data = await response.json() as GenerateTopQuestionNameResponse
        dispatch(generateClusterNameSuccess(clusterId, data.generatedName))
      } else {
        const data = await response.text()
        dispatch(generateClusterNameRejected(clusterId, data))
      }
    } catch (error) {
      dispatch(generateClusterNameRejected(clusterId, error.message))
    }
  }
}

const generateClusterNameRequest = (clusterId: string): GenerateClusterNameReq => ({
  type: GENERATE_CLUSTER_NAME_REQ,
  payload: { clusterId },
})


const generateClusterNameSuccess = (clusterId: string, generatedName: string): GenerateClusterNameResolved => ({
  type: GENERATE_CLUSTER_NAME_RESOLVED,
  payload: { clusterId, generatedName: generatedName },
})


const generateClusterNameRejected = (clusterId: string, error: string): GenerateClusterNameRejected => ({
  type: GENERATE_CLUSTER_NAME_REJECTED,
  payload: { clusterId, error },
})

const fetchClustersRequest = () => {
  return {
    type: FETCH_TOP_QUESTIONS_PENDING,
  }
}

const fetchClustersSuccess = (data: TopQuestionsResponse) => {
  return {
    type: FETCH_TOP_QUESTIONS_RESOLVED,
    payload: data,
  }
}

const fetchClustersFailure = error => {
  return {
    type: FETCH_TOP_QUESTIONS_REJECTED,
    payload: error,
  }
}
