import { PayloadAction, PendingRequest, RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import { UserAutocomplete } from 'tabs/nlp/components/unansweredQuestions/assignUser/model/internal-types'

export type AssignUserSliceState = {
  confirmSelectionRequest: {
    selectedUser: UserAutocomplete
    sendNotification: boolean
  }
  autocompleteInput: string | null
  fetchAutocompletUsersRequest: PendingRequest<UserAutocomplete[]>
}

export const AUTOCOMPLETE_INPUT_CHANGED = 'assignUser/START_CLUSTERING_PENDING'
export const FETCH_USERS_AUTOCOMPLETE_PENDING = 'assignUser/FETCH_USERS_AUTOCOMPLETE_PENDING'
export const FETCH_USERS_AUTOCOMPLETE_RESOLVED = 'assignUser/FETCH_USERS_AUTOCOMPLETE_RESOLVED'
export const FETCH_USERS_AUTOCOMPLETE_REJECTED = 'assignUser/FETCH_USERS_AUTOCOMPLETE_REJECTED'
export const UPDATE_SELECTED_USER_CHANGE = 'assignedUser/UPDATE_SELECTED_USER_CHANGE'
export const UPDATE_SEND_NOTIFICATION_CHANGE = 'assignedUser/UPDATE_SEND_NOTIFICATION_CHANGE'

export type AutocompleteInputChanged = PayloadAction<typeof AUTOCOMPLETE_INPUT_CHANGED, string>
export type FetchUsersAutocompletePending = PayloadAction<typeof FETCH_USERS_AUTOCOMPLETE_PENDING, undefined>
export type FetchUsersAutocompleteResolved = PayloadAction<typeof FETCH_USERS_AUTOCOMPLETE_RESOLVED, UserAutocomplete[]>
export type FetchUsersAutocompleteRejected = PayloadAction<typeof FETCH_USERS_AUTOCOMPLETE_REJECTED, undefined>
export type UpdateSelectedUserIdChange = PayloadAction<typeof UPDATE_SELECTED_USER_CHANGE, UserAutocomplete>
export type UpdateSendNotificationChange = PayloadAction<typeof UPDATE_SEND_NOTIFICATION_CHANGE, boolean>

export type ClusteringConfigurationAction =
  | FetchUsersAutocompletePending
  | FetchUsersAutocompleteResolved
  | FetchUsersAutocompleteRejected
  | AutocompleteInputChanged
  | UpdateSelectedUserIdChange
  | UpdateSendNotificationChange

const createInitialState = (): AssignUserSliceState => {
  return {
    confirmSelectionRequest: {
      selectedUser: undefined,
      sendNotification: false,
    },
    autocompleteInput: null,
    fetchAutocompletUsersRequest: { status: RequestStatus.NONE },
  }
}

export const assignUserSlice = (
  state: AssignUserSliceState = createInitialState(),
  action: ClusteringConfigurationAction,
): AssignUserSliceState => {
  switch (action.type) {
    case FETCH_USERS_AUTOCOMPLETE_PENDING:
      return { ...state, fetchAutocompletUsersRequest: { status: RequestStatus.PENDING } }
    case FETCH_USERS_AUTOCOMPLETE_RESOLVED:
      return { ...state, fetchAutocompletUsersRequest: { status: RequestStatus.RESOLVED, data: action.payload } }
    case FETCH_USERS_AUTOCOMPLETE_REJECTED:
      return { ...state, fetchAutocompletUsersRequest: { status: RequestStatus.REJECTED } }
    case AUTOCOMPLETE_INPUT_CHANGED:
      return { ...state, autocompleteInput: action.payload }
    case UPDATE_SELECTED_USER_CHANGE:
      return { ...state, confirmSelectionRequest: { ...state.confirmSelectionRequest, selectedUser: action.payload } }
    case UPDATE_SEND_NOTIFICATION_CHANGE:
      return {
        ...state,
        confirmSelectionRequest: { ...state.confirmSelectionRequest, sendNotification: action.payload },
      }
    default:
      return state
  }
}

export const selectFetchAutocompleteUsers = (store: any): UserAutocomplete[] => {
  const clusteringConfigState: AssignUserSliceState = store.assignUserSlice
  const request = clusteringConfigState.fetchAutocompletUsersRequest
  if (request.status === RequestStatus.RESOLVED) {
    return request.data
  }
  return []
}

export const selectClusteringRequestStatus = (store: any) => {
  const clusteringConfigState: AssignUserSliceState = store.assignUserSlice
  return clusteringConfigState.autocompleteInput
}

export const selectSendNotificationChecked = (store: any) => {
  const clusteringConfigState: AssignUserSliceState = store.assignUserSlice
  return clusteringConfigState.confirmSelectionRequest.sendNotification
}

export const selectSelectedUser = (store: any) => {
  const clusteringConfigState: AssignUserSliceState = store.assignUserSlice
  return clusteringConfigState.confirmSelectionRequest.selectedUser
}

export const updateAutocompleteInput = (payload: string): AutocompleteInputChanged => ({
  type: AUTOCOMPLETE_INPUT_CHANGED,
  payload,
})

export const usersAutocompletePending = (): FetchUsersAutocompletePending => ({
  type: FETCH_USERS_AUTOCOMPLETE_PENDING,
  payload: undefined,
})

export const usersAutocompleteRejected = (): FetchUsersAutocompleteRejected => ({
  type: FETCH_USERS_AUTOCOMPLETE_REJECTED,
  payload: undefined,
})

export const usersAutocompleteResolved = (payload: UserAutocomplete[]): FetchUsersAutocompleteResolved => ({
  type: FETCH_USERS_AUTOCOMPLETE_RESOLVED,
  payload,
})

export const updateSelectedUserIdChange = (payload: UserAutocomplete): UpdateSelectedUserIdChange => ({
  type: UPDATE_SELECTED_USER_CHANGE,
  payload,
})
export const updateSendNotificationChange = (payload: boolean): UpdateSendNotificationChange => ({
  type: UPDATE_SEND_NOTIFICATION_CHANGE,
  payload,
})
