import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  validateResetPasswordToken,
  changeAdminPasswordWithResetToken,
} from '../api/admin'
import { withStyles } from '@material-ui/core/styles'
import base64 from 'base-64'
import queryString from 'query-string'
import LoaderSmall from '../../../uiKit/loaders/loaderSmall'
import UnavailableSmall from './UnavailableSmall'
import LoaderOverlay from '../../../uiKit/loaders/loaderOverlay'
import FormMessage from './FormMessage'
import PasswordInput from './PasswordInput'
import Button from './Button'
import { isStringEmpty } from '../../../helpers/isStringEmpty'

const styles = () => ({
  resetPasswordFormWrapper: {
    position: 'relative',
  },
  '@global': {
    '@media (max-width: 575px)': {
      demoForm: {
        textAlign: 'center',
        maxWidth: 'unset',
        flexBasis: '90%',
        margin: 'auto',
      },
    },
  },
})

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props)

    const urlParams = queryString.parse(this.props.location.search)
    let resetPasswordToken = urlParams.token
    let resetTokenValidationFinished = false
    if (!isStringEmpty(resetPasswordToken)) {
      resetTokenValidationFinished = true
      this.props.history.replace(this.props.history.location.pathname)
      sessionStorage.setItem('resetPasswordToken', resetPasswordToken)
    } else {
      resetPasswordToken = sessionStorage.getItem('resetPasswordToken')
      if (isStringEmpty(resetPasswordToken)) {
        resetTokenValidationFinished = true
      }
    }

    this.state = {
      password: '',
      passwordConfirm: '',
      passwordError: false,
      passwordConfirmError: false,
      error: false,
      errorMessage: '',
      hidePassword: true,
      resetPasswordToken,
      resetTokenValidationFinished,
      resetTokenIsValid: false,
      failToValidateResetToken: false,
      processingPasswordChange: false,
      passwordChangedSuccessfully: false,
    }
  }

  componentDidMount() {
    if (this.state.resetTokenValidationFinished) {
      return
    }

    validateResetPasswordToken(this.state.resetPasswordToken).then(response => {
      if (response.ok) {
        response.json().then(response => {
          this.setState({
            resetTokenValidationFinished: true,
            resetTokenIsValid: response.valid === true,
          })
        })
      } else {
        this.setState({
          resetTokenValidationFinished: true,
          failToValidateResetToken: true,
        })
        // TODO: print error that was not able to validate resetToken.
        // Probably some internal error, maybe internet loss.
      }
    })
  }

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value,
      passwordError: e.target.value.length < 8,
      passwordConfirmError:
        this.state.passwordConfirm.length > 0 &&
        e.target.value !== this.state.passwordConfirm,
    })
  }
  handlePasswordConfirmChange = e => {
    this.setState({
      passwordConfirm: e.target.value,
      passwordConfirmError: this.state.password !== e.target.value,
    })
  }

  handleRequestPasswordReset = () => {
    if (!this.state.passwordConfirm || !this.state.password) {
      if (!this.state.passwordConfirm)
        this.setState({
          passwordConfirmError: true,
        })
      if (!this.state.password)
        this.setState({
          passwordError: true,
        })
      return
    }
    if (
      this.state.passwordError ||
      this.state.passwordConfirmError ||
      isStringEmpty(this.state.password)
    ) {
      return
    }
  }

  handleHidePasswordToggle = e => {
    e.preventDefault()
    this.setState({
      hidePassword: !this.state.hidePassword,
    })
  }

  handleRequestPasswordReset = () => {
    if (!this.state.passwordConfirm || !this.state.password) {
      if (!this.state.passwordConfirm)
        this.setState({
          passwordConfirmError: true,
        })
      if (!this.state.password)
        this.setState({
          passwordError: true,
        })
      return
    }
    if (
      this.state.passwordError ||
      this.state.passwordConfirmError ||
      isStringEmpty(this.state.password)
    ) {
      return
    }

    this.setState({
      processingPasswordChange: true,
    })

    changeAdminPasswordWithResetToken(
      this.state.resetPasswordToken,
      base64.encode(this.state.password),
    ).then(response => {
      if (response.ok) {
        this.setState({
          passwordChangedSuccessfully: true,
          processingPasswordChange: false,
        })
      } else {
        if (response.status === 400) {
          response.json().then(text => {
            this.setState({ errorMessage: text.message })
          })
        }
        this.setState({
          error: true,
          processingPasswordChange: false,
        })
      }
    })
  }

  resetPasswordFormContent = classes => {
    const {
      resetTokenValidationFinished,
      failToValidateResetToken,
      resetTokenIsValid,
      processingPasswordChange,
      passwordChangedSuccessfully,
    } = this.state

    // TODO: add some error message when connection to server lost
    if (!resetTokenValidationFinished || failToValidateResetToken) {
      return <LoaderSmall showLoader={true} />
    }
    if (!resetTokenIsValid) {
      return (
        <UnavailableSmall
          linkExpired={false}
          title="Sorry, this link is not available anymore"
          subtitle="The reset password link expired or it has been already used."
          buttonTitle="Go to login page"
          buttonUrl="/login"
        />
      )
    }

    if (passwordChangedSuccessfully) {
      return (
        <FormMessage
          title="Success! Your password has been changed."
          subtitle="You can now return to the sign in page and try your new password."
          buttonTitle="Go to sign in"
          buttonUrl="/login"
        />
      )
    }

    return (
      <div className={classes.resetPasswordFormWrapper}>
        {processingPasswordChange && <LoaderOverlay />}
        <h4 className={classes.formTitle}>Reset your password</h4>
        <div className={classes.demoDiv}>
          <PasswordInput
            id="password"
            name="password"
            placeholder="New password"
            label="Type your new password"
            errorLabel="Password is too short"
            hasError={this.state.passwordError}
            onChange={this.handlePasswordChange}
            pressEnterCallback={this.handleRequestPasswordReset}
          />

          <PasswordInput
            id="passwordConfirm"
            name="passwordConfirm"
            placeholder="New password confirm"
            label="Confirm new password"
            errorLabel="Passwords don't match"
            hasError={this.state.passwordConfirmError}
            onChange={this.handlePasswordConfirmChange}
            pressEnterCallback={this.handleRequestPasswordReset}
          />

          {this.state.error ? (
            <div className="error-message">
              {!isStringEmpty(this.state.errorMessage)
                ? this.state.errorMessage
                : 'Something went wrong, try one more time'}
            </div>
          ) : (
            <div />
          )}
        </div>
        <Button
          type="submit"
          title="Save"
          onClick={this.handleRequestPasswordReset}
        />
      </div>
    )
  }

  render() {
    const classes = { ...this.props.classes, ...this.props.styles }

    return (
      <main className={classes.main}>
        <div className={`${classes.demoForm} animated zoomIn faster`}>
          {this.resetPasswordFormContent(classes)}
        </div>
        <br />
        <span className={classes.bottomText}>
          Remembered your password? <Link to={'/login'}>Sign in</Link>
        </span>
      </main>
    )
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(ResetPasswordForm),
)
