import { PayloadAction } from 'tabs/topQuestions/types/GenericTypes'
import { DateObject } from 'tabs/topQuestions/utils/DateUtils'

export type ColorRgb = [number, number, number]


export type ClusteringExecutionData = {
  clusteringFinishedAt: number
  executionClusters: ExecutionCluster[]
}

export type ExecutionCluster = {
  clusterUniqueId: string
  name?: string
  questionsCount: number
  colorRgb?: ColorRgb
}

export const LINE_CHART_DATA_REQ_PENDING = 'LINE_CHART_DATA_REQ_PENDING'
export const LINE_CHART_DATA_REQ_RESOLVED = 'LINE_CHART_DATA_REQ_RESOLVED'
export const LINE_CHART_DATA_REQ_REJECTED = 'LINE_CHART_DATA_REQ_REJECTED'
export const LINE_CHART_FILTER_START_DATE_CHANGE = 'LINE_CHART_FILTER_START_DATE_CHANGE'
export const LINE_CHART_FILTER_END_DATE_CHANGE = 'LINE_CHART_FILTER_END_DATE_CHANGE'

export type LineChartDataReqPending = PayloadAction<typeof LINE_CHART_DATA_REQ_PENDING, undefined>
export type LineChartDataReqResolved = PayloadAction<typeof LINE_CHART_DATA_REQ_RESOLVED, ClusteringExecutionData[]>
export type LineChartDataReqRejected = PayloadAction<typeof LINE_CHART_DATA_REQ_REJECTED, undefined>

export type LineChartFilterStartDateChange = PayloadAction<typeof LINE_CHART_FILTER_START_DATE_CHANGE, DateObject>
export type LineChartFilterEndDateChange = PayloadAction<typeof LINE_CHART_FILTER_END_DATE_CHANGE, DateObject>

export type LineChartDataReq = LineChartDataReqPending
  | LineChartDataReqResolved
  | LineChartDataReqRejected
  | LineChartFilterStartDateChange
  | LineChartFilterEndDateChange

export type QuestionClustersLineChartActions = LineChartDataReq

