import { useRef } from 'react'

type DebouncedFunction<U extends any[]> = (...args: U) => void
export const useDebounceCallback = <U extends any[]>(fn: DebouncedFunction<U>,
                                                     debounceTime = 500): DebouncedFunction<U> => {

  const throttleTimeoutRef = useRef<NodeJS.Timeout | undefined>()

  function scheduleNextExecution(args: U, time: number) {
    throttleTimeoutRef.current = setTimeout(() => {
      fn(...args)
      throttleTimeoutRef.current = undefined
    }, time)
  }

  return (...args) => {
    const throttleTimeout = throttleTimeoutRef.current
    if (throttleTimeout == null) {
      scheduleNextExecution(args, debounceTime)
    } else {
      clearTimeout(throttleTimeout)
      scheduleNextExecution(args, debounceTime)
    }
  }

}

