import React from 'react'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { BACKEND_URL } from '../../../configs'
import { authenticate } from '../actions/security'
import Unavailable from '../../../containers/Unavailable/Unavailable'
import LoaderSmall from '../../../uiKit/loaders/loaderSmall'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = () => ({
  loader: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

class EmailConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmed: false,
      invalidLink: false,
    }
  }

  componentDidMount() {
    this.getConfirmationStatus()
  }

  getConfirmationStatus() {
    const token = this.parseQueryParams(this.props.location.search).get('t') // Get token from query parameter 't'
    fetch(`${BACKEND_URL}/signUp/confirmEmail/${token}`, {
      method: 'POST',
    }).then(response => {
      if (response.ok) {
        response.json().then(body => {
          if (body.success) {
            // On success, save the token and authenticate a user
            authenticate()
            this.setState({
              confirmed: true,
            })
          } else {
            // On failure
            this.setState({
              invalidLink: true,
            })
          }
        })
      }
    })
  }

  parseQueryParams(location) {
    return new Map(
      location
        .slice(1)
        .split('&')
        .map(param => param.split('=')),
    )
  }

  render() {
    const { classes } = this.props
    const { confirmed, invalidLink } = this.state
    if (confirmed) {
      // If confirmed, then redirect to home page
      return <Redirect to="/" />
    } else if (invalidLink) {
      // Otherwise, show that link is expired
      return <Unavailable linkExpired />
    } else {
      // Show loader while the data is coming from backend
      return (
        <div className={classes.loader}>
          <LoaderSmall showLoader />
        </div>
      )
    }
  }
}

EmailConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(
  withStyles(styles, { withTheme: true })(EmailConfirmation),
)
