import { IMessage } from '@stomp/stompjs'
import { ClusterDetailsResponse, TopQuestion } from 'tabs/topQuestions/types/ApiResponses'
import { ClusterDetails, ClusterDetailsState, GenerateClusterNameResponsePayload } from 'tabs/topQuestions/types/InternalTypes'
import { FetchTopQuestionsResolved, TopQuestionsSlice, UpdateClusterDisplayedStatus, UpdateClusterHovered } from 'tabs/topQuestions/lib/top_questions/topQuestionsConstnats'
import { toRgba } from 'tabs/topQuestions/utils/ColorUtils'
import { ColorRgb } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartTypes'
import { comparingFields } from 'tabs/topQuestions/utils/ArrayUtils'


export function handleNewClusterInfoReceived(action: FetchTopQuestionsResolved) {
  const response = action.payload
  const { topQuestions, colors } = response

  const clusters = topQuestions
    .sort(comparingFields('questionsCount', 'desc'))
    .map((q, idx) => convertCluster(q, colors[idx]))

  const result: Record<string, ClusterDetails> = {}
  for (const cluster of clusters) {
    result[cluster.clusterId] = cluster
  }
  return result
}

export function handleClusterHoveredChanged(clustersState: ClusterDetailsState, { payload }: UpdateClusterHovered) {
  const { clusterId, hovered } = payload
  const clustersCopy = { ...clustersState }
  const allClusters = Object.values(clustersCopy)
  for (let i = 0; i < allClusters.length; i++) {
    const currentCluster = { ...allClusters[i] }
    const currentClusterId = currentCluster.clusterId
    if (hovered) {
      currentCluster.hovered = currentCluster.clusterId === clusterId
    } else {
      currentCluster.hovered = false
    }
    clustersCopy[currentClusterId] = currentCluster
  }
  return clustersCopy
}

export function clusterDetailsResponseConverter(message: IMessage): ClusterDetailsResponse {
  return JSON.parse(message.body)
}

export function updateClusterName(clusters: ClusterDetailsState, { clusterId, generatedName }: GenerateClusterNameResponsePayload) {
  const existingCluster = clusters[clusterId]
  if (existingCluster != undefined) {
    const clustersCopy = { ...clusters }
    clustersCopy[clusterId] = { ...existingCluster, clusterName: generatedName }
    return clustersCopy
  } else {
    console.warn('Unexpected result, cluster should be present')
    return clusters
  }
}

export function handleDisplayedClusterChange(action: UpdateClusterDisplayedStatus,
                                             state: TopQuestionsSlice) {
  const { payload } = action
  const { clusterId, status } = payload
  const displayedClustersCopy = { ...state.clusters }
  const cluster = { ...displayedClustersCopy[clusterId] }
  cluster.isDisplayed = status
  displayedClustersCopy[clusterId] = cluster
  return displayedClustersCopy
}

function convertCluster(newCluster: TopQuestion, color: ColorRgb): ClusterDetails {
  return {
    ...newCluster,
    clusterColor: toRgba(...color, 0.8),
    clusterId: newCluster.id,
    clusterUniqueId: newCluster.clusterUniqueId,
    clusterName: newCluster.questionName,
    totalQuestionCount: newCluster.questionsCount,
    isDisplayed: true,
  }
}
