import styled from 'styled-components'
import * as Styles from '../../../styles'

const RemoveButton = styled(Styles.RemoveButton)`
  padding: 0 4px;
  border-radius: 5px;
  background: linear-gradient(285.2deg, rgb(97 158 255) -35.9%, rgb(54 93 244 / 65%) 61.17%); ;
`

const ConfirmDeleteButton = styled.div`
  position: absolute;
  padding: 2px;
  top: -25px;
  left: -19px;
  max-width: 60px;
  width: max-content;
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 239);
  font-size: 12px;
  color: red;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  background-color: white;

  &:hover {
    background-color: rgb(210 228 255);
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 22px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: rgb(235, 235, 239) transparent transparent transparent;
  }
`

export { RemoveButton, ConfirmDeleteButton }
