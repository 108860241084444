import { PayloadAction } from 'tabs/topQuestions/types/GenericTypes'


export type TopQuestionsFilter = {
  query?: string,
  type?: 'faq' | 'unknown' | 'dax'
}

export type TopQuestionsSearchSliceState = {
  filter: TopQuestionsFilter
}
export const UPDATE_TOP_QUESTIONS_SEARCH = 'topQuestionsSearch/UPDATE_TOP_QUESTIONS_SEARCH'

export type UpdateTopQuestionsSearch = PayloadAction<typeof UPDATE_TOP_QUESTIONS_SEARCH, Partial<TopQuestionsFilter>>

export type TopQuestionsFilterActions = UpdateTopQuestionsSearch
