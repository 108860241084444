import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { UnknownQuestionType } from 'tabs/nlp/components/unansweredQuestions/types'
import { useDispatch, useSelector } from 'react-redux'
import { selectQuestionTypeFilter } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchSelectors'
import { unansweredQuestionTypeLabels } from 'tabs/nlp/components/unansweredQuestions/configs'
import { updateTopQuestionsSearch } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchActions'
import { clearTopQuestionsData } from 'tabs/topQuestions/lib/questions_scatter_chart/questionScatterChartActions'

type Option = { value: UnknownQuestionType | '', label: string }
export const TopQuestionsTypeSelect: React.FC = () => {

  const dispatch = useDispatch()
  const selectedQuestionType = useSelector(selectQuestionTypeFilter)

  const unansweredQuestionTypeAssignOptions: Option[] = Object.entries(unansweredQuestionTypeLabels)
    .map(([value, label]) => ({ value, label })) as Option[]
  unansweredQuestionTypeAssignOptions.unshift({ value: '', label: '' })


  const onSelectValueChange = (newValue: UnknownQuestionType | '') => {
    if (newValue == '') {
      dispatch(updateTopQuestionsSearch({ type: null }))
    } else {
      dispatch(updateTopQuestionsSearch({ type: newValue }))
    }
    dispatch(clearTopQuestionsData())
  }

  return <FormControl classes={{ 'fullWidth': 'topQuestionFilterFormWrapper', root: 'topQuestionFilterFormWrapper' }}>
    <InputLabel id="demo-simple-select-filled-label">Select question type</InputLabel>
    <Select id="demo-simple-select-filled"
            value={selectedQuestionType}
            onChange={(event) => onSelectValueChange(event.target.value as UnknownQuestionType)}>
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      <MenuItem value={UnknownQuestionType.unknown}>Unknown</MenuItem>
      <MenuItem value={UnknownQuestionType.faq}>FAQ</MenuItem>
      <MenuItem value={UnknownQuestionType.dax}>DAX</MenuItem>
    </Select>
  </FormControl>
}
