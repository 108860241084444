import React from 'react'
import * as ModalStyles from '../../../ModalWindow/modal.styles'
import { RowRadioButtonsGroup } from '../RadioGroup'

interface EnablingSectionProps {
  radioGroupChangeHandler: (value: string) => void
  mandatoryRadioGroupChangeHandler: (value: string) => void
  selectedOption: string
  isMandatory: boolean
  title: string
}

export const EnablingSection: React.FC<EnablingSectionProps> = ({
  radioGroupChangeHandler,
  selectedOption,
  isMandatory,
  mandatoryRadioGroupChangeHandler,
  title,
}) => {
  return (
    <ModalStyles.ModalContainer>
      <div>
        <ModalStyles.SubHeader>{title}</ModalStyles.SubHeader>
      </div>
      <RowRadioButtonsGroup
        id="all-users"
        options={['Enable', 'Disable']}
        selectedOption={selectedOption}
        onChangeHandler={radioGroupChangeHandler}
      />
      <RowRadioButtonsGroup
        id="all-users"
        options={['Required', 'Non-required']}
        selectedOption={isMandatory ? 'Required' : 'Non-required'}
        onChangeHandler={mandatoryRadioGroupChangeHandler}
        disabled={selectedOption !== 'Enable'}
      />
    </ModalStyles.ModalContainer>
  )
}
