import { ClusterQuestionContent } from 'tabs/topQuestions/types/InternalTypes'
import { PayloadAction } from 'tabs/topQuestions/types/GenericTypes'
import { ClusterDetailsResponse } from 'tabs/topQuestions/types/ApiResponses'

export type TopQuestionsContentSlice = {
  questionDetails: Partial<ClusterQuestionContent>[]
}

export const CLEAR_CLUSTER_QUESTION = 'topQuestions/CLEAR_CLUSTER_QUESTION'
export const NEW_CLUSTER_QUESTIONS_RECEIVED = 'topQuestions/NEW_CLUSTER_QUESTIONS_RECEIVED'

export type NewClusterQuestionsReceived = PayloadAction<typeof NEW_CLUSTER_QUESTIONS_RECEIVED, ClusterDetailsResponse>
export type ClearClusterQuestion = PayloadAction<typeof CLEAR_CLUSTER_QUESTION, undefined>

export type TopQuestionsDetailsPayloadAction = NewClusterQuestionsReceived | ClearClusterQuestion
