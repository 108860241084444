const isLocalhost = window.location.hostname === 'localhost'

export const SERVER_URL = 'https://simon-staging-development.biz'
export const SERVER_PREFIX = '/simon-crm'

// process.env.REACT_APP_SERVER_URL ||
// (isLocalhost ? 'http://localhost:8080' : window.location.protocol + '//' + window.location.hostname)

export const WIDGET_BASE_URL = isLocalhost ? 'http://localhost:3030' : SERVER_URL + '/widget'

// Url of backend on a server (server url + nginx prefix for requests to tomcat)
export const BACKEND_URL = SERVER_URL + SERVER_PREFIX + '/api'

export const BASE_URL = BACKEND_URL + '/private'

export const HOME_PATH = process.env.REACT_APP_HOME_PATH || '/admin'

export const USE_MESSAGE_KEYS = process.env.REACT_APP_USE_MESSAGE_KEYS !== '0'
