import React, { FC, useState } from 'react'
import * as Styles from '../../styles'
import { DoneIcon } from 'uiKit/icons/DoneIcon'

export const ExampleTemplateContainer: FC = () => {
  const [isFileDownloaded, setIsFileDownloaded] = useState(false)

  const downloadExampleTemplate = () => {
    const link = document.createElement('a')
    link.href = process.env.PUBLIC_URL + '/abbreviation_example_template.xlsx'
    link.download = 'abbreviation_example_template.xlsx'
    link.click()

    link.remove()
    setIsFileDownloaded(true)
  }

  return (
    <>
      {isFileDownloaded ? (
        <Styles.FileDownloadSuccessfully>
          <DoneIcon width={30} height={27} />
          <Styles.SubHeader>
            Example template downloaded successfully. Please fill it with desired abbreviations and upload it.
          </Styles.SubHeader>
        </Styles.FileDownloadSuccessfully>
      ) : (
        <Styles.DownloadButton onClick={downloadExampleTemplate}>Download example template</Styles.DownloadButton>
      )}
    </>
  )
}
