import React, { FC, useEffect, useRef, useState } from 'react'
import * as Styles from './styles'
import { DynamicTextInputProps } from './type'

export const DynamicTextAndInput: FC<DynamicTextInputProps> = ({
  inputChangeAction,
  value,
  textStyles,
  wrapperStyles,
  inputStyles,
  onClickAction,
  onBlurAction,
  isFocused,
  placeholder,
  inputValidation,
}) => {
  const [isEditMode, setIsEditMode] = useState(isFocused)
  const [inputText, setInputText] = useState(value)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isEditMode && inputRef.current) inputRef.current.focus()
  }, [isEditMode])

  const handleClickOnText = event => {
    setIsEditMode(true)
    onClickAction && onClickAction(event)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value as string)
    inputChangeAction && inputChangeAction(event)
  }

  const validateInput = () => {
    return inputValidation ? inputValidation(inputText) : true
  }

  const handleBlur = event => {
    if (validateInput()) {
      setIsEditMode(false)
      onBlurAction && onBlurAction(event, inputText)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleBlur(event)
    }
  }

  return isEditMode ? (
    <Styles.DynamicInputContainer>
      <Styles.DynamicInputWrapper style={wrapperStyles}>
        <Styles.DynamicInput
          ref={inputRef}
          placeholder={placeholder}
          onChange={handleInputChange}
          value={inputText}
          onBlur={handleBlur}
          style={inputStyles}
          onKeyDown={handleKeyDown}
        />
      </Styles.DynamicInputWrapper>
    </Styles.DynamicInputContainer>
  ) : (
    <Styles.DynamicText onClick={handleClickOnText} style={textStyles}>
      {inputText}
    </Styles.DynamicText>
  )
}
