import React, { FC, useCallback } from 'react'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import { AbbreviationTableRowProps } from './types'
import { abbreviationTableRowConfigs, abbreviationsTableRemoveConfig } from './configs'
import { FullNameTableRowCell } from './cells/fullName'
import { AbbreviationsTableRowCell } from './cells/abbreviation'
import { TableRowRemoveElement } from '../../tableRowRemove'
import { useAbbreviations } from '../context'
import { DefinitionTableRowCell } from './cells/definition'

export const AbbreviationTableRow: FC<AbbreviationTableRowProps> = ({ abbreviationsRecord, index }) => {
  const { deleteAbbreviation } = useAbbreviations()

  const onDeleteButtonPressed = useCallback(() => {
    deleteAbbreviation(abbreviationsRecord.id)
  }, [abbreviationsRecord.id])

  return (
    <TableRow key={index} index={index} style={{}}>
      <TableCell style={abbreviationTableRowConfigs.fullName}>
        <FullNameTableRowCell abbreviationsRecord={abbreviationsRecord} />
      </TableCell>
      <TableCell style={abbreviationTableRowConfigs.abbreviations}>
        <AbbreviationsTableRowCell abbreviationsRecord={abbreviationsRecord} />
      </TableCell>
      <TableCell style={abbreviationTableRowConfigs.definition}>
        <DefinitionTableRowCell abbreviationsRecord={abbreviationsRecord} />
      </TableCell>
      <TableCell style={abbreviationTableRowConfigs.removeButton}>
        <TableRowRemoveElement
          removeAction={onDeleteButtonPressed}
          title={abbreviationsTableRemoveConfig.title}
          content={abbreviationsTableRemoveConfig.content}
        />
      </TableCell>
    </TableRow>
  )
}
