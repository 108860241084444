import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { RadioButtonsGroupProps } from './types'

export const RowRadioButtonsGroup: React.FC<RadioButtonsGroupProps> = ({
  options,
  selectedOption,
  row = true,
  id,
  label,
  onChangeHandler,
  disabled,
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChangeHandler(value)
  }

  return (
    <FormControl disabled={disabled}>
      {label && <FormLabel id={`${id}-radio-buttons-group-label`}>{label}</FormLabel>}
      <RadioGroup
        row={row}
        aria-labelledby={`${id}-radio-buttons-group-label`}
        name={`${id}-buttons-group-label`}
        defaultValue={selectedOption.toLowerCase()}
        onChange={onChange}>
        {options.map(option => (
          <FormControlLabel key={option} value={option.toLowerCase()} control={<Radio />} label={option} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
