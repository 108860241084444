/* eslint-disable */
import { FC, useMemo, useReducer, useState } from 'react'
import ModalTemplate from 'uiKit/ModalTemplate'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import styles from './styles.module.scss'
import { useModalToggle } from 'tabs/nlp/components/unansweredQuestions/hooks'
import * as LocalStyles from './styles'
import * as ModalStyles from '../../ModalWindow/modal.styles'
import { SettingIcon } from 'uiKit/icons/SettingIcon'
import { RowRadioButtonsGroup } from './RadioGroup'
import { useDispatch, useSelector } from 'react-redux'
import { getBotSetting, getUsersOfGroup } from './state/BotSettingsReduser'
import { botSettingUpdate } from './state/BotSettingActions'
import { FeedbackUsersGroup } from './AddUserSection/FeedbackUsersGroup'
import { appBotService } from '../api/bot'
import { EnablingSection } from './EnablingSection'

export const SettingsFeedbackModal: FC = () => {
  const { toggleModal, isOpen } = useModalToggle()
  const bot = useSelector(getBotSetting)
  const dispatch = useDispatch()

  const getAllUsersFeedbackState = (feedbackStatus: boolean) => {
    return feedbackStatus ? 'Enable' : 'Disable'
  }

  const radioGroupChangeHandler = (value: string) => {
    const shouldFeedbackBeAsked = value === 'enable'
    appBotService
      .updateFeedback({ shouldFeedbackBeAsked })
      .then(() => dispatch(botSettingUpdate({ feedback: { shouldFeedbackBeAsked } })))
  }

  const mandatoryRadioGroupChangeHandler = (value: string) => {
    const isMandatory = value === 'required'
    appBotService.updateFeedback({ isMandatory }).then(() => dispatch(botSettingUpdate({ feedback: { isMandatory } })))
  }

  return (
    <>
      <LocalStyles.IconContainer onClick={toggleModal}>
        <SettingIcon />
      </LocalStyles.IconContainer>
      <ModalTemplate
        containerClasses={styles.modal}
        open={isOpen}
        title="User Feedback Settings"
        titleIcon={
          <LocalStyles.TitleIconWrapper>
            <SparklesIcon />
          </LocalStyles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <ModalStyles.ModalContainer>
          <EnablingSection
            title="All Users"
            radioGroupChangeHandler={radioGroupChangeHandler}
            selectedOption={getAllUsersFeedbackState(bot?.feedback?.shouldFeedbackBeAsked)}
            isMandatory={bot?.feedback?.isMandatory}
            mandatoryRadioGroupChangeHandler={mandatoryRadioGroupChangeHandler}
          />

          <ModalStyles.Divider />

          {Object.entries(bot?.feedback?.groups || {}).map(([groupName, { shouldFeedbackBeAsked, isMandatory }]) => (
            <FeedbackUsersGroup
              groupName={groupName}
              shouldFeedbackBeAsked={shouldFeedbackBeAsked}
              isMandatory={isMandatory}
            />
          ))}
        </ModalStyles.ModalContainer>
      </ModalTemplate>
    </>
  )
}
