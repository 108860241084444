import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Heading from 'uiKit/texts/Heading'
import * as Styles from './styles'
import { TrainSearchInput } from '../searchField'
import Table from '../../../../uiKit/table/Table'
import { userFeedbackTableTitles } from './configs'
import { UserFeedbackTableRow } from './tableRow'
import { useUserFeedback } from './context'
import { mergeTitlesWithSortingMap } from 'uiKit/table/config'
import { paginationConfigs } from 'tabs/nlp/constants/paginationSize'
import Pagination from 'uiKit/table/pagination/Pagination'
import { SettingsFeedbackModal } from './settings'
import { useDispatch } from 'react-redux'
import { retrieveBotSettings, retrieveUsersGroup } from './settings/state/BotSettingApi'

const UserFeedbackTab = () => {
  const {
    updateSearchValue,
    userFeedbackRecords,
    sortHeadersMap,
    dropSortParams,
    isSortingActive,
    areRecordsInitialized,
    page,
    count,
    setPage,
  } = useUserFeedback()
  const dispatch = useDispatch()

  const titlesWithSortingMap = () => {
    return mergeTitlesWithSortingMap(sortHeadersMap, userFeedbackTableTitles)
  }

  const onPaginationChange = (_, page) => {
    setPage(page)
  }

  useEffect(() => {
    dispatch(retrieveBotSettings())
    dispatch(retrieveUsersGroup())
  }, [])

  return (
    <Styles.Container>
      <Styles.Header>
        <div>
          <Heading>User Feedback</Heading>
          <Styles.SubHeader>See users feedback about bot answer to questions.</Styles.SubHeader>
        </div>
        <SettingsFeedbackModal />
      </Styles.Header>

      <Styles.FiltersContainer>
        <TrainSearchInput placeholder="Search User Question" updateSearchValue={updateSearchValue} />
        <Styles.Button onClick={dropSortParams} disabled={!isSortingActive}>
          Reset Sort Parameters
        </Styles.Button>
      </Styles.FiltersContainer>

      <Table titles={titlesWithSortingMap()}>
        {userFeedbackRecords.map((feedback, index) => (
          <UserFeedbackTableRow key={feedback.id} feedbackRecord={feedback} index={index} />
        ))}
      </Table>

      {areRecordsInitialized && (
        <Pagination page={page} rowsPerPage={paginationConfigs.limit} count={count} onChangePage={onPaginationChange} />
      )}
    </Styles.Container>
  )
}

export default withRouter(UserFeedbackTab)
