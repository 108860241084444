import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StompSubscription } from '@stomp/stompjs'
import { selectActiveBot } from 'tabs/home/reducers/selectors'
import { CompatClient } from '@stomp/stompjs/src/compatibility/compat-client'
import { SharedWebsocketContext } from 'contexts/webSockets'
import { selectExceptionally } from 'tabs/topQuestions/utils/ObjectUtils'
import { questionDetailsResponseConverter } from 'tabs/topQuestions/conversion/ApiConverters'
import { useThrottledBuffer } from 'tabs/topQuestions/hooks/useThrottledBuffer'
import { ClusterDetailsResponse, FetchClusterQuestions, QuestionDetailsResponse } from 'tabs/topQuestions/types/ApiResponses'
import { clusterDetailsResponseConverter } from 'tabs/topQuestions/conversion/InternalConverters'
import { newClusterQuestionsReceived } from 'tabs/topQuestions/lib/questions_content/questionsContentActions'

type Props = {
  onNewQuestionDetailsReceived: (data: QuestionDetailsResponse[]) => void,
}

type QuestionsDetailsWebsocketContextState = {
  sendFetchClusterQuestionsMessage: (request: FetchClusterQuestions) => void,
  sendQuestionDetailsRequest: (pineconeId: string, mongoId: string) => void

}

export const QuestionsDetailsWebsocketContext = createContext<QuestionsDetailsWebsocketContextState>(
  {
    sendFetchClusterQuestionsMessage: undefined,
    sendQuestionDetailsRequest: undefined,
  },
)

export const QuestionsDetailsWebsocketConnector: React.FC<Props> = ({
                                                                      onNewQuestionDetailsReceived,
                                                                      children,
                                                                    }) => {

  const dispatch = useDispatch()
  const stompSubscriptions = useRef<StompSubscription[]>([])
  const adminUser = useSelector((root: any) => root.adminUser)
  const activeBot = useSelector(selectActiveBot)

  const { getWebsocketClient, websocketConnected } = useContext(SharedWebsocketContext) as any
  const webSocketClient = getWebsocketClient()._client as CompatClient


  useEffect(() => {
    if (websocketConnected && adminUser?.id != null && activeBot?.id != null) {
      stompSubscriptions.current.push(subscribeToQuestionDetails())
      stompSubscriptions.current.push(subscribeToClusterDetails())
      return () => {
        stompSubscriptions.current?.forEach(s => s.unsubscribe())
      }
    }
  }, [webSocketClient.connected, adminUser?.id, activeBot?.id])

  const subscribeToQuestionDetails = () => {
    const userId = selectExceptionally(adminUser.id)

    return webSocketClient.subscribe('/topic/' + userId + '/questions/details', (data) => {
      const questionDetails = questionDetailsResponseConverter(data)
      onNewQuestionDetailsReceivedThrottled(questionDetails)
    })
  }

  const subscribeToClusterDetails = () => {
    const userId = selectExceptionally(adminUser.id)

    return webSocketClient.subscribe('/topic/' + userId + '/questions-cluster', (data) => {
      const questionData = clusterDetailsResponseConverter(data)
      onNewClusterQuestionsPageReceived(questionData)
    })
  }

  const onNewClusterQuestionsPageReceived = (data: ClusterDetailsResponse) => {
    dispatch(newClusterQuestionsReceived(data))
  }

  const onNewQuestionDetailsReceivedThrottled = useThrottledBuffer(onNewQuestionDetailsReceived, 400)

  const sendFetchClusterQuestionsMessage = (request: FetchClusterQuestions) => {
    console.log('/app/questions-clusters')
    const body = JSON.stringify(request)
    webSocketClient.send('/app/questions-clusters', {}, body)
  }

  const sendQuestionDetailsRequest = (pineconeId: string, mongoId: string) => {
    console.log('/app/questions-clusters/details')
    const body = JSON.stringify({
                                  pi: pineconeId,
                                  mi: mongoId,
                                })
    webSocketClient.send('/app/questions-clusters/details', {}, body)
  }


  return <QuestionsDetailsWebsocketContext.Provider value={{ sendFetchClusterQuestionsMessage, sendQuestionDetailsRequest }}>
    {children}
  </QuestionsDetailsWebsocketContext.Provider>
}
