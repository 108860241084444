import { ClusterQuestion } from 'tabs/topQuestions/types/InternalTypes'
import { PayloadAction } from 'tabs/topQuestions/types/GenericTypes'
import { QuestionsDataResponse } from 'tabs/topQuestions/types/ApiResponses'
import { updateClustersMergeQuestions } from 'tabs/topQuestions/lib/questions_scatter_chart/questionScatterChartHandlers'


export type QuestionsChartSliceState = {
  isLoading: boolean,
  clusterQuestions: Partial<ClusterQuestion>[] | null
}

const initialState: QuestionsChartSliceState = {
  isLoading: false,
  clusterQuestions: null,
}
export const UPDATE_LOADING_QUESTIONS_DATA = 'topQuestions/UPDATE_LOADING_QUESTIONS_DATA'
export const CLEAR_TOP_QUESTIONS_DATA = 'topQuestions/CLEAR_TOP_QUESTIONS_DATA'
export const NEW_QUESTION_DATA_RECEIVED = 'topQuestions/NEW_QUESTION_DATA_RECEIVED'

export type NewQuestionDataReceived = PayloadAction<typeof NEW_QUESTION_DATA_RECEIVED, QuestionsDataResponse[]>
export type UpdateLoadingQuestionsData = PayloadAction<typeof UPDATE_LOADING_QUESTIONS_DATA, boolean>
export type ClearTopQuestionsData = PayloadAction<typeof CLEAR_TOP_QUESTIONS_DATA, undefined>


type QuestionChartPayloadAction = NewQuestionDataReceived | ClearTopQuestionsData | UpdateLoadingQuestionsData;

export const questionsChart = (
  state: QuestionsChartSliceState = initialState,
  action: QuestionChartPayloadAction): QuestionsChartSliceState => {
  switch (action.type) {
    case NEW_QUESTION_DATA_RECEIVED:
      return {
        ...state,
        clusterQuestions: updateClustersMergeQuestions(state.clusterQuestions, action.payload),
      }
    case CLEAR_TOP_QUESTIONS_DATA:
      return {
        ...state,
        clusterQuestions: null,
      }
    case UPDATE_LOADING_QUESTIONS_DATA:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}
