import React, { FC, useCallback } from 'react'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import * as Styles from '../styles'
import { UnknownQuestionTableRowProps } from './types'
import { unknownQuestionTableRemoveConfig, unkownQuestionTableRowConfigs } from './configs'
import { UnknownQuestionTableRowSelect } from '../tableRowSelect'
import { UnknownQuestionType } from '../types'
import { useUnknownQuestions } from '../context'
import { TableRowRemoveElement } from '../../tableRowRemove'
import { UnkownQuestionTableRowEditElement } from '../tableRowEdit'
import { TrainQuestionModal } from '../trainQuestionModal'
import { AssignUser } from 'tabs/nlp/components/unansweredQuestions/assignUser/AssignUser'

export const UnkownQuestionTableRow: FC<UnknownQuestionTableRowProps> = ({ question, index }) => {
  const { updateType, deleteQuestion } = useUnknownQuestions()

  const createAtDate = new Date(question.createdAt)

  const onCurrentTypeChange = (value: UnknownQuestionType) => {
    updateType(question, value)
  }

  const onRemoveButtonPressed = useCallback(() => {
    deleteQuestion(question)
  }, [question.id])

  return (
    <TableRow key={index} index={index}>
      <TableCell style={unkownQuestionTableRowConfigs.question}>
        <Styles.AnswerText>{question.question}</Styles.AnswerText>
      </TableCell>
      <TableCell style={unkownQuestionTableRowConfigs.answer}>
        <Styles.AnswerText>{question.answer}</Styles.AnswerText>
      </TableCell>
      <TableCell style={unkownQuestionTableRowConfigs.type}>
        <UnknownQuestionTableRowSelect question={question} onValueChange={onCurrentTypeChange} />
      </TableCell>
      <TableCell style={unkownQuestionTableRowConfigs.assignUser}>
        <AssignUser questionId={question.id} userId={question.assignedUserId} />
      </TableCell>
      <TableCell style={unkownQuestionTableRowConfigs.edit}>
        <UnkownQuestionTableRowEditElement question={question} />
      </TableCell>
      <TableCell style={unkownQuestionTableRowConfigs.train}>
        <TrainQuestionModal question={question} />
      </TableCell>
      <TableCell style={unkownQuestionTableRowConfigs.createdAt}>
        <Styles.AnswerText style={{ textAlign: 'center' }}>
          {`${createAtDate.toLocaleDateString()}`}
          <br />
          {createAtDate.toLocaleTimeString()}
        </Styles.AnswerText>
      </TableCell>
      <TableCell style={unkownQuestionTableRowConfigs.remove}>
        <TableRowRemoveElement
          removeAction={onRemoveButtonPressed}
          title={unknownQuestionTableRemoveConfig.title}
          content={unknownQuestionTableRemoveConfig.content}
        />
      </TableCell>
    </TableRow>
  )
}
