import React, { FC, useState } from 'react'
import { AbbreviationCellProps } from '../../types'
import * as Styles from './styles'
import { AbbreviationItem } from './item'
import { useAbbreviations } from '../../../context'
import { AbbreviationModel } from '../../../type'

export const AbbreviationsTableRowCell: FC<AbbreviationCellProps> = ({
  abbreviationsRecord,
  setExternalAbbreviations,
}) => {
  const { updateAbbreviation, putOnHoldUpdateRequest, sendOnHoldRequestsIfNeed } = useAbbreviations()

  const [abbreviations, setAbbreviations] = useState(abbreviationsRecord.abbreviations)
  const [shouldFocusNewItem, setShouldFocusNewItem] = useState(false)

  const updateRecord = (newAbbreviations = abbreviations) => {
    updateAbbreviation(abbreviationsRecord.id, { ...abbreviationsRecord, abbreviations: newAbbreviations })
  }

  const removeAbbreviationFromList = (abbr: string) => {
    const newAbbreviations = abbreviations.filter(abbreviation => abbreviation !== abbr)
    setAbbreviations(newAbbreviations)

    if (!setExternalAbbreviations) updateRecord(newAbbreviations)
    else setExternalAbbreviations(newAbbreviations)
  }

  const updateAbbreviationValue = (value: string, ind: number) => {
    setShouldFocusNewItem(false)

    const newAbbreviations = [...abbreviations]
    newAbbreviations[ind] = value

    const abbreviationsWithContent = newAbbreviations.filter(filterBlankAbbreviations => filterBlankAbbreviations)
    setAbbreviations(abbreviationsWithContent)

    setExternalAbbreviations && setExternalAbbreviations(abbreviationsWithContent)

    const shouldSendUpdateRecordRequest = abbreviations[ind] !== value
    if (shouldSendUpdateRecordRequest) {
      abbreviationsRecord.abbreviations = abbreviationsWithContent
      return abbreviationsWithContent
    } else {
      sendOnHoldRequestsIfNeed(abbreviationsRecord.id, {
        ...abbreviationsRecord,
        abbreviations: abbreviationsWithContent,
      } as AbbreviationModel)
    }
  }

  const addAbbreviation = () => {
    putOnHoldUpdateRequest(abbreviationsRecord.id)

    setAbbreviations(prevAbbreviations => [...prevAbbreviations, ''])

    setExternalAbbreviations && setExternalAbbreviations(prevAbbreviations => [...prevAbbreviations, ''])

    setShouldFocusNewItem(true)
  }

  const isLastItem = (index: number) => index === abbreviations.length - 1

  const stopUpdateRequestIfExist = () => {
    putOnHoldUpdateRequest(abbreviationsRecord.id)
  }

  return (
    <Styles.AbbreviationsContainer>
      {abbreviations.map((abbr, index) => (
        <AbbreviationItem
          key={index + abbr}
          value={abbr}
          updateValue={(value: string) => updateAbbreviationValue(value, index)}
          updateRecord={updateRecord}
          isFocused={isLastItem(index) && shouldFocusNewItem}
          removeAbbreviationFromList={removeAbbreviationFromList}
          stopUpdateRequestIfExist={stopUpdateRequestIfExist}
          isSingle={abbreviations.length === 1}
        />
      ))}
      <Styles.AbbreviationAddButton onClick={addAbbreviation} />
    </Styles.AbbreviationsContainer>
  )
}
