import React, { useRef, useState, FC, useMemo } from 'react'
import { UserAutocomplete } from 'tabs/nlp/components/unansweredQuestions/assignUser/model/internal-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectSendNotificationChecked,
  updateSendNotificationChange,
} from 'tabs/nlp/components/unansweredQuestions/assignUser/slice/assign-user-slice'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import './assignUser.scss'
import Card from '@mui/material/Card'
import { useOutsideAlerter } from 'hooks/useClickOutside'
import { fetchAssignUserToUnkQuestion } from 'tabs/nlp/components/unansweredQuestions/assignUser/api/assign-user-api'
import * as Styles from 'tabs/nlp/components/unansweredQuestions/assignUser/styles'
import { useUnknownQuestions } from '../context'
import { AssignAutocomplete } from './autocomplete/AssignAutocomplete'
import { alertSuccess } from 'api'

export const AssignUser: FC<{ questionId: string; userId: number }> = ({ questionId, userId }) => {
  const dispatch = useDispatch()
  const { searchQuestions } = useUnknownQuestions()

  const [autocompleteValue, setAutocompleteValue] = useState<UserAutocomplete | null>(null)

  const [popupOpen, setPopUpOpen] = useState<boolean>(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const sendNotificationSend = useSelector(selectSendNotificationChecked)

  const isDisabled = useMemo(() => autocompleteValue?.id === userId, [autocompleteValue, userId])

  useOutsideAlerter(wrapperRef, event => {
    if (!event.target.closest('.MuiAutocomplete-option')) {
      setPopUpOpen(false)
    }
  })

  const shouldOpenPopup = (selectedUser: UserAutocomplete) => {
    selectedUser ? setPopUpOpen(true) : setPopUpOpen(false)
  }

  const onNotificationOptionChange = (sendNotification: boolean) => {
    dispatch(updateSendNotificationChange(sendNotification))
  }

  const selectUser = () => {
    return fetchAssignUserToUnkQuestion(autocompleteValue?.id, questionId, sendNotificationSend).then(() => {
      setPopUpOpen(false)
      searchQuestions()
    })
  }

  const onSelectUser = (user: UserAutocomplete) => {
    setAutocompleteValue(user)
    user?.id !== userId && shouldOpenPopup(user)
  }

  const unAssignUser = () => {
    if (userId && autocompleteValue === null) {
      fetchAssignUserToUnkQuestion(null, questionId, false).then(() => {
        alertSuccess('User unassigned from question!')
        return searchQuestions()
      })
    }
  }

  return (
    <div className={'assignUserWrapper'} ref={wrapperRef}>
      <div className={'assignUserOpenWindowButton'}>
        <AssignAutocomplete
          autocompleteValue={autocompleteValue}
          setAutocompleteValue={onSelectUser}
          userId={userId}
          onBlur={unAssignUser}
        />
      </div>
      <Card className={`assignUserSearchBoxWrapper ${popupOpen ? '' : 'hidden'}`}>
        <div className={'assignUserAutocompleteActions'}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendNotificationSend}
                onChange={(_e, checked) => {
                  onNotificationOptionChange(checked)
                }}
              />
            }
            label="Send Notification"
          />
          <Styles.AssignButton variant="outlined" disabled={isDisabled} onClick={selectUser}>
            Assign
          </Styles.AssignButton>
        </div>
      </Card>
    </div>
  )
}
