/* eslint-disable */
import styled from 'styled-components'
import { AnswerTextArea } from '../../../styles'

const DefinitionContainer = styled.div`
  position: relative;
  max-width: max-content;
  word-break: break-word;
`

const AnswerText = styled.div`
  max-width: 100%;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
`

const DefinitionEditBlock = styled.div`
  position: absolute;
  padding: 3px;
  bottom: 105%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 350px;
  min-height: 180px;
  border-radius: 5px;
  border: 1px solid rgb(235, 235, 239);
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: rgb(235, 235, 239) transparent transparent transparent;
  }
`

const DefinitionTextArea = styled(AnswerTextArea)`
  min-height: 180px;
  border-radius: inherit;
  word-break: break-word;
  max-height: 250px;
`

const DefinitionAddButton = styled.button`
  text-align: center;
  padding: 5px;
  height: 30px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 0;
  cursor: pointer;
  color: #616581;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

  &:hover {
    background-color: rgba(90, 152, 247, 0.2);
  }

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`

export { AnswerText, DefinitionEditBlock, DefinitionContainer, DefinitionTextArea, DefinitionAddButton }
