import React, { ChangeEventHandler, FC, useMemo, useState } from 'react'
import * as LocalStyles from './styles'
import * as Styles from '../styles'
import { UnknownQuestionCreateModalProps } from './types'
import { useKeyOnChange, useModalToggle } from '../hooks'
import ModalTemplate from 'uiKit/ModalTemplate'
import { SparklesIcon } from 'uiKit/icons/SparklesIcon'
import { UnknownQuestionType } from '../types'
import { useUnknownQuestions } from '../context'
import Select from 'react-select'
import SelectorStyles from '../../../constants/selectorStyles'
import { unansweredQuestionTypeAssignOptions } from '../configs'
import styles from './styles.module.scss'
import { AssignAutocomplete } from '../assignUser/autocomplete/AssignAutocomplete'
import { UserAutocomplete } from '../assignUser/model/internal-types'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const Component: FC<UnknownQuestionCreateModalProps> = ({ toggleModal, isOpen }) => {
  const { createQuestion } = useUnknownQuestions()

  const [autocompleteValue, setAutocompleteValue] = useState<UserAutocomplete | null>(null)
  const [sendNotificationSend, setSendNotificationSend] = useState(false)
  const [type, setType] = useState<UnknownQuestionType>()
  const [question, setQuestion] = useState<string>()
  const [answer, setAnswer] = useState<string>()

  const isDataValid = useMemo(() => !!question && !!answer && !!type, [question, answer, type])

  const onUpdateButtonPressed = async (train = false) => {
    if (!isDataValid) return
    createQuestion({ question, answer, type }, train, autocompleteValue, sendNotificationSend, true).then(toggleModal)
  }

  const onAnswerChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setAnswer(event.target.value)
  }

  const onQuestionChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setQuestion(event.target.value)
  }

  const onSelectValueChange = event => {
    setType(event?.value)
    sendNotificationSend
  }

  return (
    <>
      <LocalStyles.CreateUnknowQuestionButton filled onClick={toggleModal}>
        Add New Question
      </LocalStyles.CreateUnknowQuestionButton>
      <ModalTemplate
        containerClasses={styles.modal}
        open={isOpen}
        title="Create custom question"
        titleIcon={
          <Styles.TitleIconWrapper>
            <SparklesIcon />
          </Styles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <Styles.ModalContainer>
          <Styles.SubHeader>Add possible question that user may ask.</Styles.SubHeader>

          <Styles.AnswerInput placeholder="Enter question here..." value={question} onChange={onQuestionChange} />

          <Styles.SubHeader>Add desired bot response to this specific question</Styles.SubHeader>

          <Styles.AnswerInput placeholder="Enter answer here..." value={answer} onChange={onAnswerChange} />

          <Select
            styles={SelectorStyles}
            options={unansweredQuestionTypeAssignOptions}
            onChange={onSelectValueChange}
            placeholder="Select question type"
          />

          <Styles.SubHeader>Assign admin to question</Styles.SubHeader>

          <LocalStyles.AssignAdminBlock>
            <AssignAutocomplete setAutocompleteValue={setAutocompleteValue} autocompleteValue={autocompleteValue} />
            <FormControlLabel
              disabled={!autocompleteValue}
              control={
                <Checkbox
                  color="primary"
                  checked={sendNotificationSend}
                  onChange={(_e, checked) => setSendNotificationSend(checked)}
                />
              }
              style={{ color: 'green' }}
              label="Send Notification"
            />
          </LocalStyles.AssignAdminBlock>

          <Styles.ModalButtonContainer>
            <LocalStyles.ModalButton onClick={toggleModal}>Cancel</LocalStyles.ModalButton>
            <LocalStyles.ModalButton filled disabled={!isDataValid} onClick={() => onUpdateButtonPressed()}>
              Add
            </LocalStyles.ModalButton>
            <LocalStyles.ModalButton filled disabled={!isDataValid} onClick={() => onUpdateButtonPressed(true)}>
              Add and Train
            </LocalStyles.ModalButton>
          </Styles.ModalButtonContainer>
        </Styles.ModalContainer>
      </ModalTemplate>
    </>
  )
}

export const UnknownQuestionCreateModal = () => {
  const { isOpen, toggleModal } = useModalToggle()

  const key = useKeyOnChange(isOpen)

  return <Component {...{ isOpen, toggleModal, key }} />
}
