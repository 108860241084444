export type PayloadAction<K extends string, T> = { type: K, payload: T }

export enum RequestStatus {
  NONE,
  PENDING,
  RESOLVED,
  REJECTED
}

export type ResolvedState<T> = {
  status: RequestStatus.RESOLVED,
  data: T;
}

export type InitializingState = {
  status: RequestStatus.NONE,
}

export type PendingState = {
  status: RequestStatus.PENDING,
}

export type RejectedState = {
  status: RequestStatus.REJECTED,
}

export type PendingRequest<T> = ResolvedState<T> | PendingState | RejectedState | InitializingState;

export type PendingPageRequest<T, P> = (
    PendingRequest<T> &
    {
      pageNum: number,
      isLast: boolean
    })
  |

  (ResolvedState<T> &
    {
      pageNum: number,
      isLast: boolean,
      pointer: P
    });
