import React, { useEffect, useState } from 'react'
import * as Styles from '../styles'
import Popover from 'uiKit/Popover/index'
import { useUnknownQuestions } from '../context'
import SelectorStyles from '../../../constants/selectorStyles'
import { unansweredQuestionTypeSearchOptions } from '../configs'
import Select from 'react-select'
import * as LocalStyles from './styles'
import { selectFetchAutocompleteUsers } from '../assignUser/slice/assign-user-slice'
import { useSelector } from 'react-redux'
import { CrossIcon } from 'uiKit/icons/CrossIcon'
import { SPUN_PEARL_GREY } from 'constants/colors'

export const FilterPopover = () => {
  const { removeFilter: removeFilter, addFilterParam, filter } = useUnknownQuestions()
  const users = useSelector(selectFetchAutocompleteUsers)
  const usersOptions = users.map(user => ({ value: user, label: user.name }))

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [selectedType, setSelectedType] = useState(
    unansweredQuestionTypeSearchOptions.find(q => q.value === filter?.type) || null,
  )
  const [selectedUser, setSelectedUser] = useState(usersOptions.find(u => u.value.id == filter?.assignedUserId) || null)

  const id = anchorEl ? 'simple-popover' : undefined

  const toggleModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onTypeChange = event => {
    setSelectedType(event)
    addFilterParam('type', event?.value || '')

    if (!event) removeFilter('type')
  }

  const onUserChange = event => {
    setSelectedUser(event)
    addFilterParam('assignedUserId', event?.value.id || '')

    if (!event) removeFilter('assignedUserId')
  }

  useEffect(() => {
    if (!filter?.type) setSelectedType(null)
    setSelectedUser(
      users && filter?.assignedUserId ? usersOptions.find(u => u.value?.id == filter?.assignedUserId) : null,
    )
  }, [filter, users])

  return (
    <>
      <Styles.TrainButton onClick={toggleModal}>Filter</Styles.TrainButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        id={id}
        paperStyle={{ overflowY: 'unset', overflowX: 'unset' }}
        style={{ overflow: 'unset' }}
        horizontal="center">
        <LocalStyles.FilterPopoverWrapper>
          <LocalStyles.CloseButton onClick={() => setAnchorEl(null)}>
            <CrossIcon color={SPUN_PEARL_GREY} size="12" />
          </LocalStyles.CloseButton>
          <Select
            styles={SelectorStyles}
            options={unansweredQuestionTypeSearchOptions}
            onChange={onTypeChange}
            isClearable
            placeholder="Select question type"
            value={selectedType}
          />

          <Select
            styles={SelectorStyles}
            options={usersOptions}
            onChange={onUserChange}
            isClearable
            placeholder="Select user"
            value={selectedUser}
          />
        </LocalStyles.FilterPopoverWrapper>
      </Popover>
    </>
  )
}
