import { CSSProperties } from 'react'

export const userFeedbackTableConfig: Record<string, { maxWidth: string; title: string; styles?: CSSProperties }> = {
  userQuestion: { maxWidth: '20%', title: 'User Question' },
  botAnswer: { maxWidth: '20%', title: 'Bot Answer' },
  userFeedback: { maxWidth: '17%', title: 'Feedback' },
  score: { maxWidth: '8%', title: 'Match Score' },
  solveButton: { maxWidth: '10%', title: 'Solve' },
  createdAt: { maxWidth: '10%', title: 'Created At' },
  removeButton: { maxWidth: '5%', title: '' },
}

export const userFeedbackTableTitles = Object.values(userFeedbackTableConfig).map(({ title, maxWidth, styles }) => ({
  title,
  maxWidth,
  styles,
}))
