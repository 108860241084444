import React, { useContext, useEffect, useRef } from 'react'
import { SharedWebsocketContext } from 'contexts/webSockets'
import { CompatClient } from '@stomp/stompjs/src/compatibility/compat-client'
import { StompSubscription } from '@stomp/stompjs'
import { useDispatch, useSelector } from 'react-redux'
import { updateClusteringStatus } from 'tabs/topQuestions/lib/top_questions_config/clusteringConfigurationSlice'
import { UpdateClusteringStatusPayload } from 'tabs/topQuestions/types/InternalTypes'
import { selectActiveBot } from 'tabs/home/reducers/selectors'

export const QUESTIONS_PAGE_SIZE = 10


export const QuestionClusteringStatusWebsocketConnector: React.FC = ({ children }) => {

  const dispatch = useDispatch()
  const stompSubscriptions = useRef<StompSubscription[]>([])
  const adminUser = useSelector((root: any) => root.adminUser)
  const activeBot = useSelector(selectActiveBot)

  const { getWebsocketClient, websocketConnected } = useContext(SharedWebsocketContext) as any
  const webSocketClient = getWebsocketClient()._client as CompatClient


  useEffect(() => {
    if (websocketConnected && adminUser?.id != null && activeBot?.id != null) {
      stompSubscriptions.current.push(subscribeClusteringStatusChange())
      return () => {
        stompSubscriptions.current?.forEach(s => s.unsubscribe())
      }
    }
  }, [websocketConnected, adminUser?.id, activeBot?.id])


  const subscribeClusteringStatusChange = () => {
    const botId = activeBot.id
    return webSocketClient.subscribe('/topic/bot/' + botId + '/clustering-status', (message) => {
      const payload: UpdateClusteringStatusPayload = JSON.parse(message.body)
      dispatch(updateClusteringStatus(payload))
    })
  }

  return <>{children}</>
}
