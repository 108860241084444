import {
  ClusteringExecutionData,
  LINE_CHART_DATA_REQ_PENDING,
  LINE_CHART_DATA_REQ_REJECTED,
  LINE_CHART_DATA_REQ_RESOLVED, LINE_CHART_FILTER_END_DATE_CHANGE, LINE_CHART_FILTER_START_DATE_CHANGE,
  QuestionClustersLineChartActions,
} from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartTypes'
import { PendingRequest, RequestStatus } from 'tabs/topQuestions/types/GenericTypes'
import { DateObject, getCurrentDateMinusMonths, toDateObject } from 'tabs/topQuestions/utils/DateUtils'

export type QuestionClustersLineChartSlice = {
  lienChartFilter: {
    startDate: DateObject | undefined,
    endDate: DateObject | undefined
  },
  lineChartData: PendingRequest<ClusteringExecutionData[]>
}

const initialState: QuestionClustersLineChartSlice = {
  lienChartFilter: {
    startDate: toDateObject(getCurrentDateMinusMonths(1)),
    endDate: toDateObject(new Date(Date.now())),
  },
  lineChartData: { status: RequestStatus.NONE },
}

export const questionsLineChartData = (
  state: QuestionClustersLineChartSlice = initialState,
  action: QuestionClustersLineChartActions): QuestionClustersLineChartSlice => {
  switch (action.type) {
    case LINE_CHART_DATA_REQ_PENDING:
      return {
        ...state,
        lineChartData: { status: RequestStatus.NONE },
      }
    case LINE_CHART_DATA_REQ_RESOLVED:
      return {
        ...state,
        lineChartData: { status: RequestStatus.RESOLVED, data: action.payload },
      }
    case LINE_CHART_DATA_REQ_REJECTED:
      return {
        ...state,
        lineChartData: { status: RequestStatus.REJECTED },
      }
    case LINE_CHART_FILTER_START_DATE_CHANGE:
      return {
        ...state,
        lienChartFilter: {
          startDate: action.payload,
          endDate: state.lienChartFilter.endDate,
        },
      }
    case LINE_CHART_FILTER_END_DATE_CHANGE:
      return {
        ...state,
        lienChartFilter: {
          startDate: state.lienChartFilter.startDate,
          endDate: action.payload,
        },
      }
    default:
      return state
  }
}
