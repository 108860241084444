import {
  BOT_SETTING_DATE_GET,
  BOT_SETTING_DATE_SAVE,
  BOT_SETTING_UPDATE,
  BOT_USERS_SAVE_FEEDBACK_GROUP,
  Bot,
  BotSettingActions,
  BotUser,
} from './BotSettingsTypes'

export type BotState = {
  bot: Bot
  feedback: {
    usersOfGroups: Record<string, BotUser[]>
  }
}

const initialState: BotState = {
  bot: null,
  feedback: {
    usersOfGroups: {},
  },
}

export const botSettingsReduser = (state: BotState = initialState, action: BotSettingActions) => {
  switch (action.type) {
    case BOT_SETTING_DATE_GET:
      return state
    case BOT_SETTING_DATE_SAVE:
      return {
        ...state,
        bot: action.payload,
      }
    case BOT_SETTING_UPDATE:
      return {
        ...state,
        bot: { ...state.bot, ...action.payload, feedback: { ...state.bot.feedback, ...action.payload.feedback } },
      }
    case BOT_USERS_SAVE_FEEDBACK_GROUP:
      return {
        ...state,
        feedback: {
          ...state.feedback,
          usersOfGroups: {
            ...state.feedback.usersOfGroups,
            [action.payload.groupName]: action.payload.users,
          },
        },
      }
    default:
      return state
  }
}

export const getBotSetting = store => {
  const state: BotState = store.botSettingsReduser
  return state.bot
}

export const getUsersOfGroup = (store, groupName: string) => {
  const state: BotState = store.botSettingsReduser
  return state.feedback.usersOfGroups[groupName]
}
