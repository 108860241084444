import React, { FC, useState } from 'react'
import { AbbreviationCreateModalProps } from '../types'
import * as FormStyles from '../../../knowledgeBase/UploadForm/UploadForm.style'
import { useAbbreviations } from '../../context'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

export const AbbreviationUploadForm: FC<Pick<AbbreviationCreateModalProps, 'toggleModal'>> = ({ toggleModal }) => {
  const { uploadAbbreviationFromFile } = useAbbreviations()

  const [isLoading, setIsLoading] = useState(false)

  const handleUpload = event => {
    setIsLoading(true)

    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('file', file)

    uploadAbbreviationFromFile(formData).then(() => {
      toggleModal()
    })
  }

  return (
    <>
      {isLoading ? (
        <LoaderSmall showLoader />
      ) : (
        <FormStyles.UploadForm>
          <FormStyles.Button htmlFor="upload">Upload file</FormStyles.Button>
          <FormStyles.File id="upload" type="file" accept={'.xlsx'} onChange={handleUpload} />
          <FormStyles.Title>Files should be XLSX</FormStyles.Title>
        </FormStyles.UploadForm>
      )}
    </>
  )
}
