import React from 'react'
import './TopQuestionsPage.scss'
import { TopQuestionsLoadingPage } from 'tabs/topQuestions/components/top_questions_loading_page/TopQuestionsLoadingPage'
import * as Styles from 'tabs/nlp/components/UserFeedback/styles'
import Heading from 'uiKit/texts/Heading'
import { ClusteringControls } from 'tabs/topQuestions/components/question_controls/ClusteringControls'
import { TopQuestionsFilterPopover } from 'tabs/topQuestions/components/top_questins_filter/TopQuestionsFilterPopover'


export const TopQuestionsPage: React.FC = () => {

  return <div className={'topQuestionsPageWrapper'}>
    <div className={'topQuestionsPageHeaderWrapper'}>
      <div className={'topQuestionsTitleWrapper'}>
        <div className={'topQuestionsTitle'}>
          <Heading>Top Questions</Heading>
          <Styles.SubHeader>See questions most asked by users.</Styles.SubHeader>
        </div>
        <TopQuestionsFilterPopover />
      </div>
      <ClusteringControls />
    </div>
    <div className={'contentWrapper'}>
      <TopQuestionsLoadingPage />
    </div>
  </div>
}
