import React, { FC, useCallback } from 'react'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import * as Styles from '../styles'
import { TableRowRemoveElement } from '../../tableRowRemove'
import { FeedbackTableRowProps } from './types'
import { userFeedbackTableRemoveConfig, userFeedbackTableRowConfigs } from './config'
import { SolveFeedbackModal } from './solveFeedbackModal'
import { useUserFeedback } from '../context'

export const UserFeedbackTableRow: FC<FeedbackTableRowProps> = ({ feedbackRecord, index }) => {
  const { deleteUserFeedback } = useUserFeedback()

  const createAtDate = new Date(feedbackRecord.createdAt)

  const onDeleteButtonPressed = useCallback(() => {
    deleteUserFeedback(feedbackRecord.id)
  }, [feedbackRecord.id])

  return (
    <TableRow key={index} index={index} style={{}}>
      <TableCell style={userFeedbackTableRowConfigs.question}>
        <Styles.Text>{feedbackRecord.userQuestion}</Styles.Text>
      </TableCell>
      <TableCell style={userFeedbackTableRowConfigs.botAnswer}>
        <Styles.Text>{feedbackRecord.botAnswer}</Styles.Text>
      </TableCell>
      <TableCell style={userFeedbackTableRowConfigs.userFeedback}>
        <Styles.Text>{feedbackRecord.userFeedback}</Styles.Text>
      </TableCell>
      <TableCell style={userFeedbackTableRowConfigs.score}>
        <Styles.Text>{(feedbackRecord.matchScore * 100).toFixed(0) + '%'}</Styles.Text>
      </TableCell>
      <TableCell style={userFeedbackTableRowConfigs.solveButton}>
        <SolveFeedbackModal feedback={feedbackRecord} />
      </TableCell>
      <TableCell style={userFeedbackTableRowConfigs.createdAt}>
        <Styles.Text>{createAtDate.toLocaleDateString() + '\n' + createAtDate.toLocaleTimeString()}</Styles.Text>
      </TableCell>
      <TableCell style={userFeedbackTableRowConfigs.removeButton}>
        <TableRowRemoveElement
          removeAction={onDeleteButtonPressed}
          title={userFeedbackTableRemoveConfig.title}
          content={userFeedbackTableRemoveConfig.content}
        />
      </TableCell>
    </TableRow>
  )
}
