import React from 'react'
import { withRouter } from 'react-router-dom'
import Heading from 'uiKit/texts/Heading'
import * as Styles from './styles'
import { TrainSearchInput } from '../searchField'
import Table from '../../../../uiKit/table/Table'
import { useAbbreviations } from './context'
import { AbbreviationTableRow } from './tableRow'
import { abbreviationsTableTitles } from './configs'
import { AbbreviationCreateModal } from './createAbbreviation'
import { AbbreviationBulkFileUploadModal } from './fileUploadModal'

const AbbreviationTab = () => {
  const { abbreviationsRecords, updateSearchValue } = useAbbreviations()

  return (
    <Styles.Container>
      <Heading>Abbreviations</Heading>
      <Styles.SubHeader>Train bot to understand abbreviations</Styles.SubHeader>

      <Styles.FiltersContainer>
        <TrainSearchInput placeholder="Search abbreviation" updateSearchValue={updateSearchValue} />
        <Styles.FiltersButtonsContainer>
          <AbbreviationBulkFileUploadModal />
          <AbbreviationCreateModal />
        </Styles.FiltersButtonsContainer>
      </Styles.FiltersContainer>

      <Table titles={abbreviationsTableTitles}>
        {abbreviationsRecords.map((abbreviationRecord, index) => (
          <AbbreviationTableRow key={abbreviationRecord.id} abbreviationsRecord={abbreviationRecord} index={index} />
        ))}
      </Table>
    </Styles.Container>
  )
}

export default withRouter(AbbreviationTab)
