import { ClusteringExecutionData, ExecutionCluster } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartTypes'
import { toRgba } from 'tabs/topQuestions/utils/ColorUtils'
import { formatDate, fromTimestampSeconds, rangeBetweenDates } from 'tabs/topQuestions/utils/DateUtils'
import _ from 'lodash'
import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'

export type ChartDataSets = {
  data: number[]
  label: string
  backgroundColor?: string
  borderColor?: string
  fill: boolean
}

export function questionsCountComparator(counter: (c: ClusterDetails) => number) {
  return (cluster1: ClusterDetails, cluster2: ClusterDetails) => {
    const count1 = counter(cluster1)
    const count2 = counter(cluster2)
    if (count1 == undefined && count2 == undefined) {
      return 0
    }
    if (count1 != undefined && count2 == undefined) {
      return 1
    }
    if (count2 != undefined && count1 === undefined) {
      return -1
    }

    if (count1 < count2) {
      return 1
    }
    return -1
  }
}


function buildClusterDataPoint(label: string,
                               count: number,
                               backgroundColor: string,
                               borderColor: string,
) {
  return {
    data: [count],
    label,
    backgroundColor,
    borderColor,
    fill: true,
    tension: 0.3,
    pointRadius: 2,
  }
}


function sortClusterExecutions(clusteringExecutions: ClusteringExecutionData[]) {
  return clusteringExecutions.sort((e1, e2) => {
    return e2.clusteringFinishedAt - e1.clusteringFinishedAt
  })
}

function getLastExecutionClusterIds(sortedClusteringExecutions: ClusteringExecutionData[]) {
  if (sortedClusteringExecutions.length > 0) {
    const lastExecution = (sortClusterExecutions(sortedClusteringExecutions))[0]
    const lastExecutionClusters = lastExecution.executionClusters.map(c => c.clusterUniqueId)
    return new Set<string>(lastExecutionClusters)
  } else {
    return new Set<string>()
  }
}

export function toChartDatasets(startFormTimestamp: number,
                                finishTimeStamp: number,
                                clusteringExecutions: ClusteringExecutionData[],
                                clusterColors: Record<string, string>,
                                hoveredClusterUniqueId: string | undefined): [string[], ChartDataSets[]] {
  const dateRange = rangeBetweenDates(startFormTimestamp, finishTimeStamp)
  const sortedClusteringExecutions = sortClusterExecutions(clusteringExecutions)

  const lastExecutionClusterIds = getLastExecutionClusterIds(sortedClusteringExecutions)

  const allClusterIds = sortedClusteringExecutions
    .flatMap(c => c.executionClusters)
    .map(c => c.clusterUniqueId)


  const allUniqueClusterIds = new Set<string>(allClusterIds)

  const clusteringExecutionsDateMap = _.groupBy(sortedClusteringExecutions, (val) => {
    return formatDate(fromTimestampSeconds(val.clusteringFinishedAt))
  })

  // final result of transformation - list where each element is a data that belongs to the specific cluster,
  // each element inside data property is a number of questions inside this cluster at this moment
  const dataSets: Record<string, ChartDataSets> = {}
  const dataKeys: string[] = []

  // go through each date in the selected range
  for (const date of dateRange) {
    dataKeys.push(date)
    // get all clustering executions executed during this day
    const dateClusteringExecution = clusteringExecutionsDateMap[date]


    if (dateClusteringExecution != undefined && sortedClusteringExecutions.length > 0) {
      fillClustersFromExecution(dataSets, dateClusteringExecution, allUniqueClusterIds, hoveredClusterUniqueId, clusterColors)
    } else {
      fillEmptyClustersData(dataSets, allUniqueClusterIds, hoveredClusterUniqueId, clusterColors)
    }
  }

  const filteredDataSets = filterClustersNotPresentInTheLastExecution(dataSets, lastExecutionClusterIds)

  return [dataKeys, filteredDataSets]
}

function fillClustersFromExecution(dataSets: Record<string, ChartDataSets>,
                                   dateClusteringExecution: ClusteringExecutionData[],
                                   allUniqueClusterIds: Set<string>,
                                   hoveredClusterUniqueId: string | undefined,
                                   clusteringColors: Record<string, string>) {

  // if there was a clustering execution during that day
  // get only last execution
  const { executionClusters } = dateClusteringExecution[0]

  // for all unique clusters ids in all executions
  for (const uniqueClusterId of allUniqueClusterIds) {
    // find cluster with unique id inside of the execution
    const clusteringData = findClusterInExecution(executionClusters, uniqueClusterId)
    // if cluster present inside of the execution
    if (clusteringData != undefined) {
      const clusterCount = clusteringData.questionsCount
      //check if the cluster was included in result object from previous execution
      const existingDataSet = dataSets[uniqueClusterId]
      if (existingDataSet == undefined) {
        dataSets[uniqueClusterId] = buildClusterDataPoint(clusteringData.name,
                                                          clusterCount,
                                                          getBackgroundColor(clusteringData, clusteringColors),
                                                          getBorderColor(clusteringData, clusteringColors),
        )
      } else {
        //if these values were not present in the previous dataset update them using new values
        if (existingDataSet.label == undefined) {
          existingDataSet.label = clusteringData.name
        }
        fillClusterColorData(hoveredClusterUniqueId, existingDataSet, clusteringData, clusteringColors)
        existingDataSet.data.push(clusterCount)
        dataSets[uniqueClusterId] = existingDataSet
      }
    }
  }
}

function fillEmptyClustersData(dataSets: Record<string, ChartDataSets>,
                               allUniqueClusterIds: Set<string>,
                               hoveredClusterUniqueId: string | undefined,
                               clustersColors: Record<string, string>) {
  const emptyClusters = [...allUniqueClusterIds].map(clusterId => {
    return {
      questionsCount: 0,
      clusterUniqueId: clusterId,
    }
  })
  const newVar = [{ executionClusters: emptyClusters, clusteringFinishedAt: -1 }]
  fillClustersFromExecution(dataSets, newVar, allUniqueClusterIds, hoveredClusterUniqueId, clustersColors)
}

function filterClustersNotPresentInTheLastExecution(dataSets: Record<string, ChartDataSets>,
                                                    lastExecutionClusterIds: Set<string>) {
  return Object.entries(dataSets)
    .filter(([key]) => lastExecutionClusterIds.has(key))
    .map(([, val]) => val)
}

function fillClusterColorData(hoveredClusterUniqueId: string,
                              existingDataSet: ChartDataSets,
                              clusteringData: ExecutionCluster,
                              clustersColors: Record<string, string>) {
  if (hoveredClusterUniqueId != undefined) {
    fillClusterColorDataWhenHovered(clusteringData, hoveredClusterUniqueId, existingDataSet, clustersColors)
  } else {
    fillClusterColorDataWhenNotHovered(existingDataSet, clusteringData, clustersColors)
  }
}

const FALLBACK_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.03)'
const FALLBACK_BORDER_COLOR = 'rgba(0, 0, 0, 0.1)'

function fillClusterColorDataWhenHovered(clusteringData: ExecutionCluster,
                                         hoveredClusterUniqueId: string,
                                         existingDataSet: ChartDataSets,
                                         clustersColors: Record<string, string>) {
  if (clusteringData.clusterUniqueId === hoveredClusterUniqueId) {
    existingDataSet.backgroundColor = getBackgroundColor(clusteringData, clustersColors)
    existingDataSet.borderColor = getBorderColor(clusteringData, clustersColors)
  } else {
    existingDataSet.backgroundColor = FALLBACK_BACKGROUND_COLOR
    existingDataSet.borderColor = FALLBACK_BORDER_COLOR
  }
}

function fillClusterColorDataWhenNotHovered(existingDataSet: ChartDataSets,
                                            clusteringData: ExecutionCluster,
                                            clustersColors: Record<string, string>) {
  if (existingDataSet.backgroundColor == undefined && clusteringData.colorRgb != undefined) {
    existingDataSet.backgroundColor = getBackgroundColor(clusteringData, clustersColors)
  }
  if (existingDataSet.borderColor == undefined && clusteringData.colorRgb != undefined) {
    existingDataSet.borderColor = getBorderColor(clusteringData, clustersColors)
  }
}


function getBorderColor(clusteringData: ExecutionCluster,
                        clustersColors: Record<string, string>) {
  const lastColor = clustersColors[clusteringData.clusterUniqueId]
  if (lastColor != undefined) {
    return lastColor
  }
  if (clusteringData.colorRgb != undefined) {
    return toRgba(...clusteringData.colorRgb, 0.8)
  }
}

function getBackgroundColor(clusteringData: ExecutionCluster,
                            clustersColors: Record<string, string>) {
  const lastColor = clustersColors[clusteringData.clusterUniqueId]
  if (lastColor != undefined) {
    return lastColor
  }
  if (clusteringData.colorRgb != undefined) {
    return toRgba(...clusteringData.colorRgb, 0.3)
  }
}


function findClusterInExecution(executionClusters: ExecutionCluster[], uniqueClusterId: string) {
  return executionClusters
    .find(execution => execution.clusterUniqueId === uniqueClusterId)
}
