import React from 'react'

export const SettingIcon: React.FC = () => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#2a353d" strokeWidth="1.5">
        <path
          d="m21.3175 7.14139-.4936-.8566c-.3733-.64783-.5599-.97174-.8775-1.10091-.3176-.12916-.6768-.02724-1.3951.1766l-1.2202.3437c-.4586.10576-.9398.04576-1.3585-.16939l-.3369-.19437c-.3591-.22999-.6353-.56909-.7882-.96768l-.3339-.99738c-.2196-.66002-.3294-.99003-.5908-1.17879-.2613-.18876-.6085-.18876-1.3029-.18876h-1.1148c-.6943 0-1.0415 0-1.3029.18876-.26135.18876-.37114.51877-.59071 1.17879l-.33396.99738c-.15288.39859-.42908.73769-.78816.96768l-.33688.19437c-.41875.21515-.8999.27515-1.35851.16939l-1.22023-.3437c-.71834-.20384-1.0775-.30576-1.39508-.1766-.31758.12917-.50422.45308-.87752 1.10091l-.49358.8566c-.34991.60725-.52487.91088-.49091 1.2341.03395.32322.26817.58369.7366 1.10463l1.03104 1.15268c.252.319.43091.875.43091 1.3749 0 .5001-.17885 1.0559-.43088 1.375l-1.03107 1.1527c-.46843.521-.70264.7814-.7366 1.1047-.03396.3232.141.6268.49091 1.234l.49357.8566c.37329.6478.55995.9718.87753 1.1009.31758.1292.67675.0273 1.3951-.1766l1.22017-.3437c.45869-.1058.93993-.0457 1.35873.1695l.33683.1944c.35901.23.63514.569.788.9676l.33399.9975c.21957.66.32936.99.59071 1.1788.2614.1887.6086.1887 1.3029.1887h1.1148c.6944 0 1.0416 0 1.3029-.1887.2614-.1888.3712-.5188.5908-1.1788l.334-.9975c.1528-.3986.4289-.7376.788-.9676l.3368-.1944c.4188-.2152.9-.2753 1.3587-.1695l1.2202.3437c.7183.2039 1.0775.3058 1.3951.1766.3176-.1291.5042-.4531.8775-1.1009l.4936-.8566c.3499-.6072.5248-.9108.4909-1.234-.034-.3233-.2682-.5837-.7366-1.1047l-1.0311-1.1527c-.252-.3191-.4309-.8749-.4309-1.375 0-.4999.179-1.0559.4309-1.3749l1.0311-1.15268c.4684-.52094.7026-.78141.7366-1.10463.0339-.32322-.141-.62685-.4909-1.2341z"
          strokeLinecap="round"
        />
        <path d="m15.5195 12c0 1.933-1.567 3.5-3.5 3.5s-3.49997-1.567-3.49997-3.5 1.56697-3.5 3.49997-3.5 3.5 1.567 3.5 3.5z" />
      </g>
    </svg>
  )
}
