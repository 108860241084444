import { combineReducers } from 'redux'

import analytics from '../tabs/analytics/reducers/analytics'
import funnels from '../tabs/analytics/reducers/funnels'
import funnel from '../tabs/analytics/reducers/funnel'

import security from '../tabs/authorization/reducers/security'
import adminUser from '../tabs/authorization/reducers/adminUser'

import broadcasts from '../tabs/broadcast/reducers/broadcasts'

import supportStatistics from '../tabs/dashboard/reducers/supportStatistics'
import usersStatistics from '../tabs/dashboard/reducers/usersStatistics'
import popularHoursStatistics from '../tabs/dashboard/reducers/popularHoursStatistics'

import flows from '../tabs/flowBuilder/reducers/flows'
import atom from '../tabs/flowBuilder/reducers/atom'
import atoms from '../tabs/flowBuilder/reducers/atoms'
import tags from '../tabs/flowBuilder/reducers/tags'
import searchAtoms from '../tabs/flowBuilder/reducers/searchAtoms'

import bots from '../tabs/home/reducers/bots'
import activeBot from '../tabs/home/reducers/activeBot'
import snackBar from '../tabs/home/reducers/snackBar'

import { botLanguages } from '../tabs/nlp/reducers/botLanguages'
import intents from '../tabs/nlp/reducers/intents'
import intentsSize from '../tabs/nlp/reducers/intentsSize'
import contextSize from '../tabs/nlp/reducers/contextSize'
import contexts from '../tabs/nlp/reducers/contexts'
import nlpRequests from '../tabs/nlp/reducers/nlpRequests'

import botAccessors from '../tabs/settings/reducers/botAccessors'
import token from '../tabs/settings/reducers/facebookToken'
import settingsPages from '../tabs/settings/reducers/settingsPages'
import twilioCredentials from '../tabs/settings/reducers/twilioCredentials'

import supportRequests from '../tabs/support/reducers/supportRequests'
import supportUsersNew from '../tabs/support/reducers/users'
import activeUser from '../tabs/support/reducers/activeUser'

import widgetSettings from '../tabs/widget/reducers/widgetSettings'
import aiChatSettings from '../tabs/widget/reducers/aiChatSettings'
import widgetDefaultSettings from '../tabs/widget/reducers/widgetDefaultSettings'

import account from '../tabs/account/reducers/account'
import attributes from '../tabs/settings/reducers/attributes.js'
import forbiddenError from '../tabs/authorization/reducers/forbiddenError'
import { topQuestions } from '../tabs/topQuestions/lib/top_questions/topQuestionsSlice'
import { clusteringConfigurationSlice } from '../tabs/topQuestions/lib/top_questions_config/clusteringConfigurationSlice'
import { assignUserSlice } from '../tabs/nlp/components/unansweredQuestions/assignUser/slice/assign-user-slice'
import { topQuestionsSearch } from '../tabs/topQuestions/lib/top_questions_search/topQuestionsSearchSlice'
import { questionsChart } from '../tabs/topQuestions/lib/questions_scatter_chart/questionScatterCoordinatesSlice'
import { questionsContentSlice } from '../tabs/topQuestions/lib/questions_content/questionsContentSlice'
import { questionsLineChartData } from '../tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartSlice'

import { botSettingsReduser } from '../tabs/nlp/components/UserFeedback/settings/state/BotSettingsReduser'

// -- APPEND IMPORT HERE --
const Reducers = combineReducers({
  security,
  flows,
  bots,
  activeBot,
  atom,
  broadcasts,
  activeUser,
  botLanguages,
  intents,
  intentsSize,
  contextSize,
  contexts,
  nlpRequests,
  snackBar,
  atoms,
  widgetSettings,
  aiChatSettings,
  supportRequests,
  adminUser,
  botAccessors,
  tags,
  settingsPages,
  token,
  supportStatistics,
  usersStatistics,
  popularHoursStatistics,
  analytics,
  searchAtoms,
  twilioCredentials,
  supportUsersNew,
  account,
  attributes,
  widgetDefaultSettings,
  funnels,
  funnel,
  forbiddenError,
  topQuestions,
  questionsChart,
  questionsContentSlice,
  topQuestionsSearch,
  clusteringConfigurationSlice,
  assignUserSlice,
  questionsLineChartData,
  botSettingsReduser,
  // -- APPEND FILE HERE --
})

export default Reducers
