import React, { FC, memo } from 'react'
import * as Styles from '../unansweredQuestions/styles'
import { TableRowRemoveElementProps } from './types'
import ModalTemplate from 'uiKit/ModalTemplate'
import { useModalToggle } from '../unansweredQuestions/hooks'
import { AlertTriangle } from 'uiKit/icons/AlertTriangle'
import { NewDeleteIcon } from 'uiKit/icons/NewDeleteIcon'

export const TableRowRemoveElement: FC<TableRowRemoveElementProps> = memo(({ removeAction, title, content }) => {
  const { isOpen, toggleModal } = useModalToggle()

  return (
    <>
      <Styles.ModalIconContainer onClick={toggleModal}>
        <NewDeleteIcon />
      </Styles.ModalIconContainer>
      <ModalTemplate
        open={isOpen}
        title={title}
        titleIcon={
          <Styles.TitleIconWrapper>
            <AlertTriangle />
          </Styles.TitleIconWrapper>
        }
        onClose={toggleModal}
        withFooter={false}>
        <Styles.ModalContainer>
          <Styles.SubHeader>{content}</Styles.SubHeader>
          <Styles.ModalButtonContainer>
            <Styles.RemoveButton onClick={removeAction}>Remove</Styles.RemoveButton>
          </Styles.ModalButtonContainer>
        </Styles.ModalContainer>
      </ModalTemplate>
    </>
  )
})
