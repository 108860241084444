import React from 'react'

import './TopQuestionsFilter.scss'
import { TopQuestionsTypeSelect } from 'tabs/topQuestions/components/top_questions_filter/type_select/TopQuestionsTypeSelect'

export const TopQuestionsFilter: React.FC = () => {


  return <div className={'topQuestionFilter'}>
    {/*<TopQuestionsNameFilterInput />*/}
    <TopQuestionsTypeSelect />
  </div>
}
