import { ClusterDetailsResponse } from 'tabs/topQuestions/types/ApiResponses'
import { CLEAR_CLUSTER_QUESTION, ClearClusterQuestion, NEW_CLUSTER_QUESTIONS_RECEIVED, NewClusterQuestionsReceived } from 'tabs/topQuestions/lib/questions_content/questionsContentTypes'

export const newClusterQuestionsReceived = (payload: ClusterDetailsResponse): NewClusterQuestionsReceived => ({
  type: NEW_CLUSTER_QUESTIONS_RECEIVED,
  payload: payload,
})

export const clearClusterQuestion = (): ClearClusterQuestion => ({
  type: CLEAR_CLUSTER_QUESTION,
  payload: undefined,
})

