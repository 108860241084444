import { appBotService } from '../../api/bot'
import { botSettingSave, botUsersGroupSave } from './BotSettingActions'

export const retrieveBotSettings = () => {
  return async dispatch => {
    const bot = await appBotService.getBotSettings()
    dispatch(botSettingSave(bot))
  }
}

export const retrieveUsersGroup = () => {
  return async dispatch => {
    const groupName = 'default'
    const users = await appBotService.getBotUsers({ groupName })
    dispatch(botUsersGroupSave(users, groupName))
  }
}
