import { useBuffer } from 'hooks/useBuffer'
import { useThrottle } from 'tabs/topQuestions/hooks/useThrottle'

// Argument function that is called with
type BufferSubscriber<U> = (events: U[]) => void

export const useThrottledBuffer = <U, >(
  bufferSubscriber: BufferSubscriber<U>,
  throttle = 100): (event: U) => void => {

  const buffer = useBuffer(bufferSubscriber)
  const throttledReleaseSignal = useThrottle(buffer.releaseSignal, throttle)

  return (e: U) => {
    throttledReleaseSignal()
    buffer.append(e)
  }
}
