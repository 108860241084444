/* eslint-disable */
import { CSSProperties } from 'react'
import { AbbreviationsTableFields } from '../type'
import { abbreviationsTableConfig } from '../configs'

export const abbreviationTableRowConfigs: Record<AbbreviationsTableFields, CSSProperties> = {
  fullName: {
    maxWidth: abbreviationsTableConfig.fullName.maxWidth,
  },
  abbreviations: {
    maxWidth: abbreviationsTableConfig.abbreviations.maxWidth,
  },
  definition: {
    maxWidth: abbreviationsTableConfig.definition.maxWidth,
  },
  removeButton: {
    maxWidth: abbreviationsTableConfig.removeButton.maxWidth,
  },
}

export const abbreviationsTableRemoveConfig = {
  title: 'Remove abbreviations?',
  content: `Are you sure you want to remove this abbreviations? Once you've done it, you won't be able to revert the changes.`,
}

export const notAllowToSaveChangesForSingleInput = (inputText: string) => {
  return !!inputText.trim()
}
