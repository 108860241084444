import { TextField } from '@mui/material'
import { Autocomplete } from '@mui/lab'
import React, { FC, useEffect, useState } from 'react'
import { UserAutocomplete } from 'tabs/nlp/components/unansweredQuestions/assignUser/model/internal-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFetchAutocompleteUsers,
  updateSelectedUserIdChange,
} from 'tabs/nlp/components/unansweredQuestions/assignUser/slice/assign-user-slice'
import '../assignUser.scss'

type AssignAutocompleteProps = {
  defaultProps?: any
  autocompleteValue: any
  setAutocompleteValue: any
  userId?: number
  onBlur?: (event) => void
}

export const AssignAutocomplete: FC<AssignAutocompleteProps> = ({
  defaultProps,
  setAutocompleteValue,
  autocompleteValue,
  userId,
  onBlur,
}) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState<UserAutocomplete[]>([])
  const userAutocompleteValues = useSelector(selectFetchAutocompleteUsers)

  const onAutocompleteItemSelected = (user: UserAutocomplete) => {
    setAutocompleteValue(user)
    dispatch(updateSelectedUserIdChange(user))
  }

  const renderOptionFunc = (props, option: UserAutocomplete) => {
    return (
      <li {...props} key={option.id}>
        {option.name}
      </li>
    )
  }

  useEffect(() => {
    setOptions(userAutocompleteValues)
    setAutocompleteValue(userAutocompleteValues.find(user => user.id === userId))
  }, [])

  return (
    <Autocomplete
      {...defaultProps}
      freeSolo
      value={autocompleteValue}
      options={options}
      getOptionLabel={(option: UserAutocomplete) => option.name}
      renderInput={params => <TextField fullWidth {...params} variant="outlined" placeholder="None" size="small" />}
      onChange={(_event, newValue) => onAutocompleteItemSelected(newValue as UserAutocomplete)}
      renderOption={renderOptionFunc}
      onBlur={onBlur}
    />
  )
}
