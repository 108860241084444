import { TopQuestionsDataRangeSelect } from 'tabs/topQuestions/components/top_questions_data_range_select/TopQuestionsDataRangeSelect'
import React from 'react'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import FilterListIcon from '@mui/icons-material/FilterList'
import './TopQuestinsFilter.scss'
import { TopQuestionsFilter } from 'tabs/topQuestions/components/top_questions_filter/TopQuestionsFilter'

export const TopQuestionsFilterPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <div className={'popoverWrapper'}>
          <h5>Filter the date clustering results</h5>
          <TopQuestionsDataRangeSelect />
          <TopQuestionsFilter />
        </div>
      </Popover>
    </div>
  )

}
