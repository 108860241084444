import { StyledComponentProps } from '@material-ui/core/styles/withStyles'
import { FetchClusterQuestions } from 'tabs/topQuestions/types/ApiResponses'
import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'
import { withStyles } from '@material-ui/core'


import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { QuestionsList } from 'tabs/topQuestions/components/questions_list/QuestionsList'
import { Pagination } from '@mui/lab'

import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { QuestionClusterCount } from 'tabs/topQuestions/components/question_cluster_count/QuestionClusterCount'
import { UsePaginationProps } from '@mui/material/usePagination/usePagination'
import { Box } from '@mui/material'

import './QuestionClusterItem.scss'
import { QuestionClusterName } from 'tabs/topQuestions/components/question-cluster-name/QuestionClusterName'
import { useSelector } from 'react-redux'
import { selectQuestionTypeFilter } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchSelectors'
import { getTopQuestionCount } from 'tabs/topQuestions/types/topQuestionsUtil'
import { QUESTIONS_PAGE_SIZE } from 'tabs/topQuestions/components/questions_websocket_connector/QuestionClusteringStatusWebsocketConnector'
import { ColorRgb } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartTypes'

type QuestionClusterItemProps = {
  expandedClusterId: string,
  setExpandedClusterId: (clusterId: string | undefined) => void,
  topQuestion: ClusterDetails
  sendFetchClusterQuestionsMessage: (request: FetchClusterQuestions) => void,
  questionColors: Record<string, ColorRgb>
} & StyledComponentProps

const styles = theme => ({
  root: {
    width: '100%',
  },
  panelSummary: {
    gap: theme.typography.pxToRem(12),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  spanner: {
    flexGrow: 1,
  },
  expansionItemWrapper: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})

const PAGE_SIZE = 10

export const QuestionClusterItem = withStyles(styles)(({
                                                         expandedClusterId,
                                                         setExpandedClusterId,
                                                         topQuestion,
                                                         sendFetchClusterQuestionsMessage,
                                                         questionColors,
                                                       }: QuestionClusterItemProps) => {
  const { clusterId, clusterUniqueId } = topQuestion
  const clusterColor = questionColors[clusterUniqueId]
  const topQuestionsFilter = useSelector(selectQuestionTypeFilter)
  const wasExpanded = useRef<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(0)

  const questionsCount = getTopQuestionCount(topQuestion, topQuestionsFilter)
  const totalPages = Math.ceil(questionsCount / PAGE_SIZE)

  useEffect(() => {
    if (expandedClusterId !== topQuestion.clusterId) {
      setCurrentPage(0)
    }
    if (expandedClusterId === topQuestion.clusterId && wasExpanded.current == true) {
      sendFetchClusterQuestionsMessage({
                                         clusterId,
                                         page: currentPage,
                                         pageSize: QUESTIONS_PAGE_SIZE,
                                         typeFilter: topQuestionsFilter,
                                       })
    }
  }, [currentPage, expandedClusterId])

  const onPageChange: UsePaginationProps['onChange'] = (_event, page) => {
    const zeroIndexedPage = page - 1
    setCurrentPage(zeroIndexedPage)
  }

  const handleChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
    if (!wasExpanded.current && isExpanded) {
      wasExpanded.current = true
    }
    setExpandedClusterId(isExpanded ? clusterId : undefined)
  }


  return <>
    <Accordion className={'question-accordion'} defaultExpanded expanded={expandedClusterId === clusterId} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3-content">
        <div className={'question-accordion-header-wrapper'}>
          <QuestionClusterCount totalQuestionCount={questionsCount}
                                clusterColor={clusterColor}
                                clusterId={clusterId} />
          <Box sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}>
            <QuestionClusterName topQuestion={topQuestion} />
          </Box>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {wasExpanded &&
          <QuestionsList page={currentPage}
                         pageSize={PAGE_SIZE}
                         topQuestion={topQuestion}
          />}
      </AccordionDetails>
      <AccordionActions>
        <div className={'question-cluster-pagination-wrapper'}>
          <Pagination page={currentPage + 1} onChange={onPageChange} count={totalPages} />
        </div>
      </AccordionActions>
    </Accordion>


  </>
})
