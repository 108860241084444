export type Comparator<T> = (a: T, b: T) => 1 | -1 | 0;

export const combineComparators = <T>(comparator1: Comparator<T>, comparator2: Comparator<T>): Comparator<T> => {
  return (a: T, b: T) => {
    const result1 = comparator1(a, b)
    if (result1 != 0) {
      return result1
    }
    return comparator2(a, b)
  }
}

export const distinctByKey = <T>(fieldName: keyof T): (elem: T) => boolean => {
  const seen = new Set<T[keyof T]>()
  return (elem) => {
    const newValue = elem[fieldName]
    if (!seen.has(newValue)) {
      seen.add(newValue)
      return true
    }
    return false
  }
}

export const comparingFields = <T>(fieldName: keyof T, direction: 'asc' | 'desc' = 'asc'): Comparator<T> => {
  return (a: T, b: T) => {
    const val1 = a[fieldName]
    const val2 = b[fieldName]
    if (val1 == val2) {
      return 0
    }
    if ((direction === 'asc' && val1 > val2)
      || (direction == 'desc' && val1 < val2)) {
      return 1
    } else {
      return -1
    }
  }
}
