import styled from 'styled-components'
import * as Styles from '../../styles'

const getScoreColor = (score: number) => {
  if (score > 82) return 'rgb(109 222 0)'
  if (score >= 70) return '#dddc07'
  return 'rgb(221 7 7)'
}

const Score = styled.div<{ score: number }>`
  font-size: 20px;
  margin: 5px;
  color: ${({ score }) => getScoreColor(score)};
`

const ScoreContainer = styled.div`
  display: flex;
  width: 100%;
`

const SolveButton = styled(Styles.Button)`
  color: ${({ disabled }) => disabled && 'white'};
`
const AnswerInput = styled.textarea`
  padding: 10px;
  outline: none;
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  line-height: 19px;
  background-color: white;
  color: black;
  border: 1px solid #c7cad6;
  min-height: 120px;
`

export { Score, ScoreContainer, SolveButton, AnswerInput }
