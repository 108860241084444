import React from 'react'
import { NavLink } from 'react-router-dom'

import { UserMenu } from '../UserMenu'
import { LeftArrowIcon } from '../../../../uiKit/icons/Icons'
import { Button } from '@material-ui/core'

export const HomeHeader = props => {
  const isLoader = props.accountSettings

  return (
    <div className={props.classes.header}>
      <div
        className={props.classes.headerInner}
        style={!props.backButton ? { justifyContent: 'flex-end' } : null}>
        {props.backButton && (
          <NavLink
            to={props.backUrl ? props.backUrl : '/admin/bot/1/nlp/ai-knowledge'}
            style={{ textDecoration: 'none' }}>
            <Button className={props.classes.button}>
              <LeftArrowIcon color="#1658F3" />
              {props.backUrl && <span>Back</span>}
              {!props.backUrl && <span>Back to bots</span>}
            </Button>
          </NavLink>
        )}
        {isLoader && (
          <h4
            className={
              props.backButton ? props.classes.title : props.classes.titleCenter
            }>
            {props.title}
          </h4>
        )}
        <UserMenu />
      </div>
    </div>
  )
}
