import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'
import 'tabs/topQuestions/components/questions_list/QuestionsList.scss'
import { useSelector } from 'react-redux'
import * as React from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@material-ui/core/Divider'
import { selectClusterQuestionsWithContent } from 'tabs/topQuestions/lib/questions_content/questionContentSelector'

type QuestionsInfiniteScrollListProps = {
  topQuestion: ClusterDetails
  page: number
  pageSize: number
}


export const QuestionsList: React.FC<QuestionsInfiniteScrollListProps> =
  ({ topQuestion, page, pageSize }) => {

    const clusterQuestions = useSelector(selectClusterQuestionsWithContent(topQuestion.clusterId))

    const pageStart = page * pageSize
    const pageEnd = (page + 1) * pageSize
    const displayedClusterQuestions = clusterQuestions.slice(pageStart, pageEnd)

    return <List component="div" disablePadding>
      {displayedClusterQuestions.map((q, idx, arr) => {
        return <div key={q.mongoLogId}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemText primary={q.questionContent} />
          </ListItemButton>
          {(idx + 1 != arr.length) && <Divider />}
        </div>
      })}
    </List>

  }
