/* eslint-disable */
import styled from 'styled-components'

const DynamicText = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
`

export { DynamicText }

const DynamicInputContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 210px;
  width: max-content;
`

const DynamicInputWrapper = styled.div`
  border: 1px solid #c7cad6;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 30px;
  width: 100%;
`

const DynamicInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  font-size: 14px;
  line-height: 19px;
  background-color: white;
`

export { DynamicInputContainer, DynamicInputWrapper, DynamicInput }
