import { toTwoDigitNumber } from 'tabs/topQuestions/utils/NumberUtil'

export type DateObject = {
  year: number
  month: number
  day: number
}

export function dateObjectTimestampSeconds(date: DateObject) {
  const { year, month, day } = date
  return new Date(year, month, day + 1).getTime() / 1000
}

export function getCurrentDateMinusMonths(numOfMonths: number) {
  const currentDate = new Date()
  return new Date(currentDate.setMonth(currentDate.getMonth() - numOfMonths))
}

export function toDateObject(date: Date): DateObject {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return { year, month, day }
}

export function fromTimestampSeconds(seconds: number) {
  return new Date(seconds * 1000)
}

export function formatDate(dateValue: Date) {
  const date = toDateObject(dateValue)
  return formatDateObject(date)
}

export function formatDateObject(date: DateObject) {
  const year = toTwoDigitNumber(date.year)
  const month = toTwoDigitNumber(date.month)
  const day = toTwoDigitNumber(date.day)
  return year + '-' + month + '-' + day
}

export function* rangeBetweenDates(startTimestampSec: number,
                                   finishTimestampSec: number) {
  const currentDate = new Date(startTimestampSec * 1000)
  const endDate = new Date(finishTimestampSec * 1000)

  currentDate.setHours(0, 0, 0, 0)

  while (currentDate <= endDate) {
    yield formatDate(new Date(currentDate)) // Yield the current date
    currentDate.setDate(currentDate.getDate() + 1) // Move to the next day
  }
}
