import styled from 'styled-components'

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem 0;
`

const SearchWrapper = styled.div`
    border: 1px solid #C7CAD6;
    width: max-content;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    margin-right: 10px;
    padding: 10px;
    max-height: 45px;
    min-width: 250px;
    max-width: 250px;
`

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 14px;
    line-height: 19px;
    background-color: white;
`

export { SearchContainer, SearchWrapper, Input }
