import {
  TableConfig,
  UnknownQuestionHeaderSortMap,
  UnknownQuestionType,
  UnkownQuestionsTableHeaderTitles,
} from './types'

export const unansweredQuestionsTableConfig: TableConfig<UnkownQuestionsTableHeaderTitles> = {
  question: { maxWidth: '20%', title: 'Question', paddingRight: 30 },
  answer: { maxWidth: '20%', title: 'Answer' },
  type: { maxWidth: '12%', title: 'Type' },
  assignUser: { maxWidth: '22%', title: 'User' },
  editButton: { maxWidth: '3%', title: '' },
  trainButton: { maxWidth: '10%', title: 'Train' },
  createAt: { maxWidth: '10%', title: 'Created At' },
  removeButton: { maxWidth: '3%', title: '' },
}

export const unansweredQuestionsTableTitles = Object.values(unansweredQuestionsTableConfig).map(
  ({ title, maxWidth }) => ({ title, maxWidth }),
)

export const unansweredQuestionTypeLabels: Record<UnknownQuestionType, string> = {
  [UnknownQuestionType.unknown]: 'Unknown',
  [UnknownQuestionType.faq]: 'FAQ',
  [UnknownQuestionType.dax]: 'DAX',
}

export const unansweredQuestionsTypeDefaultOption = {
  value: UnknownQuestionType.unknown,
  label: unansweredQuestionTypeLabels.unknown,
} as any

export const unansweredQuestionTypeSearchOptions = Object.entries(unansweredQuestionTypeLabels).map(
  ([value, label]) => ({ value, label }),
)

export const unansweredQuestionTypeAssignOptions = Object.entries(unansweredQuestionTypeLabels)
  .filter(([value]) => value !== UnknownQuestionType.unknown)
  .map(([value, label]) => ({ value, label }))

export const unansweredQuestionsQueryParamNamesMap: Record<string, keyof UnknownQuestionHeaderSortMap> = {
  createdat: 'Created At',
  user: 'User',
  train: 'Train',
  answer: 'Answer',
}
