/* eslint-disable */
import { FC } from 'react'
import * as ModalStyles from '../../../ModalWindow/modal.styles'
import { useDispatch } from 'react-redux'
import { AddUsersSection } from '.'
import { botSettingUpdate } from '../state/BotSettingActions'
import { appBotService } from '../../api/bot'
import { EnablingSection } from '../EnablingSection'

interface FeedbackUsersGroupProps {
  groupName: string
  shouldFeedbackBeAsked: boolean
  isMandatory: boolean
}

export const FeedbackUsersGroup: FC<FeedbackUsersGroupProps> = ({ groupName, shouldFeedbackBeAsked, isMandatory }) => {
  const dispatch = useDispatch()

  const radioGroupChangeHandler = (value: string) => {
    const shouldFeedbackBeAsked = value === 'enable'
    const groups = { [groupName]: { shouldFeedbackBeAsked, isMandatory } }
    appBotService.updateFeedback({ groups }).then(() => dispatch(botSettingUpdate({ feedback: { groups } })))
  }

  const mandatoryRadioGroupChangeHandler = (value: string) => {
    const isMandatory = value === 'required'
    const groups = { [groupName]: { shouldFeedbackBeAsked, isMandatory } }
    appBotService.updateFeedback({ groups }).then(() => dispatch(botSettingUpdate({ feedback: { groups } })))
  }

  return (
    <ModalStyles.ModalContainer>
      <EnablingSection
        title="Group Users"
        radioGroupChangeHandler={radioGroupChangeHandler}
        selectedOption={shouldFeedbackBeAsked ? 'Enable' : 'Disable'}
        isMandatory={isMandatory}
        mandatoryRadioGroupChangeHandler={mandatoryRadioGroupChangeHandler}
      />

      <AddUsersSection groupName={groupName} />
    </ModalStyles.ModalContainer>
  )
}
