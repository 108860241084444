import React from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import './TopQuestionsDataRangeSelect.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectEndDateFilter, selectStartDateFilter } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartSelectors'
import dayjs, { Dayjs } from 'dayjs'
import { DateObject, formatDateObject } from 'tabs/topQuestions/utils/DateUtils'
import { lineChartFilterEndDateChange, lineChartFilterStartDateChange } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartActions'


export function toDateObject(day: dayjs.Dayjs): DateObject {
  return {
    day: day.date(),
    month: day.month() + 1,
    year: day.year(),
  }
}

export function fromDateObject(obj: DateObject): dayjs.Dayjs {
  return obj != undefined ? dayjs(formatDateObject(obj)) : undefined
}

export const TopQuestionsDataRangeSelect: React.FC = () => {
  const dispatch = useDispatch()
  const selectedStartDate = useSelector(selectStartDateFilter)
  const selectedEndDate = useSelector(selectEndDateFilter)

  const startValue = fromDateObject(selectedStartDate)
  const endValue = fromDateObject(selectedEndDate)


  const onStartDateChange = (day: Dayjs | null) => {
    const dateObj = toDateObject(day)
    dispatch(lineChartFilterStartDateChange(dateObj))
  }

  const onEndDateChange = (day: Dayjs | null) => {
    const endObj = toDateObject(day)
    dispatch(lineChartFilterEndDateChange(endObj))
  }

  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className={'topQuestionsDateRangeWrapper'}>
      <DatePicker label="Start date" value={startValue} onChange={onStartDateChange} />
      <div className={'topQuestionsDateDash'} />
      <DatePicker label="End date" value={endValue} onChange={onEndDateChange} />
    </div>
  </LocalizationProvider>
}
