import { BACKEND_URL } from '../../../configs'
import API from '../../../services/api'
import { alertSuccess } from '../../../api'
import { feedbackAPIMessages } from './configs'
import {
  FeedbackAboutQuestionModel,
  FeedbackPaginateAPIResponse,
  FeedbackSearchAPI,
} from '../components/UserFeedback/types'

const template = (path = '', params = {}) => {
  const searchParams = new URLSearchParams(params)
  return `${BACKEND_URL}/feedback${path}?${searchParams}`
}

const searchUserFeedback = async (params: FeedbackSearchAPI): Promise<FeedbackPaginateAPIResponse> => {
  return API.get(template('', params))
}

const deleteUserFeedback = async (id: string, withAlert = true): Promise<any> => {
  return API.delete(template(`/${id}`)).then(response => {
    if (withAlert) alertSuccess(feedbackAPIMessages.deleted)
    return response
  })
}

const updateFeedback = async (
  id: string,
  body: Partial<FeedbackAboutQuestionModel>,
  withAlert = true,
): Promise<any> => {
  return API.put(template(`/${id}`), body).then(response => {
    if (withAlert) alertSuccess(feedbackAPIMessages.solved)
    return response
  })
}

export const userFeedbackService = {
  searchUserFeedback,
  deleteUserFeedback,
  updateFeedback,
}
