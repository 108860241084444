import React, { FC, useEffect, useRef, useState } from 'react'
import * as Styles from './styles'
import { DynamicTextAndInput } from '../../../dynamicTextInput'
import { abbreviationDynamicTextStyles } from '../config'
import { AbbreviationItemProps } from './type'
import { notAllowToSaveChangesForSingleInput } from '../../../configs'

export const AbbreviationItem: FC<AbbreviationItemProps> = ({
  value,
  updateValue,
  isFocused,
  updateRecord,
  removeAbbreviationFromList,
  stopUpdateRequestIfExist,
  isSingle,
}) => {
  const [isEditMode, setIsEditMode] = useState(isFocused)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const deleteConfirmationContainer = useRef(null)

  const handleEditClick = () => {
    setIsEditMode(true)
    stopUpdateRequestIfExist()
  }

  const onBlurAction = (event, editText: string) => {
    setIsEditMode(false)
    const newAbbreviations = updateValue(editText)
    newAbbreviations && updateRecord(newAbbreviations)
  }

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(value => !value)
  }

  const onDeleteButtonPressed = () => {
    removeAbbreviationFromList(value)
  }

  const handleClickOutside = event => {
    if (deleteConfirmationContainer.current && !deleteConfirmationContainer.current.contains(event.target)) {
      setShowDeleteConfirmation(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Styles.AbbreviationItemContainer>
      <DynamicTextAndInput
        {...abbreviationDynamicTextStyles}
        value={value}
        onClickAction={handleEditClick}
        onBlurAction={onBlurAction}
        isFocused={isFocused}
        placeholder="Abbreviation"
        inputValidation={isSingle && notAllowToSaveChangesForSingleInput}
      />
      {!isSingle && !isEditMode && (
        <Styles.AbbreviationDeleteContainer onClick={openDeleteConfirmation} ref={deleteConfirmationContainer}>
          <Styles.AbbreviationDeleteButton />
          {showDeleteConfirmation && (
            <Styles.AbbreviationConfirmDeleteButton onClick={onDeleteButtonPressed}>
              Remove?
            </Styles.AbbreviationConfirmDeleteButton>
          )}
        </Styles.AbbreviationDeleteContainer>
      )}
    </Styles.AbbreviationItemContainer>
  )
}
