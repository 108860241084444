import React, { FC, useState } from 'react'
import { AbbreviationTableRowProps } from '../../types'
import { DynamicTextAndInput } from '../../dynamicTextInput'
import { useAbbreviations } from '../../../context'
import { notAllowToSaveChangesForSingleInput } from '../../configs'

export const FullNameTableRowCell: FC<AbbreviationTableRowProps> = ({ abbreviationsRecord }) => {
  const { updateAbbreviation, putOnHoldUpdateRequest, sendOnHoldRequestsIfNeed } = useAbbreviations()

  const [name, setName] = useState(abbreviationsRecord.fullName)

  const handleInputChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string)
  }

  const onBlurAction = () => {
    if (name !== abbreviationsRecord.fullName) {
      abbreviationsRecord.fullName = name
      updateAbbreviation(abbreviationsRecord.id, abbreviationsRecord, !!abbreviationsRecord.definition)
    } else {
      sendOnHoldRequestsIfNeed(abbreviationsRecord.id, abbreviationsRecord)
    }
  }

  const stopUpdateRequestIfExist = () => {
    putOnHoldUpdateRequest(abbreviationsRecord.id)
  }

  return (
    <DynamicTextAndInput
      inputChangeAction={handleInputChange}
      value={abbreviationsRecord.fullName}
      onBlurAction={onBlurAction}
      onClickAction={stopUpdateRequestIfExist}
      inputValidation={notAllowToSaveChangesForSingleInput}
    />
  )
}
