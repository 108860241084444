/* eslint-disable */
import { FC, useEffect, useRef, useState } from 'react'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import { feedbackSettingTableTitles } from './configs'
import * as Styles from '../../../styles'
import * as LocalStyles from './styles'
import { appBotService } from '../../../api/bot'
import { useDispatch } from 'react-redux'
import { retrieveUsersGroup } from '../../state/BotSettingApi'
import { BotUser } from '../../state/BotSettingsTypes'

interface FeedbackUsersTableProps {
  users: Array<BotUser>
  groupName: string
}

interface RowProps {
  userId: string
  name: string
  index: number
  groupName: string
}

const Row: FC<RowProps> = ({ userId, name, index, groupName }) => {
  const dispatch = useDispatch()

  const [showRemoveButton, setShowRemoveButton] = useState(false)
  const deleteConfirmationContainer = useRef(null)

  const handleClickOutside = event => {
    if (deleteConfirmationContainer.current && !deleteConfirmationContainer.current.contains(event.target)) {
      setShowRemoveButton(false)
    }
  }

  const removeUser = () => {
    appBotService.removeUserFromGroup({ userId, groupName }).then(() => {
      dispatch(retrieveUsersGroup())
    })
  }

  const openRemoveConfirmation = event => {
    setShowRemoveButton(prev => !prev)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <TableRow style={{ minHeight: 20, padding: '5px 20px' }} index={index}>
      <TableCell>
        <Styles.Text fontSize="16px">{name}</Styles.Text>
      </TableCell>
      <TableCell>
        <div style={{ position: 'relative' }} ref={deleteConfirmationContainer}>
          <LocalStyles.RemoveButton onClick={openRemoveConfirmation}>X</LocalStyles.RemoveButton>
          {showRemoveButton && (
            <LocalStyles.ConfirmDeleteButton onClick={removeUser}>Remove?</LocalStyles.ConfirmDeleteButton>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export const FeedbackUsersTable: FC<FeedbackUsersTableProps> = ({ users, groupName }) => {
  return (
    <Table titles={feedbackSettingTableTitles} style={{ width: '70%', maxHeight: '300px', overflowX: 'auto' }}>
      {users.map((user, ind) => (
        <Row key={user.id} userId={user.idString} name={user.name} index={ind} groupName={groupName} />
      ))}
    </Table>
  )
}
