import { QuestionClusterItem } from 'tabs/topQuestions/components/question_cluster_item/QuestionClusterItem'
import React, { useState } from 'react'
import { ClusterDetails } from 'tabs/topQuestions/types/InternalTypes'
import { FetchClusterQuestions } from 'tabs/topQuestions/types/ApiResponses'
import { Pagination } from '@mui/lab'
import { UsePaginationProps } from '@mui/material/usePagination/usePagination'
import Card from '@mui/material/Card'
import { useSelector } from 'react-redux'
import './QuestionClusterList.scss'
import { selectClusterColorsMap, selectClusterDetails } from 'tabs/topQuestions/lib/top_questions/topQuestionsSelectors'
import { getTopQuestionCount } from 'tabs/topQuestions/types/topQuestionsUtil'
import { selectQuestionTypeFilter } from 'tabs/topQuestions/lib/top_questions_search/topQuestionsSearchSelectors'
import { questionsCountComparator } from 'tabs/topQuestions/lib/question_clusters_line_chart/questionClustersLineChartConverter'

type QuestionClustersTabProps = {
  sendFetchClusterQuestionsMessage: (request: FetchClusterQuestions) => void,
}

const CLUSTERS_PAGE_SIZE = 10

export const QuestionClustersTab: React.FC<QuestionClustersTabProps> = ({
                                                                          sendFetchClusterQuestionsMessage,
                                                                        }) => {
  const topQuestions = useSelector(selectClusterDetails)
  const topQuestionsColors = useSelector(selectClusterColorsMap)

  const topQuestionTypeFilter = useSelector(selectQuestionTypeFilter)
  const [page, setPage] = useState(0)

  const [expandedClusterId, setExpandedClusterId] = useState<string | undefined>()


  const totalPages = Math.ceil(topQuestions.length / CLUSTERS_PAGE_SIZE)
  const onPageChange: UsePaginationProps['onChange'] = (_event, page) => {
    const zeroIndexedPage = page - 1
    setPage(zeroIndexedPage)
  }

  const filteredTopQuestions = topQuestions.filter(cluster => {
    const clusterCount = getTopQuestionCount(cluster, topQuestionTypeFilter)
    return clusterCount != 0
  })

  const getClusterQuestionTypeCount = (cluster: ClusterDetails) => {
    return getTopQuestionCount(cluster, topQuestionTypeFilter)
  }

  const sortedTopQuestions = filteredTopQuestions.sort(questionsCountComparator(getClusterQuestionTypeCount))


  const pageStart = page * CLUSTERS_PAGE_SIZE
  const pageEnd = (page + 1) * CLUSTERS_PAGE_SIZE
  const currentPageClusters = sortedTopQuestions
    .slice(pageStart, pageEnd)

  return <div className={'questionClustersWrapper'}>
    <Card className={'includedClustersTitleWrapper'}>
      <div className={'includedClustersWrapper'}>
        {currentPageClusters
          .map((topQuestion) => {
            return <QuestionClusterItem key={topQuestion.clusterId}
                                        topQuestion={topQuestion}
                                        questionColors={topQuestionsColors}
                                        expandedClusterId={expandedClusterId}
                                        setExpandedClusterId={setExpandedClusterId}
                                        sendFetchClusterQuestionsMessage={sendFetchClusterQuestionsMessage}
            />
          })}
        <div className={'paginationWrapper'}>
          <Pagination page={page + 1} onChange={onPageChange} count={totalPages} />
        </div>
      </div>
    </Card>
  </div>

}
